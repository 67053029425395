import React from 'react';
import { Grid } from '@material-ui/core';
import './SpecialTile.scss';
import { AuthButton } from '../AuthButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Helper from '../../services/helper';

interface Props {
  children?: any;
  image?: string;

  title?: string;
  subTitle?: string;
  info?: string;

  buttonLabel?: string;
  buttonLink?: string;

  priceActual?: string;
  priceBefore?: string;
  specialText?: string;

  shopId?: string;
  history?: any;
}

export default function SpecialTile(props: Props) {
  return (
    <div className="SpecialTile">
      {props.image && <img src={props.image} alt="" />}

      <div className="SpecialTile-main">
        {props.title && <h2>{props.title}</h2>}

        {props.priceActual && (
          <h3 className="SpecialTile-priceActual">
            {props.priceActual} {props.specialText && '*'}
          </h3>
        )}

        {props.priceBefore && (
          <h3 className="SpecialTile-priceBefore">{props.priceBefore}</h3>
        )}

        {props.subTitle && (
          <h3 className="SpecialTile-subTitle">{props.subTitle}</h3>
        )}

        {props.specialText && (
          <div className="SpecialTile-content">* {props.specialText}</div>
        )}
      </div>

      <div className="SpecialTile-footer">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {props.info && <div className="SpecialTile-info">{props.info}</div>}
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
            }}
          >
            {props.buttonLabel && props.buttonLink && (
              <AuthButton
                color="primary"
                variant="contained"
                href={props.buttonLink ? props.buttonLink : ''}
                target="_blank"
                onClick={
                  props.shopId && props.history
                    ? (e: any) =>
                        Helper.handleClickoutHint(
                          {
                            type: 'shop',
                            url: props.buttonLink,
                            shopId: props.shopId,
                          },
                          e,
                          props?.history
                        )
                    : () => {}
                }
              >
                {props.buttonLabel}{' '}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    marginLeft: 10,
                  }}
                />
              </AuthButton>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
