import React from "react";
import { Layout } from "../../components/Layout/Layout";
import Hero from "../../components/Hero/Hero";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import Box from "./../../components/Box/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ServiceTile from "../../components/ServiceTile/ServiceTile";
import TeaserTile from "../../components/TeaserTile/TeaserTile";
import CashbackManager from "../../services/manager/CashbackManager";
import Shop from "../../interfaces/shop";
import ShopTile from "../../components/ShopTile/ShopTile";
import { Loading } from "../../components/Loading";
import Backlink from "../../components/Backlink/Backlink";
import { Redirect } from "react-router-dom";
import UserManager from "../../services/manager/UserManager";
import { User } from "../../interfaces/user";
import helper from "../../services/helper";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

interface State {
  loading: boolean;
  redirect: boolean;

  interests: any;
}

interface Props {}

export default class ChangeInterests extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,

      interests: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const user = await UserManager.me();

    let interests = JSON.parse(
      user.userInfo.interests ? user.userInfo.interests : "{}"
    );
    if (!interests) interests = {};

    this.setState({
      loading: false,
      interests
    });
  }

  handleChange(field: string) {
    return (e: any) => {
      const interests = this.state.interests;

      interests[field] = e.target.checked;

      this.setState({
        interests
      });
    };
  }

  async handleSubmit() {
    await UserManager.updateSettings({
      interests: JSON.stringify(this.state.interests)
    });

    this.setState({
      redirect: true
    });
  }

  render() {
    const interests = this.state.interests;

    if (this.state.redirect) return <Redirect to="/einstellungen" />;

    return (
      <Layout>
        <Hero
          size="lg"
          imageSrc="/assets/images/heroes/dashboard.jpg"
          imagePositionY={-280}
        >
          <h1>Ihre Interessen</h1>
        </Hero>

        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && (
          <InnerLayout>
            <Backlink label="zurück zur Übersicht" to="/einstellungen" />

            <Box>
              <h2>Ihre Interessen</h2>

              <Grid container spacing={0}>
                {[
                  {
                    label: "Immobilien",
                    value: interests.realEstate,
                    field: "realEstate"
                  },
                  {
                    label: "Familie",
                    value: interests.family,
                    field: "family"
                  },
                  { label: "Sport", value: interests.sports, field: "sports" },
                  { label: "Kunst", value: interests.art, field: "art" },
                  {
                    label: "Kultur",
                    value: interests.culture,
                    field: "culture"
                  },
                  {
                    label: "Autos/Motorräder",
                    value: interests.cars,
                    field: "cars"
                  },
                  { label: "Musik", value: interests.music, field: "music" },
                  {
                    label: "Mobilität",
                    value: interests.mobility,
                    field: "mobility"
                  },
                  {
                    label: "Energie sparen",
                    value: interests.energy,
                    field: "energy"
                  },
                  { label: "Reisen", value: interests.travel, field: "travel" },
                  {
                    label: "Versicherungsprodukte",
                    value: interests.insuranceProducts,
                    field: "insuranceProducts"
                  }
                ].map(({ label, value, field }, key) => (
                  <Grid item md={6} xs={12} key={key}>
                    <span
                      style={{
                        color: "#333333"
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            color="default"
                            value="default"
                            onChange={
                              field ? this.handleChange(field) : () => {}
                            }
                          />
                        }
                        label={label}
                      />
                    </span>
                  </Grid>
                ))}
              </Grid>

              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end"
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmit}
                  >
                    Speichern
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </InnerLayout>
        )}
      </Layout>
    );
  }
}
