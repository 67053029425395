import React from 'react';
import './KpiTile.scss';
import { Link } from 'react-router-dom';

export default function KpiTile({
  color,
  bgColor,
  title,
  children,
  icon,
  link,
  style,
  iconPosition,
  externalLink,
}: any) {
  if (!style) style = {};
  style.background = bgColor;
  style.color = color;

  const content = (
    <div className="inner-content">
      {title && (
        <div>
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
          <div className="divider"></div>
        </div>
      )}
      <div className="children-container">{children}</div>
      <div className={'icon ' + (iconPosition === 'top' ? 'top' : '')}>
        {icon}
      </div>
    </div>
  );

  if (link) {
    return (
      <Link className="KpiTile" style={style} to={link}>
        {content}
      </Link>
    );
  } else if (externalLink) {
    return (
      <a
        href={externalLink}
        target="_blank"
        className="KpiTile"
        rel="noopener noreferrer"
        style={style}
      >
        {content}
      </a>
    );
  } else
    return (
      <div className="KpiTile" style={style}>
        {content}
      </div>
    );
}
