import React from "react";
import Box from "./../../../components/Box/Box";
import { Grid } from "@material-ui/core";
import ServiceTile from "../../../components/ServiceTile/ServiceTile";

export const TravelInsurance = {
  title: "Reiseversicherung",
  content: (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={9}>
        <Box>
          <h2>Jetzt Ihre Reiseversicherung bei Travel Protect abschließen</h2>
          <p>
            <b>TravelProtect – Die Reiseversicherung</b> bieten Ihnen
            verschiedene Tarife in der Reiseversicherungen an, die Sie zur
            Absicherung Ihres Urlaubs online, sicher und schnell abschließen
            können.
          </p>
          <p>
            Neben der Reiserücktrittsversicherung (inklusive{" "}
            <b>Reiseabbruchschutz</b>) können Sie wahlweise auch eine{" "}
            <b>Reisegepäckversicherung</b> oder eine{" "}
            <b>Reisekrankenversicherung</b> hinzubuchen.
          </p>
          <p>
            Reisen Sie nur einmalig? Dann empfehlen wir Ihnen unsere{" "}
            <b>Einmalreiseversicherung</b>, die nur für einen speziellen Urlaub
            gilt. Sollten Sie mehr als einmal im Jahr in den Urlaub fahren,
            lohnt sich zur Absicherung auf jeden Fall die{" "}
            <b>Jahresreiseversicherung </b>. Hier können Sie beliebig oft eine
            Reise buchen und versichern – bei nur einer jährlichen Prämie.
          </p>
        </Box>
      </Grid>
      <Grid item xs={12} lg={3}>
        <ServiceTile
          tel="0931 3042 9800"
          title="Travel Protect Kundenservice"
          timePeriods={[
            { title: "Mo.-Do.:", period: "08:00 - 17:00 Uhr" },
            { title: "Fr.:", period: "08:00 - 16:00 Uhr" }
          ]}
        />
      </Grid>
    </Grid>
  )
};
