import './RegionalOffers.scss';

import {
  faArrowRight,
  faCreditCard,
  faMapMarkerAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Box from '../../components/Box/Box';
import Hero from '../../components/Hero/Hero';
import HeroSearch from '../../components/HeroSearch/HeroSearch';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import Location from '../../components/Location/Location';
import Pagination from '../../components/Pagination/Pagination';
import ShopTile from '../../components/ShopTile/ShopTile';
import RegionalOfferCategory from '../../interfaces/regionalOfferCategory';
import RegionalOfferShop from '../../interfaces/regionalOfferShop';
import Auth from '../../services/auth';
import RegionalOffersManager from '../../services/manager/RegionalOffersManager';
import StateManager from '../../services/manager/StateManager';

const MySwal = withReactContent(Swal);

interface Props {}

interface State {
  shops: RegionalOfferShop[];
  categories: RegionalOfferCategory[];
  loading: boolean;
  page: number;
  numberOfPages: number;
  sorting?: string;
  category: number;
  query: string;
  onlyFavorites: boolean;
  location?: { lat: number; lng: number; maxDistance: number };
}

export default class RegionalOffers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      shops: [],
      categories: [],
      loading: true,
      page: 1,
      numberOfPages: 0,
      query: '',
      category: 0,
      onlyFavorites: false,
      sorting: 'default',
    };

    this.changePage = this.changePage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeCategory = this.handleChangeCategory.bind(this);
    this.handleChangeSorting = this.handleChangeSorting.bind(this);
    this.handleToggleOnlyFavorites = this.handleToggleOnlyFavorites.bind(this);
    this.handlePickLocation = this.handlePickLocation.bind(this);
    this.handleRemoveFilter = this.handleRemoveFilter.bind(this);
    this.handleToggleFavorite = this.handleToggleFavorite.bind(this);
  }

  async componentDidMount() {
    const categories = await RegionalOffersManager.findCategories();

    await this.setState({
      categories: categories.items,
    });

    this.load();
  }

  async load(page?: number) {
    if (!page) page = this.state.page;

    await this.setState({
      loading: true,
    });

    const filter: any = {
      page,
      filter: {},
    };

    if (this.state.category) {
      filter.filter.categories = [this.state.category];
    }

    if (this.state.sorting && this.state.sorting !== 'default') {
      filter.sort = this.state.sorting;
    }

    if (this.state.query.trim() !== '') {
      filter.query = this.state.query;
    }

    if (this.state.onlyFavorites) filter.filter.favorite = true;
    if (this.state.location) filter.filter.location = this.state.location;

    StateManager.setState(
      'regionalOffersCategory',
      filter.filter.categories || undefined
    );
    StateManager.setState('regionalOffersSorting', filter.sorting || undefined);
    StateManager.setState('regionalOffersQuery', filter.query || undefined);
    StateManager.setState(
      'regionalOffersLocation',
      filter.filter.location || undefined
    );

    const shops = await RegionalOffersManager.findShops(filter);

    if (shops && shops.items && shops.items.length > 0) {
      if (Auth.isLoggedIn()) {
        for (const shop of shops.items) {
          shop.isFavorite = await RegionalOffersManager.isFavorite(shop.id);
        }
      } else {
        for (const shop of shops.items) {
          shop.isFavorite = false;
        }
      }
    }

    this.setState({
      shops: shops.items,
      numberOfPages: shops.numberOfPages,
      loading: false,
      page,
    });
  }

  async changePage(page: number) {
    this.load(page);
    window.scrollTo({
      top: 0,
    });
  }

  handleSearch() {
    this.load(1);
  }

  handleChangeQuery(query: string) {
    this.setState({
      query,
    });
  }

  handleChangeCategory(e: any) {
    this.setState(
      {
        category: e.target.value,
      },
      () => this.load(1)
    );
  }

  handleChangeSorting(e: any) {
    this.setState(
      {
        sorting: e.target.value,
      },
      () => this.load(1)
    );
  }

  handleToggleOnlyFavorites() {
    this.setState(
      {
        onlyFavorites: !this.state.onlyFavorites,
      },
      () => this.load(1)
    );
  }

  handleRemoveFilter() {
    this.setState(
      {
        query: '',
        category: 0,
        onlyFavorites: false,
        sorting: 'default',
        location: undefined,
        page: 1,
      },
      this.load
    );
  }

  handleToggleFavorite(shop: RegionalOfferShop) {
    return async (isFavorite: boolean) => {
      if (isFavorite) {
        await RegionalOffersManager.setShopFavorite(shop.id);
      } else {
        await RegionalOffersManager.removeShopFavorite(shop.id);
      }
      this.load();
    };
  }

  handlePickLocation() {
    MySwal.fire({
      html: (
        <Location
          location={this.state.location}
          distance={
            this.state.location ? this.state.location.maxDistance : undefined
          }
          onChange={(location: any, distance: number) => {
            this.setState(
              {
                location: {
                  lat: location.lat,
                  lng: location.lng,
                  maxDistance: distance,
                },
              },
              () => {
                this.load(1);
                MySwal.close();
              }
            );
          }}
        />
      ),
      width: '60%',
      showConfirmButton: false,
      showCloseButton: true,
      customClass: {
        content: 'swal-content',
        popup: 'swal-popup',
      },
    });
  }

  renderFilter() {
    const sortings = [
      { label: 'Vorteil aufsteigend', value: 'rates_desc' },
      { label: 'Vorteil absteigend', value: 'rates_asc' },
      { label: 'Name aufsteigend', value: 'name_desc' },
      { label: 'Name absteigend', value: 'name_asc' },
    ];

    return (
      <Box
        style={{
          marginBottom: '1em',
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'relative',
          paddingBottom: '70px',
        }}
        className="filterbuttonsbox"
      >
        {Auth.isLoggedIn() && (
          <div
            style={{
              justifySelf: 'flex-start',
              flex: 1,
            }}
          >
            <Button
              color={this.state.onlyFavorites ? 'secondary' : 'primary'}
              variant={this.state.onlyFavorites ? 'contained' : 'outlined'}
              style={{ marginRight: 5, marginBottom: 5 }}
              onClick={this.handleToggleOnlyFavorites}
            >
              Nur Favoriten
            </Button>
          </div>
        )}

        <div
          style={{
            marginRight: 10,
          }}
          className="filterbuttons"
        >
          <Button
            color={this.state.location ? 'secondary' : 'primary'}
            variant={this.state.location ? 'contained' : 'outlined'}
            style={{ marginRight: 5, marginBottom: 5 }}
            onClick={this.handlePickLocation}
          >
            Im Umkreis / nach Adresse suchen
          </Button>
        </div>

        <div
          style={{
            marginRight: 10,
          }}
          className="filterbuttons"
        >
          <Select
            className="rounded-select"
            value={this.state.category}
            onChange={this.handleChangeCategory}
          >
            <MenuItem value={0}>Kategorie filtern</MenuItem>
            {this.state.categories.map(
              (category: RegionalOfferCategory, key) => (
                <MenuItem key={key} value={category.id}>
                  {category.name}
                </MenuItem>
              )
            )}
          </Select>
        </div>

        <div className="filterbuttons">
          <Select
            className="rounded-select"
            value={this.state.sorting}
            onChange={this.handleChangeSorting}
          >
            <MenuItem value={'default'}>Sortieren</MenuItem>
            {sortings.map((order, key) => (
              <MenuItem value={order.value} key={key}>
                {order.label}
              </MenuItem>
            ))}
          </Select>
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: '30px',
            left: '50%',
            marginLeft: '-59px',
          }}
        >
          <a
            href="#"
            onClick={this.handleRemoveFilter}
            style={{
              borderBottom: '1px solid #93c01f',
              color: '#333',
            }}
          >
            Filter zurücksetzen
          </a>
        </div>
      </Box>
    );
  }

  render() {
    const fkn = Auth.getFKN();

    return (
      <Layout>
        <div className="RegionalOffers">
          <Hero
            size="lg"
            imageSrc="/assets/images/heroes/vorOrt.jpg"
            imagePositionY={-200}
          >
            <HeroSearch
              title="Angebote vor Ort"
              subTitle="günstig Einkaufen direkt um die Ecke"
              placeholder="Angebot finden..."
              buttonLabel="suchen"
              onSubmit={this.handleSearch}
              onChange={this.handleChangeQuery}
              query={this.state.query}
            />
          </Hero>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <div className="filter">{this.renderFilter()}</div>

              <Grid container spacing={2} style={{ marginTop: 8, padding: 8 }}>
                <Grid item xs={12} style={{ backgroundColor: '#fff' }}>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    style={{
                      padding: 20,
                      backgroundColor: '#fff',
                    }}
                  >
                    <h3>
                      Sie unterstützen den lokalen Einzelhandel.
                      <br />
                      Wir geben Ihnen Rabatte!
                    </h3>

                    <h3></h3>
                    <p>
                      Auch nach Corona ist es wichtig den regionalen
                      Einzelhandel zu unterstützen und langfristig zu sichern.
                      <br />
                      Egal ob das Lieblings Café um die Ecke oder der
                      Obsthändler des Vertrauens, mit der Umkreissuche finden
                      Sie einfach und schnell die passenden Angebote in ihrer
                      Region.
                    </p>
                    <strong>Ihre Vorteile:</strong>
                    <ul>
                      <li>Lokalen Einzelhandel unterstützen</li>
                      <li>Rabatte sichern</li>
                      <li>Mit Verantwortung shoppen</li>
                    </ul>
                    <strong>So funktioniert's:</strong>
                    <ol>
                      <li>plusrente bei der Bayerischen abschließen</li>

                      <li>
                        Von Rabatten im lokalen Einzelhandel profitieren und
                        zusätzlich Cashback für die Rente sichern
                      </li>
                    </ol>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2} style={{ marginTop: 8 }}>
                {this.state.shops &&
                  this.state.shops.map((shop: RegionalOfferShop, key) => (
                    <Grid item xs={6} md={3} key={key}>
                      <ShopTile
                        image={
                          shop.images[0]
                            ? shop.images[0].url
                            : '/assets/images/heroes/tickets.jpg'
                        }
                        isFavorite={
                          Auth.isLoggedIn() ? shop.isFavorite : undefined
                        }
                        onFavoriteChange={this.handleToggleFavorite(shop)}
                      >
                        <strong>
                          {shop.displayName ? shop.displayName : shop.name}
                        </strong>

                        <div className="ShopTile-Regional">
                          {shop.offers.length > 0 && (
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <FontAwesomeIcon icon={faCreditCard} />
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: shop.offers[0].description,
                                }}
                              />
                            </div>
                          )}
                          {shop.branches.length > 0 && (
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <FontAwesomeIcon icon={faMapMarkerAlt} />
                              <div>
                                {shop.branches[0].street}{' '}
                                {shop.branches[0].streetNumber},{' '}
                                {shop.branches[0].city}
                              </div>
                            </div>
                          )}
                        </div>

                        <NavLink to={`/vor-ort/${shop.id}`}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: 20 }}
                          >
                            zu den Angeboten
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              style={{
                                marginLeft: 10,
                              }}
                            />
                          </Button>
                        </NavLink>
                      </ShopTile>
                    </Grid>
                  ))}
              </Grid>
              {this.state.numberOfPages > 0 && (
                <Pagination
                  currentPage={this.state.page}
                  numberOfPages={this.state.numberOfPages}
                  onPageChange={this.changePage}
                />
              )}
              {!this.state.numberOfPages && (
                <h1 style={{ color: '#93c01f', textAlign: 'center' }}>
                  Angebote folgen in Kürze!
                </h1>
                // <p>Leider wurden keine Angebote gefunden.</p>
              )}
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
