import * as React from 'react';
import { CircularProgress } from '@material-ui/core';

export const AppLoading = () => (
  <div
    style={{
      textAlign: 'center',
      padding: '5em',
      backgroundColor: '#2D5A8A',
      height: '100vh',
      color: '#fff',
      justifyItems: 'center',
      alignItems: 'center',
      display: 'flex',
    }}
  >
    <div
      style={{
        textAlign: 'center',
        display: 'block',
        width: '100%',
      }}
    >
      <img src="/plusrente.svg" alt="Logo der plusrente" />
      <br />
      <CircularProgress
        color="inherit"
        style={{
          marginTop: '4em',
        }}
      />
    </div>
  </div>
);
