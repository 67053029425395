import './Box.scss';

import React, { CSSProperties } from 'react';

interface Props {
  children: any;
  border?: boolean;
  style?: CSSProperties;
  className?: string;
}

export default function Box(props: Props) {
  return (
    <div
      className={`Box ${props.border === true ? 'Box-Border' : ''} ${
        props.className
      }`}
      style={props.style}
    >
      {props.children}
    </div>
  );
}
