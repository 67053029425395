import "./Dashboard.scss";

import { faArrowRight, faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

import Backlink from "../../components/Backlink/Backlink";
import Box from "../../components/Box/Box";
import Hero from "../../components/Hero/Hero";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import KpiTile from "../../components/KpiTile/KpiTile";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ResponsiveTable from "../../components/ResponsiveTable/ResponsiveTable";
import { CashbackComplaint } from "../../interfaces/cashbackComplaint";
import { CashoutProfile } from "../../interfaces/cashoutProfile";
import Shop from "../../interfaces/shop";
import Helper from "../../services/helper";
import CashbackManager from "../../services/manager/CashbackManager";
import Auth from "../../services/auth";

interface Props {}

interface State {
  cashoutProfile?: CashoutProfile;
  complaints: CashbackComplaint[];
  confirmedCashback?: number;
  pendingCashback?: number;
  loading: boolean;
  hasClickouts: boolean;
}

export default class Complaints extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      complaints: [],
      loading: true,
      hasClickouts: false,
    };
  }

  async componentDidMount() {
    const isFriendAccount =
      Auth.getTokenInfo().userInfo.isFriendAccount !== 1 &&
      Auth.getTokenInfo().userInfo.isFriendAccount !== "1";

    const [
      { confirmed: confirmedCashback, open: pendingCashback },
      complaintResponse,
      clickoutShops,
      cashouts,
    ] = await Promise.all([
      CashbackManager.getCashbackAmountSums(["confirmed", "open"]),
      CashbackManager.findComplaints(),
      CashbackManager.findLastClickoutShops(),
      isFriendAccount ? CashbackManager.getCashoutProfile() : (() => null)(),
    ]);

    const complaints = complaintResponse.items;
    const shopPromises: any[] = [];
    const shops = await Promise.all(shopPromises);

    if (cashouts) {
      this.setState({
        cashoutProfile: cashouts,
      });
    }

    if (clickoutShops.length > 0) {
      this.setState({
        hasClickouts: true,
      });
    }

    complaints.forEach((complaint: CashbackComplaint) => {
      shopPromises.push(CashbackManager.findShop(complaint.shopId));

      shops.forEach((shop: Shop) => {
        if (shop.id === complaint.shopId) {
          complaint.shopName = shop.name;
        }
      });
    });

    this.setState({
      complaints,
      confirmedCashback,
      pendingCashback,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <div className="Dashboard">
          <Hero
            size="md"
            imageSrc="/assets/images/heroes/dashboard.jpg"
            imagePositionY={-280}
          >
            <h1>Meine Nachbuchungsanfragen</h1>
          </Hero>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Backlink label="zurück zur Übersicht" to="/profil" />

              <Grid container spacing={4}>
                <Grid item xs={12} md={9}>
                  <Box>
                    <h2>Meine Nachbuchungsanfragen</h2>

                    <p>
                      Unten aufgeführt finden Sie eine Übersicht über alle von
                      Ihnen gestellten Nachbuchungsanfragen samt Status und
                      Transaktionsdetails. Sollten Sie einen Einkauf, den Sie
                      über das Cashback-Portal getätigt haben, nicht in Ihrer
                      Einkaufsübersicht aufgeführt werden, können Sie im Feld
                      weiter unten eine Anfrage stellen. Nutzen Sie dieses
                      Formular bitte nur für Einkäufe, die überhaupt nicht
                      aufgeführt werden. Für Rückfragen zu einer bestehenden
                      Transaktion verwenden Sie bitte unser Kontaktformular.
                    </p>

                    <ResponsiveTable
                      options={{
                        pagination: true,
                      }}
                      columns={[
                        {
                          name: "createdAt",
                          label: "Datum",
                          customBodyRender: (value: any) =>
                            Helper.formatDate(value),
                          textAlign: "left",
                        },
                        {
                          name: "shopName",
                          notCollapseOnMobile: true,
                          label: "Shop",
                          textAlign: "left",
                        },
                        {
                          name: "transactionDate",
                          label: "Einkaufsdatum",
                          customBodyRender: (value: any) =>
                            Helper.formatDate(value),
                          textAlign: "left",
                        },
                        {
                          name: "price",
                          label: "Warenkorbwert",
                          customBodyRender: (value: number) =>
                            Helper.formatPrice(value),
                          textAlign: "right",
                        },
                        {
                          name: "status",
                          notCollapseOnMobile: true,
                          label: "Status",
                          customBodyRender: (value) => (
                            <div>
                              <span className="color-bad">
                                {value === "requested" && "Angefragt"}
                                {value === "open" && "Offen"}
                                {value === "cancelled" && "Storniert"}
                                {value === "canceled" && "Storniert"}
                                {value === "rejected" && "Abgelehnt"}
                              </span>
                              <span className="color-good">
                                {value === "done" && "Erledigt"}
                                {value === "waiting" && "Wartet auf Kunde"}
                              </span>
                            </div>
                          ),
                          textAlign: "right",
                        },
                      ]}
                      data={this.state.complaints.map(
                        (complaint: CashbackComplaint) => {
                          return {
                            createdAt: complaint.createdAt,
                            shopName: complaint.shopName,
                            price: complaint.price,
                            status: complaint.status,
                            transactionDate: complaint.transactionDate,
                          };
                        }
                      )}
                    />

                    <br />
                    <br />

                    <h3>Neue Nachbuchungsanfrage erstellen</h3>

                    <p>
                      Zur schnellen und reibungslosen Bearbeitung bitten wir
                      Sie, alle Felder des Formulars vollständig auszufüllen.
                      Sollten Ihnen für eine Angabe keine Informationen
                      vorliegen, schreiben Sie dies bitte in das Feld, lassen
                      Sie das Feld aber nicht leer.
                    </p>
                    <p>
                      Bitte beachten Sie, dass es in manchen Fällen bis zu 90
                      Tage dauern kann, bis Ihre Nachbuchungsanfrage in der
                      Liste erscheint.
                    </p>

                    <Link to="/nachbuchungsanfrage">
                      <Button color="primary" variant="contained" size="medium">
                        Neue Nachbuchungsanfrage
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          style={{
                            marginLeft: 10,
                          }}
                        />
                      </Button>
                    </Link>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <KpiTile
                    color="#fff"
                    bgColor="#93c01f"
                    title="plusrente Guthaben"
                    icon={<FontAwesomeIcon icon={faCoins} />}
                    link="/transaktionen"
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ textAlign: "right", marginRight: 20 }}>
                        <h3>bestätigt:</h3>
                        <h3>offen: </h3>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <h3>
                          {Helper.formatPrice(
                            this.state.confirmedCashback || 0
                          )}
                        </h3>
                        <h3>
                          {Helper.formatPrice(this.state.pendingCashback || 0)}
                        </h3>
                      </div>
                    </div>
                  </KpiTile>

                  {Auth.getTokenInfo().userInfo.isFriendAccount !== 1 &&
                    Auth.getTokenInfo().userInfo.isFriendAccount !== "1" && (
                      <KpiTile
                        color="#fff"
                        bgColor="#009FD6"
                        title="Meine Auszahlungen"
                        icon={<FontAwesomeIcon icon={faCoins} />}
                        link="/auszahlungen"
                      >
                        <h3>
                          {this.state.cashoutProfile &&
                            Helper.formatPrice(
                              this.state.cashoutProfile.paidOutCash +
                                this.state.cashoutProfile.externalPaidOutCash ||
                                0
                            )}
                        </h3>
                      </KpiTile>
                    )}
                </Grid>
              </Grid>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
