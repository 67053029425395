import {API} from "../API";
import {List} from "../../interfaces/list";
import RegionalOfferCategory from "../../interfaces/regionalOfferCategory";
import RegionalOfferShop from "../../interfaces/regionalOfferShop";

export default class RegionalOffersManager {

    static favorites? : any[] = undefined;

    static async findCategories(): Promise<List<RegionalOfferCategory>> {
        return await API.get(`regionaloffer/categories`);
    }

    static async findShops(params: any = {}): Promise<List<RegionalOfferShop>> {
        return await API.get(`regionaloffer/shops?${API.encodeURL(params)}`);
    }

    static async findShopById(id: number): Promise<RegionalOfferShop> {
        return await API.get(`regionaloffer/shops/${id}`);
    }

    static async isFavorite(id: number): Promise<boolean> {
        if (!RegionalOffersManager.favorites) {
            RegionalOffersManager.favorites = await API.get(`regionaloffer/shop/favourites`);
        }
        if (!RegionalOffersManager.favorites) return false;
        if ((RegionalOffersManager.favorites as any).error) return false;
        return RegionalOffersManager.favorites.filter((fav:any) => fav.shop.id === id).length > 0;
    }

    static async removeShopFavorite(id: number) {
        RegionalOffersManager.favorites = undefined;
        return await API.delete(`regionaloffer/shops/${id}/favourite`);
    }

    static async setShopFavorite(id: number) {
        RegionalOffersManager.favorites = undefined;
        return await API.post(`regionaloffer/shops/${id}/favourites`, {});
    }

    static async findFavorites(): Promise<RegionalOfferShop[]> {
        return (await API.get(`regionaloffer/shop/favourites`)).map((shop:any) => shop.shop);
    }

}
