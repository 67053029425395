import './Travel.scss';

import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography, Hidden } from '@material-ui/core';
import React from 'react';

import Hero from '../../components/Hero/Hero';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ServiceTile from '../../components/ServiceTile/ServiceTile';
import ShopTile from '../../components/ShopTile/ShopTile';
import Shop from '../../interfaces/shop';
import { User } from '../../interfaces/user';
import Auth from '../../services/auth';
import Config from '../../services/config';
import CashbackManager from '../../services/manager/CashbackManager';
import UserManager from '../../services/manager/UserManager';
import Box from './../../components/Box/Box';
import { AuthButton } from '../../components/AuthButton';

interface State {
  cashbackShops: Shop[];
  loading: boolean;
  user?: User;
}

interface Props {}

export default class Travel extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cashbackShops: [],
      loading: true,
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
  }

  async componentDidMount() {
    const cashbackShops = await CashbackManager.findShopByCategoryId(
      Config.getTravelCategoryId(),
      1,
      4
    );

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }

    this.setState({
      cashbackShops: cashbackShops.items,
      loading: false,
    });
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.cashbackShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        cashbackShops: shops,
      });
    };
  }

  hideFrameLoading() {
    const el = document.getElementById('iframe-loading');
    if (el) el.style.display = 'none';
  }

  render() {
    const fkn = Auth.getFKN();

    return (
      <Layout>
        <div className="Travel">
          <Hero
            size="md"
            imageSrc="/assets/images/heroes/travel.jpg"
            imagePositionY={-400}
          >
            <h1>Reisebuchung – einfach & online!</h1>
          </Hero>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Box
                    style={{
                      height: '100%',
                    }}
                  >
                    <h2>Reise buchen und 5 % Cashback sichern!</h2>
                    <p>
                      Egal ob Kurztrip, Pauschal- oder Individualurlaub -
                      stellen Sie sich hier Ihre persönliche Traumreise zusammen
                      und buchen Sie direkt online. Im plusrente Reisebüro
                      können Sie sich Ihre persönliche Reise zusammenstellen.
                      Diese Reiseanfrage bestätigt Ihnen unser
                      Reisebuchungsservice schnellstmöglich innerhalb unserer{' '}
                      <b>
                        Öffnungszeiten (Mo. bis Fr. 8:00 bis 20:00 Uhr und
                        Samstag 8:00 bis 14:00 Uhr).
                      </b>{' '}
                      Sie können jederzeit auch telefonisch eine Reise buchen.
                      <br />
                      <br />
                      <b>
                        Ganz egal, wie Sie buchen: Bei jeder Reise erhalten Sie
                        5 %* des Gesamtpreises auf Ihr Cashback-Konto
                        gutgeschrieben.
                      </b>
                      <br />
                      <br />
                      Noch nicht die passende Reise gefunden? Dann rufen Sie uns
                      gerne an. Telefonisch haben wir noch eine weitere Auswahl
                      an Reiseveranstaltern und können Ihnen so Ihre Traumreise
                      ermöglichen.
                    </p>
                    {fkn && (
                      <Typography color="primary">
                        <FontAwesomeIcon
                          style={{ marginRight: 2 }}
                          icon={faUserCircle}
                        />{' '}
                        Ihre plusrente Kundennummer <b>{fkn}</b>
                      </Typography>
                    )}
                  </Box>
                </Grid>

                <Grid item md={3}>
                  <ServiceTile />
                </Grid>

                <Grid item md={12}>
                  <h2>Unsere Top-Reisepartner</h2>

                  <Grid container spacing={2}>
                    {this.state.cashbackShops &&
                      this.state.cashbackShops.map((shop: Shop, key) => (
                        <Grid item xs={6} md={3} key={key}>
                          <ShopTile
                            image={shop.logo}
                            buttonLabel="zum Shop"
                            morePath={`/shop/${shop.id}`}
                            buttonLink={shop.link}
                            isFavorite={shop.isFavorite}
                            onFavoriteChange={this.handleFavoriteChange(shop)}
                          >
                            Bis zu{' '}
                            <strong>
                              {shop.maximumCashback} {shop.maximumCashbackType}
                            </strong>{' '}
                            bei {shop.name}
                          </ShopTile>
                        </Grid>
                      ))}
                  </Grid>
                </Grid>

                {Auth.isLoggedIn() && (
                  <Grid item xs={12}>
                    <div id="iframe-loading">
                      <Loading />
                    </div>
                    <iframe
                      src={`https://buchung.reisewerk24.de/search/region?taid=plusrente&colors=p-93c01f,pl-fdd031,pd-729617,a-93c01f,al-fdd031,ad-729617,c-93c01f,cd-729617&agent=${fkn}`}
                      width="100%"
                      height="6000"
                      style={{
                        border: 0,
                      }}
                      onLoad={this.hideFrameLoading}
                    />
                  </Grid>
                )}
                {!Auth.isLoggedIn() && (
                  <Grid item md={12}>
                    <div
                      style={{
                        position: 'relative',
                      }}
                    >
                      <img
                        src="/assets/images/travel-blurred.jpg"
                        alt=""
                        style={{
                          width: '100%',
                        }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center',
                          left: 0,
                          top: 100,
                          right: 0,
                        }}
                      >
                        <div
                          style={{
                            width: 664,
                            background: '#004685',
                            color: '#fff',
                            padding: '2em',
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                          }}
                        >
                          <h3
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            Bitte einloggen
                          </h3>
                          <p>
                            Bitte loggen Sie sich ein, damit Sie sich Ihr
                            Cashback bei der Reisebuchung sichern können.
                            Anschließend haben Sie die Möglichkeit die
                            gewünschte Reise auszuwählen und zu buchen.
                          </p>
                          <AuthButton
                            color="primary"
                            variant="contained"
                            size="medium"
                            style={{
                              alignSelf: 'flex-end',
                              marginTop: '2em',
                            }}
                          >
                            Login
                          </AuthButton>
                        </div>
                      </div>
                    </div>
                  </Grid>
                )}

                <Grid
                  item
                  md={12}
                  style={{
                    marginTop: '2em',
                  }}
                >

                  <Hidden xsDown>
                    <p>
                      * Die Cashback-Auszahlung erfolgt im Folgemonat nach
                      Reiserückkehr direkt auf Ihr Plusrente-Konto. Ausgenommen
                      sind Steuern und Gebühren, Tourismusabgaben,
                      Servicepauschalen, Um- und Zubuchungen vor Ort, Treibstoff-
                      und Kerosinzuschläge, Übergepäck, Sitzplatzreservierungen,
                      einzeln gebuchte Versicherungen, An- und Abreisepakete sowie
                      Ausflugs- und Getränkepakete bei Kreuzfahrten, Bahn-, Flug-
                      und Fährtickets, einzeln gebuchte Transferleistungen,
                      persönliche Ausgaben (Verpflegung, Pay-TV, Parkgebühren,
                      Minibar, u. dgl.), stornierte Reisen, Reisen, bei denen der
                      Reiseveranstalter nach Buchung insolvent gegangen ist, ggf.
                      erhobene Kreditkartengebühren, Zustellbetten, E-Bikes bei
                      Fahrradreisen und Zuschläge Flug Upgrade bei Pauschalreisen.
                    </p>
                  </Hidden>

                </Grid>
              </Grid>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
