import React from 'react';
import { Icon } from '@material-ui/core';
import './ShopTile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { AuthButton } from '../AuthButton';
import Auth from '../../services/auth';
import Helper from '../../services/helper';

interface Props {
  children?: any;
  image?: string;
  buttonLabel?: string;
  morePath?: string;
  buttonLink?: string;
  buttonLinkTarget?: string;
  shopId?: string;
  history?: any;
  isFavorite?: boolean;
  onFavoriteChange?: (isFavorite: boolean) => void | Promise<void>;
}

export default function ShopTile(props: Props) {
  return (
    <div className="ShopTile">
      <div className="ShopTile-image-wrapper">
        {props.image && props.morePath && (
          <Link to={props.morePath}>
            <img className="ShopTile-image" src={props.image} alt="" />
          </Link>
        )}
        {props.image && !props.morePath && (
          <img className="ShopTile-image" src={props.image} alt="" />
        )}
      </div>

      <div className="ShopTile-main">
        <div className="ShopTile-content">{props.children}</div>

        {props.morePath && (
          <Link className="more" to={props.morePath}>
            Mehr Details
          </Link>
        )}

        {props.buttonLabel && props.buttonLink && (
          <AuthButton
            color="primary"
            variant="contained"
            size="medium"
            href={props.buttonLink}
            target={props.buttonLinkTarget ? props.buttonLinkTarget : '_blank'}
            onClick={
              props.shopId && props.history
                ? (e: any) =>
                    Helper.handleClickoutHint(
                      {
                        type: 'shop',
                        url: props.buttonLink,
                        shopId: props.shopId,
                      },
                      e,
                      props?.history
                    )
                : () => {}
            }
          >
            {props.buttonLabel}
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{
                marginLeft: 10,
              }}
            />
          </AuthButton>
        )}

        {props.isFavorite !== undefined && Auth.isLoggedIn() && (
          <div className="ShopTile-star">
            <Icon
              fontSize="large"
              onClick={() =>
                props.onFavoriteChange
                  ? props.onFavoriteChange(!props.isFavorite)
                  : {}
              }
            >
              {props.isFavorite ? 'star_filled' : 'star_outline'}
            </Icon>
          </div>
        )}
      </div>
    </div>
  );
}
