import React from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { Icon, Link } from "@material-ui/core";
import { hiddenRoutes, navigationItems, footerItems } from "../../routes";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <h2 className="primary-color">{children}</h2>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

export default function InfoPageModal({
  children,
  title,
  content,
  route
}: any) {
  const [open, setOpen] = React.useState(false);
  let stateContent = { title, content };

  const handleClickOpen = (e: any) => {
    e.preventDefault();
    if (stateContent.title && stateContent.content) setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const allRoutes = [...footerItems, ...navigationItems, ...hiddenRoutes];
  if (!stateContent.title && !stateContent.content) {
    allRoutes.forEach(r => {
      if (
        r.infoPage &&
        r.infoPage.route &&
        r.infoPage.content &&
        r.infoPage.route === route
      ) {
        stateContent = r.infoPage.content;
      }
    });
  }

  return (
    <span style={{ display: "inline-block" }}>
      <Link onClick={handleClickOpen}>{children}</Link>
      <Dialog onClose={handleClose} aria-labelledby="dialog-title" open={open}>
        <DialogTitle id="dialog-title" onClose={handleClose}>
          <span dangerouslySetInnerHTML={{ __html: stateContent.title }}></span>
        </DialogTitle>
        <DialogContent dividers>{stateContent.content}</DialogContent>
      </Dialog>
    </span>
  );
}
