import './Backlink.scss';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';
import { Link, useHistory } from 'react-router-dom';

interface Props {
  style?: CSSProperties;
  label: string;
  to?: string;
  blackLink?: boolean;
  state?: any;
}

export default function Backlink(props: Props) {
  const history = useHistory();

  return (
    <div className="Backlink" style={props.style}>
      <Link
        to={{
          pathname: props.to ? props.to : "",
          state: props.state ? props.state : {},
        }}
        onClick={() => {
          if (!props.to) history.goBack();
        }}
        className={props.blackLink ? "black" : ""}
      >
        <FontAwesomeIcon icon={faChevronLeft} size="sm" />
        {props.label}
      </Link>
    </div>
  );
}
