import "./Dashboard.scss";

import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize/TextareaAutosize";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import _ from "lodash";
import React from "react";
import { Fragment } from "react";
import { Redirect } from "react-router-dom";

import Backlink from "../../components/Backlink/Backlink";
import Box from "../../components/Box/Box";
import Hero from "../../components/Hero/Hero";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import Shop from "../../interfaces/shop";
import { User } from "../../interfaces/user";
import { Alert } from "../../services/alert";
import Auth from "../../services/auth";
import Helper from "../../services/helper";
import CashbackManager from "../../services/manager/CashbackManager";
import UserManager from "../../services/manager/UserManager";
import InfoPageModal from "../InfoPage/InfoPageModal";

interface Props {}

interface State {
  shops: Shop[];
  loading: boolean;
  user?: User;
  sent: boolean;
  privacy: boolean;
  transactionDate: Date;
  name: string;
  mail: string;
  shopId: string;
  cashBackRate: string;
  cashBackRateType: string;
  productName: string;
  price: number;
  orderNumber: string;
  billNumber: string;
  customerNumber: string;
  comment: string;
  errors: any;

  confirmedCashback?: any;
  pendingCashback?: any;
  cashoutProfile?: any;
  cleanCashbackRates?: any;
}

export default class CreateComplaint extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      shops: [],
      loading: true,
      sent: false,
      privacy: false,
      transactionDate: new Date(),
      name: "",
      mail: "",
      shopId: "",
      cashBackRate: "",
      cashBackRateType: "",
      productName: "",
      price: 0,
      orderNumber: "",
      billNumber: "",
      customerNumber: "",
      comment: "",
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.getFieldValue = this.getFieldValue.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const isFriendAccount =
      Auth.getTokenInfo().userInfo.isFriendAccount !== 1 &&
      Auth.getTokenInfo().userInfo.isFriendAccount !== "1";

    const [
      shops,
      user,
      { confirmed: confirmedCashback, open: pendingCashback },
      cashoutProfile,
    ] = await Promise.all([
      CashbackManager.findLastClickoutShops(),
      UserManager.me(),
      CashbackManager.getCashbackAmountSums(["confirmed", "open"]),
      isFriendAccount ? CashbackManager.getCashoutProfile() : (() => null)(),
    ]);

    let cleanShops = _.uniqBy(shops, "id");
    cleanShops = _.orderBy(cleanShops, ["name"], ["asc"]);

    if (cashoutProfile) {
      this.setState({
        cashoutProfile,
      });
    }

    this.setState({
      shops: cleanShops,
      name: `${user.userInfo.firstName} ${user.userInfo.lastName}`,
      mail: user.email,
      shopId: "",
      user,
      loading: false,
      confirmedCashback,
      pendingCashback,
    });
  }

  isValid() {
    let errorsCount = 0;
    const errors: any = {};

    if (!this.state.privacy) {
      errors.privacy = "Pflichtfeld";
      errorsCount++;
    } else {
      errors.privacy = "";
    }

    if (!this.state.cashBackRate) {
      errors.cashBackRate = "Pflichtfeld";
      errorsCount++;
    } else {
      errors.cashBackRate = "";
    }

    this.setState({ errors });
    return errorsCount === 0;
  }

  async handleSubmit() {
    if (!this.isValid()) return;

    this.setState({
      loading: true,
    });

    const [cashBackRate, cashBackRateType] = this.state.cashBackRate.split("|");

    await CashbackManager.createComplaint({
      shopId: this.state.shopId,
      fkn: Auth.getFKN(),
      name: this.state.name,
      mail: this.state.user ? this.state.user.email : this.state.mail,
      productName: this.state.productName,
      orderNumber: this.state.orderNumber,
      customerNumber: this.state.customerNumber,
      price: this.state.price,
      billNumber: this.state.billNumber,
      transactionDate: Helper.dateToString(this.state.transactionDate),
      comment: this.state.comment,
      cashBackRate,
      cashBackRateType: cashBackRateType === "%" ? "perc" : "eur",
    });

    Alert.success(
      "Vielen Dank für Ihre Anfrage!",
      "Ihre Nachbuchungsanfrage wird so schnell wie möglich bearbeitet."
    );

    this.setState({
      sent: true,
      loading: false,
    });
  }

  handleChange(field: string) {
    return (e: any) => {
      this.setState(
        {
          [field]:
            field === "transactionDate"
              ? e
              : field === "privacy"
              ? e.target.checked
              : e.target.value,
          ...(field === "shopId" ? { cashBackRate: "" } : {}),
        } as any,
        () => {
          if (field === "shopId") {
            const tempCashbackRates = this.state.shops.find(
              (shop) => shop.id === this.state.shopId
            )?.cashbackRates;

            if (tempCashbackRates) {
              const cleanCashbackRates = _.sortBy(
                _.uniqBy(tempCashbackRates, "amount"),
                ["type", "amount"]
              );

              this.setState({
                cleanCashbackRates,
              });
            }

            this.forceUpdate();
          }
        }
      );
    };
  }

  getFieldValue(field: string): string {
    const state: any = this.state as any;
    return state[field];
  }

  render() {
    if (this.state.sent) return <Redirect to="/reklamationen" />;

    return (
      <Layout>
        <div className="Dashboard">
          <Hero
            size="md"
            imageSrc="/assets/images/heroes/dashboard.jpg"
            imagePositionY={-280}
          >
            <h1>Neue Nachbuchungsanfrage</h1>
          </Hero>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Backlink label="zurück zur Übersicht" to="/profil" />

              <Grid container spacing={4}>
                <Grid item xs={12} md={9}>
                  <Box>
                    <h2>Neue Nachbuchungsanfrage</h2>

                    <Grid container spacing={2}>
                      {[
                        { label: "Name", field: "name" },
                        {
                          label: "Shop",
                          field: "shopId",
                          component: (
                            <FormControl variant="filled">
                              <InputLabel>Shop</InputLabel>
                              <Select
                                value={this.state.shopId}
                                onChange={this.handleChange("shopId")}
                              >
                                {this.state.shops.map((s, key) => (
                                  <MenuItem key={key} value={s.id}>
                                    {s.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ),
                        },
                        {
                          label: "Gekauft am",
                          field: "transactionDate",
                          component: (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd.MM.yyyy"
                                margin="normal"
                                label="Gekauft am"
                                value={this.state.transactionDate}
                                onChange={this.handleChange("transactionDate")}
                                style={{ margin: 0 }}
                                inputVariant={"filled"}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </MuiPickersUtilsProvider>
                          ),
                        },
                        { label: "Produkt", field: "productName" },
                        {
                          label: "Bestellnummer",
                          field: "orderNumber",
                        },
                        {
                          label: "Kundennummer",
                          field: "customerNumber",
                        },
                        { label: "Preis", field: "price" },
                        {
                          label: "Rechnungsnummer",
                          field: "billNumber",
                        },
                        {
                          label: "Erwartete/Verwendete Cashback-Rate",
                          field: "cashBackRate",
                          component: (
                            <Fragment>
                              <FormControl variant="filled">
                                <InputLabel>
                                  Erwartete/Verwendete Cashback-Rate
                                </InputLabel>
                                <Select
                                  value={this.state.cashBackRate}
                                  onChange={this.handleChange("cashBackRate")}
                                >
                                  {this.state.cleanCashbackRates &&
                                    this.state.cleanCashbackRates.map(
                                      (cashbackRate: any) => (
                                        <MenuItem
                                          value={
                                            cashbackRate.amount +
                                            "|" +
                                            cashbackRate.type
                                          }
                                        >
                                          {Helper.formatNumber(
                                            cashbackRate.amount,
                                            2
                                          ) +
                                            " " +
                                            cashbackRate.type}
                                        </MenuItem>
                                      )
                                    )}
                                </Select>
                              </FormControl>
                              {this.state.errors.cashBackRate && (
                                <small className="formError">
                                  {this.state.errors.cashBackRate}
                                </small>
                              )}
                            </Fragment>
                          ),
                        },
                        {
                          label: "Benutzte E-Mail Adresse",
                          field: "mail",
                        },
                        {
                          label: "Kommentar",
                          field: "comment",
                          component: (
                            <Fragment>
                              <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="Kommentar"
                              />
                            </Fragment>
                          ),
                        },
                      ].map(({ label, field, component }, key) => (
                        <Grid item xs={12} key={key}>
                          {component}
                          {!component && (
                            <Fragment>
                              <TextField
                                label={label}
                                variant="outlined"
                                autoComplete={"family-name"}
                                value={this.getFieldValue(field)}
                                onChange={this.handleChange(field)}
                              />
                              {this.state.errors[field] && (
                                <small className="formError">
                                  {this.state.errors[field]}
                                </small>
                              )}
                            </Fragment>
                          )}
                        </Grid>
                      ))}
                    </Grid>

                    <br />

                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={this.state.privacy}
                            onChange={this.handleChange("privacy")}
                            value="true"
                            color="primary"
                          />
                        }
                        label={
                          <p>
                            Ich habe die{" "}
                            <InfoPageModal route="datenschutz">
                              Datenschutz&shy;bestimmungen
                            </InfoPageModal>{" "}
                            gelesen und akzeptiere diese.
                          </p>
                        }
                      />
                    </FormControl>

                    <Grid
                      item
                      xs={12}
                      lg={12}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.handleSubmit}
                      >
                        Absenden
                      </Button>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
