import React from 'react';
import { Layout } from '../components/Layout/Layout';
import Hero from '../components/Hero/Hero';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import { Loading } from '../components/Loading';
import Box from '../components/Box/Box';
import UserManager from '../services/manager/UserManager';
import { Alert } from '../services/alert';
import { Redirect } from 'react-router-dom';

interface Props {
  location: any;
}

interface State {
  loading: boolean;
  sent: boolean;
}

export default class VerifyAccount extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      sent: false,
    };
  }

  async componentDidMount() {
    try {
      await UserManager.verify(this.props.location.search.substr(7));
    } catch (e) {
      Alert.error(
        'Konto aktivieren',
        'Fehler beim Aktivieren des Kontos. Bitte versuchen Sie es später erneut oder wenden Sie sich an den Support.'
      );
      this.setState({ loading: false });
      return;
    }

    this.setState({
      sent: true,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <Hero
          size="lg"
          imageSrc="/assets/images/heroes/faq.jpg"
          imagePositionY={-300}
        >
          <h1>Konto aktivieren</h1>
        </Hero>

        {this.state.sent && (
          <InnerLayout>
            <Box>
              <h2>Konto aktivieren</h2>
              <p>
                Ihr Konto wurde aktiviert. Sie können sich jetzt mit Ihren
                Zugangsdaten anmelden.
              </p>
            </Box>
          </InnerLayout>
        )}

        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && !this.state.sent && <Redirect to="/" />}
      </Layout>
    );
  }
}
