import './TeaserTile.scss';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title?: string;
  subTitle?: string;
  children?: any;
  image?: string;
  variant?: "primary" | "yellow";
  style?: any;

  buttonLabel?: string;
  onButtonClick?: () => void;
  buttonTo?: string;
}

export default function TeaserTile(props: Props) {
  return (
    <div className={"TeaserTile " + props.variant} style={{ ...props.style }}>
      {props.image && (
        <div className="TeaserTile-image-wrapper">
          <div
            className="TeaserTile-image"
            style={{
              backgroundImage: `url(${props.image}`,
            }}
          ></div>
          <div className="TeaserTile-backdrop"></div>
        </div>
      )}

      <div className="TeaserTile-main">
        <div className="TeaserTile-content">
          {props.title && (
            <h2 dangerouslySetInnerHTML={{ __html: props.title }}></h2>
          )}
          {props.subTitle && (
            <h3 dangerouslySetInnerHTML={{ __html: props.subTitle }}></h3>
          )}
        </div>

        {props.children}

        {props.buttonLabel && props.buttonTo && !props.buttonTo.startsWith("http") && (
          <Link to={props.buttonTo || "#"}>
            <Button
              color="primary"
              variant="contained"
              onClick={props.onButtonClick}
            >
              {props.buttonLabel}

              <FontAwesomeIcon
                icon={faArrowRight}
                style={{
                  marginLeft: 10,
                }}
              />
            </Button>
          </Link>
        )}

        {props.buttonLabel && props.buttonTo && props.buttonTo.startsWith("http") && (
          <a href={props.buttonTo} target="_blank">
            <Button
              color="primary"
              variant="contained"
            >
              {props.buttonLabel}

              <FontAwesomeIcon
                icon={faArrowRight}
                style={{
                  marginLeft: 10,
                }}
              />
            </Button>
          </a>
        )}
      </div>
    </div>
  );
}
