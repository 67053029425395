import React from "react";
import "./GoogleMap.scss";
import {
  GoogleApiWrapper,
  Map,
  Marker,
  GoogleAPI,
  Circle
} from "google-maps-react";

interface Props {
  google: GoogleAPI;
  loaded?: boolean;
  location?: { lat: number; lng: number };
  distance?: number;
}

export class MapContainer extends React.Component<Props, {}> {
  render() {
    const MyMap = Map as any;

    const pos = {
      lat: this.props.location ? this.props.location.lat : 51.94815,
      lng: this.props.location ? this.props.location.lng : 10.265166
    };
    return (
      <MyMap
        google={this.props.google}
        center={pos}
        initialCenter={pos}
        zoom={this.props.location ? 9 : 6}
      >
        {this.props.location && <Marker position={pos} />}
        {this.props.location && this.props.distance && (
          <Circle
            radius={this.props.distance * 1000}
            center={pos}
            strokeColor="transparent"
            ssssss
            strokeOpacity={0}
            strokeWeight={5}
            fillColor="#93c01f"
            fillOpacity={0.5}
          />
        )}
      </MyMap>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAQ7WiONoL_kKJhJwUw72RK8lABxzZsPN0",
  language: "de"
})(MapContainer);
