import './Dashboard.scss';

import {
  faArrowRight,
  faCloudDownloadAlt,
  faCoins,
  faCreditCard,
  faExclamation,
  faMapMarkerAlt,
  faUserCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, TextField } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';

import Hero from '../../components/Hero/Hero';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ShopTile from '../../components/ShopTile/ShopTile';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import RegionalOfferShop from '../../interfaces/regionalOfferShop';
import Shop from '../../interfaces/shop';
import { Tour } from '../../interfaces/Tour';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import RegionalOffersManager from '../../services/manager/RegionalOffersManager';
import TicketManager from '../../services/manager/TicketManager';
import KpiTile from './../../components/KpiTile/KpiTile';
import { VoucherManager } from '../../services/manager/VoucherManager';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Box from '../../components/Box/Box';

interface Props {
  history: any;
}

interface State {
  confirmedCashback?: number;
  pendingCashback?: number;
  cashoutProfile?: CashoutProfile;
  openComplaints?: number;
  favoriteShops: Shop[];
  favoriteTours: Tour[];
  favoriteRegionalOffers: RegionalOfferShop[];
  voucher: any;
  loading: boolean;
  canUseVouchers?: boolean;
  isFriendAccount?: boolean;
}

export default class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      favoriteShops: [],
      favoriteTours: [],
      favoriteRegionalOffers: [],
      voucher: '',
      loading: true,
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
    this.handleChangeTourFavorite = this.handleChangeTourFavorite.bind(this);
    this.handleChangeRegionalOfferShopFavorite = this.handleChangeRegionalOfferShopFavorite.bind(
      this
    );
    this.submitVoucher = this.submitVoucher.bind(this);
    this.fetchCashbackData = this.fetchCashbackData.bind(this);
  }

  async submitVoucher() {
    const response = await VoucherManager.submitVoucher(this.state.voucher);

    const LoginSwal = withReactContent(Swal);

    if (response.code === 200) {
      LoginSwal.fire({
        title: 'Erfolgreich',
        text: response.message,
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } else if (response.code === 400) {
      LoginSwal.fire({
        title: 'Fehlgeschlagen',
        text: 'Gutscheincode wurde bereits verwendet.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      LoginSwal.fire({
        title: 'Fehlgeschlagen',
        text: 'Gutscheincode ungültig.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  }

  fetchCashbackData = async () => {
    const isFriendAccount = [1, '1'].includes(
      Auth.getTokenInfo()?.userInfo?.isFriendAccount
    );

    const isProspectiveCustomer = [1, '1'].includes(
      Auth.getTokenInfo()?.userInfo?.prospectiveCustomer
    );

    const contractType = Auth.getTokenInfo()?.userInfo?.contractType;

    const [
      { confirmed: confirmedCashback, open: pendingCashback },
      openComplaints,
      favoriteShops,
      cashoutProfile,
    ] = await Promise.all([
      CashbackManager.getCashbackAmountSums(['confirmed', 'open']),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.findFavoriteShops(),
      isFriendAccount ? (() => null)() : CashbackManager.getCashoutProfile(),
    ]);

    if (
      !isFriendAccount &&
      contractType !== 'unknown' &&
      !isProspectiveCustomer
    ) {
      await CashbackManager.setAutoCashoutSubscribe({ status: true });
    }

    if (isProspectiveCustomer) {
      await CashbackManager.setAutoCashoutSubscribe({ status: false });
    }

    this.setState({
      confirmedCashback,
      pendingCashback,
      openComplaints,
      cashoutProfile: cashoutProfile || undefined,
      canUseVouchers: cashoutProfile?.autoCashOutSubscribe || isFriendAccount,
      favoriteShops: Array.isArray(favoriteShops) ? favoriteShops : [],
      isFriendAccount,
      loading: false,
    });
  };

  async componentDidMount() {
    this.setState({ loading: false });

    this.fetchCashbackData();

    const [favoriteTours, favoriteRegionalOffers] = await Promise.all([
      TicketManager.findFavorites(),
      RegionalOffersManager.findFavorites(),
    ]);

    this.setState({
      favoriteTours,
      favoriteRegionalOffers,
      loading: false,
    });
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.favoriteShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        favoriteShops: shops,
      });
    };
  }

  handleChangeTourFavorite(tour: Tour) {
    return async () => {
      await TicketManager.removeFavorite(tour);

      // Update view
      const favoriteTours = this.state.favoriteTours.filter(
        (t) => tour.id !== t.id
      );

      this.setState({
        favoriteTours,
      });
    };
  }

  handleChangeRegionalOfferShopFavorite(shop: RegionalOfferShop) {
    return async () => {
      await RegionalOffersManager.removeShopFavorite(shop.id);

      // Update view
      const favoriteRegionalOffers = this.state.favoriteRegionalOffers.filter(
        (t) => shop.id !== t.id
      );

      this.setState({
        favoriteRegionalOffers,
      });
    };
  }

  handleVoucherChange(event: any) {
    this.setState({ voucher: event.target.value });
  }

  getBrowserName() {
    if (
      /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/.exec(
        navigator.userAgent
      )
    )
      return 'chrome';

    if (/Firefox\/([0-9\.]+)(?:\s|$)/.exec(navigator.userAgent))
      return 'firefox';

    return '';
  }

  render() {
    return (
      <Layout>
        {/* <div className="HeaderHint">
          Aktuell werden keine Transaktionsbenachrichtigungen für Einkäufe per
          E-Mail versendet!
        </div> */}

        <div className="Dashboard">
          <Hero
            size="md"
            imageSrc="/assets/images/heroes/dashboard.jpg"
            imagePositionY={-280}
          >
            <h1>Meine plusrente</h1>
          </Hero>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <h2
                style={{
                  position: 'relative',
                  top: -10,
                  marginTop: 0,
                }}
              >
                Hallo{' '}
                {`${Auth.getTokenInfo()?.userInfo?.firstName ||
                  ''} ${Auth.getTokenInfo()?.userInfo?.lastName || ''}`}
              </h2>

              <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                  <KpiTile
                    color="#fff"
                    bgColor="#93c01f"
                    title="plusrente Guthaben"
                    icon={<FontAwesomeIcon icon={faCoins} />}
                    link="/transaktionen/uebersicht"
                    style={{
                      height: '100%',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <div style={{ textAlign: 'right', marginRight: 20 }}>
                        <h3>bestätigt:</h3>
                        <h3>offen: </h3>
                      </div>
                      <div style={{ textAlign: 'right' }}>
                        <h3>
                          {Helper.formatNumber(
                            this.state.confirmedCashback || 0,
                            2
                          )}{' '}
                          €
                        </h3>
                        <h3>
                          {Helper.formatNumber(
                            this.state.pendingCashback || 0,
                            2
                          )}{' '}
                          €
                        </h3>
                      </div>
                    </div>
                  </KpiTile>
                </Grid>
                {!this.state.isFriendAccount && (
                  <Grid item xs={12} md={4}>
                    <KpiTile
                      color="#fff"
                      bgColor="#009FD6"
                      title="Meine Auszahlungen"
                      icon={<FontAwesomeIcon icon={faCoins} />}
                      link="/auszahlungen"
                      style={{
                        height: '100%',
                      }}
                    >
                      <h3>
                        {this.state.cashoutProfile &&
                          Helper.formatPrice(
                            this.state.cashoutProfile.paidOutCash +
                              this.state.cashoutProfile.externalPaidOutCash || 0
                          )}
                      </h3>
                    </KpiTile>
                  </Grid>
                )}

                <Grid item xs={12} md={4}>
                  <KpiTile
                    color="#fff"
                    bgColor="#FCC200"
                    title="Meine Nachbuchungsanfragen"
                    icon={<FontAwesomeIcon icon={faExclamation} />}
                    link="/reklamationen"
                    style={{
                      height: '100%',
                    }}
                  >
                    <h3>
                      {this.state.openComplaints || 0} offene
                      Nachbuchungsanfragen
                    </h3>
                  </KpiTile>
                </Grid>

                <Grid item xs={12} md={8}>
                  {this.state.canUseVouchers ? (
                    <Box style={{ minHeight: 250 }}>
                      <h2 style={{ fontWeight: 400, fontSize: '1.8rem' }}>
                        Gutscheincode erhalten?
                      </h2>

                      <p>
                        Lösen Sie Ihren Gutscheincode für Ihre nächste Reise-
                        oder Ticketbuchung oder durch einen Einkauf unter{' '}
                        <a href="/shops-und-produkte">
                          Shops&nbsp;&amp;&nbsp;Produkte
                        </a>{' '}
                        ein.
                      </p>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={7}>
                          <TextField
                            variant="outlined"
                            onChange={(event: any) =>
                              this.handleVoucherChange(event)
                            }
                            placeholder="Gutscheincode"
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <div className="buttonWrapper">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={this.submitVoucher}
                              style={{ marginTop: 5 }}
                            >
                              <span>Gutscheincode prüfen</span>
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <Box
                      style={{
                        pointerEvents: 'none',
                        opacity: 0.5,
                        minHeight: 250,
                      }}
                    >
                      <h2 style={{ fontWeight: 400, fontSize: '1.8rem' }}>
                        Gutscheincode erhalten?
                      </h2>

                      <p>
                        Diese Funktion ist nur für Kunden mit einem aktiven
                        plusrente-Vertrag. Sobald Sie Ihre Versicherungsnummer
                        in Ihren Einstellungen hinterlegt haben, wird diese
                        Funktion für Sie eingeblendet.
                      </p>

                      <Grid container spacing={2}>
                        <Grid item xs={12} md={7}>
                          <TextField
                            variant="outlined"
                            placeholder="Gutscheincode"
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <div className="buttonWrapper">
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{ marginTop: 5 }}
                            >
                              <span>Gutscheincode prüfen</span>
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} md={4}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: 250,
                    }}
                  >
                    {this.getBrowserName() && (
                      <KpiTile
                        bgColor="#fff"
                        color="#93c01f"
                        icon={<FontAwesomeIcon icon={faCloudDownloadAlt} />}
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                        }}
                        externalLink={
                          this.getBrowserName() === 'chrome'
                            ? 'https://chrome.google.com/webstore/detail/plusrente-toolbar/bbgajnbjbdilhkppmmpmgaamnkbcbgll?authuser=0'
                            : this.getBrowserName() === 'firefox'
                            ? 'https://addons.mozilla.org/de/firefox/addon/plusrente-toolbar/'
                            : '#'
                        }
                      >
                        <h2 className="primary-color">Cashback Radar</h2>
                      </KpiTile>
                    )}
                    <KpiTile
                      bgColor="#fff"
                      color="#93c01f"
                      icon={<FontAwesomeIcon icon={faUserCog} />}
                      style={{
                        flex: 1,
                        justifyContent: 'center',
                        marginBottom: 0,
                      }}
                      link="/einstellungen"
                    >
                      <h2 className="primary-color">Meine Einstellungen</h2>
                    </KpiTile>
                  </div>
                </Grid>

                {this.state.favoriteShops?.length > 0 && (
                  <Grid item xs={12}>
                    <h2>Meine Favoriten</h2>
                  </Grid>
                )}

                <Grid container spacing={2}>
                  {this.state.favoriteShops?.map((shop: Shop, key) => (
                    <Grid item xs={6} md={3} key={key}>
                      <ShopTile
                        image={shop.logo}
                        buttonLabel="zum Shop"
                        morePath={`/shop/${shop.id}`}
                        buttonLink={shop.link}
                        isFavorite={shop.isFavorite}
                        onFavoriteChange={this.handleFavoriteChange(shop)}
                        history={this.props.history}
                        shopId={shop.id}
                      >
                        Bis zu{' '}
                        <strong>
                          {shop.maximumCashback} {shop.maximumCashbackType}
                        </strong>{' '}
                        bei {shop.name}
                      </ShopTile>
                    </Grid>
                  ))}

                  {this.state.favoriteTours.map((tour: Tour, key) => (
                    <Grid item xs={12} md={3} key={key}>
                      <ShopTile
                        image={
                          tour.wideImage
                            ? tour.wideImage
                            : '/assets/images/heroes/tickets.jpg'
                        }
                        buttonLabel="zum Shop"
                        buttonLink={''}
                        isFavorite={tour.isFavorite}
                        onFavoriteChange={this.handleChangeTourFavorite(tour)}
                      >
                        <strong style={{ color: '#000' }}>{tour.title}</strong>
                        <br />
                        {new Date(tour.startDate).toLocaleDateString(
                          'de-DE'
                        )} -{' '}
                        {new Date(tour.endDate).toLocaleDateString('de-DE')}
                        <NavLink to={`/tickets/${tour.id}`}>
                          <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: 20 }}
                          >
                            Tickets ab {Helper.formatNumber(tour.fromPrice, 2)}{' '}
                            €{' '}
                            <FontAwesomeIcon
                              icon={faArrowRight}
                              style={{
                                marginLeft: 10,
                              }}
                            />
                          </Button>
                        </NavLink>
                      </ShopTile>
                    </Grid>
                  ))}

                  {this.state.favoriteRegionalOffers?.map(
                    (shop: RegionalOfferShop, key) => (
                      <Grid item xs={6} md={3} key={key}>
                        <ShopTile
                          image={
                            shop.images[0]
                              ? shop.images[0].url
                              : '/assets/images/heroes/tickets.jpg'
                          }
                          buttonLabel="zu den Angeboten"
                          buttonLink={`/vor-ort/${shop.id}`}
                          isFavorite={true}
                          onFavoriteChange={this.handleChangeRegionalOfferShopFavorite(
                            shop
                          )}
                          buttonLinkTarget="_self"
                        >
                          <strong>
                            {shop.displayName ? shop.displayName : shop.name}
                          </strong>

                          <div className="ShopTile-Regional">
                            {shop.offers?.length > 0 && (
                              <div
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <FontAwesomeIcon icon={faCreditCard} />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: shop.offers[0].description,
                                  }}
                                />
                              </div>
                            )}
                            {shop.branches?.length > 0 && (
                              <div
                                style={{
                                  display: 'flex',
                                }}
                              >
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                <div>
                                  {shop.branches[0].street}{' '}
                                  {shop.branches[0].streetNumber},{' '}
                                  {shop.branches[0].city}
                                </div>
                              </div>
                            )}
                          </div>
                        </ShopTile>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
