import React from "react";
import Box from "../Box/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

export default ({ style, title, tel, timePeriods }: any) => {
  timePeriods = timePeriods || [
    { title: "Mo.-Fr.:", period: "08:00 - 20:00 Uhr" },
    { title: "Sa.:", period: "08:00 - 14:00 Uhr" }
  ];
  tel = tel || "089 6787 5075";
  return (
    <Box style={{ ...style }}>
      <h2>{title || "Service-Center"}</h2>
      <h3 style={{ marginBottom: 15 }}>
        <FontAwesomeIcon
          icon={faPhone}
          style={{ marginRight: 10, color: "#93c01f" }}
        />
        <a style={{ color: "#000" }} href={"tel:" + tel.replace(/\s/g, "")}>
          {tel}
        </a>
      </h3>
      {timePeriods.map(
        (item: { title: string; period: string }, key: number) => (
          <div
            key={key}
            style={{
              display: "flex",
              justifyContent: "space-between",
              lineHeight: 1.6,
              fontSize: "1.2rem"
            }}
          >
            <div>{item.title}</div>
            <div style={{ fontWeight: 400 }}>{item.period}</div>
          </div>
        )
      )}
    </Box>
  );
};
