import { API } from '../API';
import { User } from '../../interfaces/user';

export default class UserManager {
  static async me(): Promise<User> {
    const me = await API.get(`users/me`);

    me.userInfo.isFriendAccount = me.userInfo.isFriendAccount === '1';
    me.userInfo.prospectiveCustomer = me.userInfo.prospectiveCustomer === '1';
    return me;
  }

  static async updateSettings(settings: any): Promise<User> {
    return await API.patch(`users/settings`, settings);
  }

  static async updateContractId(contractId: string): Promise<User> {
    return await API.patch(`users/me`, {
      data: {
        contractId: contractId,
      },
    });
  }

  static async updateEmail(email: string): Promise<User> {
    return await API.patch(`users/me`, {
      email,
      username: email,
    });
  }

  static async updatePassword(password: string): Promise<User> {
    return await API.patch(`users/me`, {
      password,
    });
  }

  static async resetPassword(email: string): Promise<void> {
    return await API.post(`users/resetPassword`, { email });
  }

  static async verifyResetPassword(
    password: string,
    token: string
  ): Promise<void> {
    return await API.post(`users/verifyResetPassword`, { password, token });
  }

  static async verify(token: string): Promise<void> {
    return await API.post(`users/verify`, { token });
  }

  static async resendVerification(username: string): Promise<void> {
    return await API.post(`users/resendVerification`, { username });
  }
}
