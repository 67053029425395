import React from "react";
import CidaasSDK from 'cidaas-javascript-sdk';
import Config from "../../services/config";
import Auth from '../../services/auth';
import {AppLoading} from "../../components/AppLoading";

interface Props {}

interface State {
  loading: boolean;
}

export default class SSOCallback extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const queryParams = new URLSearchParams(window.location.hash.replace("#", "&"));
    const accessToken = queryParams.get('access_token');

    if(accessToken !== null) {
      await Auth.loginWithToken(accessToken);
    }

    this.setState({
      loading: false,
    });
  }

  render() {
   return (<AppLoading/>);
  }
}
