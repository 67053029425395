import React from "react";
import { Layout } from "../../components/Layout/Layout";
import Hero from "../../components/Hero/Hero";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from "@material-ui/core";
import Box from "./../../components/Box/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ServiceTile from "../../components/ServiceTile/ServiceTile";
import TeaserTile from "../../components/TeaserTile/TeaserTile";
import CashbackManager from "../../services/manager/CashbackManager";
import Shop from "../../interfaces/shop";
import ShopTile from "../../components/ShopTile/ShopTile";
import { Loading } from "../../components/Loading";
import Backlink from "../../components/Backlink/Backlink";
import { Redirect } from "react-router-dom";
import UserManager from "../../services/manager/UserManager";
import { User } from "../../interfaces/user";
import helper from "../../services/helper";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

interface State {
  loading: boolean;
  redirect: boolean;

  user?: User;

  email: string;
  password: string;
  password2: string;

  errors: object;
}

interface Props {}

export default class ChangeCredentials extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,

      email: "",
      password: "",
      password2: "",

      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  async componentDidMount() {
    const user = await UserManager.me();

    this.setState({
      loading: false,
      user,

      email: user.email
    });
  }

  handleChange(field: string) {
    return (e: any) => {
      this.setState({
        [field]: e.target.value
      } as any);
    };
  }

  validate() {
    const errors = {} as any;
    const state = this.state as any;
    const fields = ["email"];

    fields.forEach((field: string) => {
      if (
        !state[field] ||
        (typeof state[field] === "string" && state[field].trim() === "")
      )
        errors[field] = "Dieses Feld muss ausgefüllt werden";
    });

    if (state.password.trim() !== "" && state.password !== state.password2)
      errors.password2 = "Die Passwörter stimmen nicht überein";

    this.setState({
      errors
    });

    return JSON.stringify(errors) === "{}";
  }

  async handleSubmit() {
    if (!this.validate()) return;

    if (this.state.user && this.state.email !== this.state.user.email) {
      await UserManager.updateEmail(this.state.email);
    }

    if (this.state.password.trim() !== "") {
      await UserManager.updatePassword(this.state.password);
    }

    this.setState({
      redirect: true
    });
  }

  render() {
    const errors = this.state.errors as any;

    if (this.state.redirect) return <Redirect to="/einstellungen" />;

    return (
      <Layout>
        <Hero
          size="lg"
          imageSrc="/assets/images/heroes/dashboard.jpg"
          imagePositionY={-280}
        >
          <h1>Ihre Zugangsdaten</h1>
        </Hero>

        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && this.state.user && (
          <InnerLayout>
            <Backlink label="zurück zur Übersicht" to="/einstellungen" />

            <Box>
              <h2>Ihre Zugangsdaten</h2>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="E-Mail-Adresse"
                    variant="filled"
                    autoComplete={"email"}
                    value={this.state.email}
                    onChange={this.handleChange("email")}
                    error={errors.email !== undefined}
                    helperText={errors.email ? errors.email : ""}
                  />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <TextField
                    label="Passwort"
                    type="password"
                    variant="filled"
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                    error={errors.password !== undefined}
                    helperText={errors.password ? errors.password : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Passwort wiederholen"
                    type="password"
                    variant="filled"
                    value={this.state.password2}
                    onChange={this.handleChange("password2")}
                    error={errors.password2 !== undefined}
                    helperText={errors.password2 ? errors.password2 : ""}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end"
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmit}
                  >
                    Speichern
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </InnerLayout>
        )}
      </Layout>
    );
  }
}
