import { stringify } from 'querystring';

import Auth from './auth';
import Config from './config';

export class API {
  static token?: string;

  static encodeURL(obj: any, prefix?: any): string {
    const str = [];
    let p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p];
        str.push(
          v !== null && typeof v === 'object'
            ? API.encodeURL(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v)
        );
      }
    }
    return str.join('&');
  }

  static async request(
    method: string,
    path: string,
    body?: any,
    parseJSON?: boolean
  ) {
    let response;

    const opts: any = {
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'x-tenant': '12',
      },
    };

    if (API.token) {
      opts.headers.Authorization = `Bearer ${API.token}`;
    }

    if (body) {
      for (const key in body) {
        if (body.hasOwnProperty(key)) {
          if (body[key] instanceof Date) {
            const date = body[key] as Date;
            body[key] = `${date.getFullYear()}-${
              date.getMonth() + 1 < 10
                ? `0${date.getMonth() + 1}`
                : date.getMonth() + 1
            }-${date.getDate()}T00:00:00.000`;
          }
        }
      }
      Object.assign(opts, {
        body: JSON.stringify(body),
      });
    }

    // Perform API request
    const apiURL = Config.getAPIURL();
    try {
      response = await fetch(`${apiURL}/${path}`, opts);
    } catch (e) {
      if (
        e.message === 'Failed to fetch' &&
        path.indexOf('checkLogin') === -1
      ) {
        Auth.logout();
        return;
      }
      throw e;
    }

    if (response.status === 400) {
      throw new Error(await response.json());
    }

    // Invalid token
    if (response.status === 401) {
      if (
        path.indexOf('checkLogin') === -1 &&
        path.indexOf('auth/login') === -1 &&
        path.indexOf('users/me') === -1 &&
        path.indexOf('users/verify') === -1
      ) {
        Auth.logout();
        return;
      }
      throw new Error(`Unauthorized (${await response.text()})`);
    }

    // Invalid token
    if (response.status === 403) {
      if (path.indexOf('checkLogin') === -1) {
        Auth.logout();
        return;
      }
      throw new Error('Forbidden');
    }

    // Invalid status codes
    if (response.status > 299) {
      throw new Error(`Invalid API response: ${await response.text()}`);
    }

    if (['DELETE', 'PUT'].indexOf(method) > -1) {
      // Method excepts no response
      return true;
    } else {
      if (parseJSON === false) {
        return await response.text();
      } else {
        return await response.json();
      }
    }
  }

  static async get(path: string, params?: any) {
    let queryString = stringify(params);
    if (queryString !== '') {
      queryString = '?' + queryString;
    }
    return API.request('GET', path + queryString);
  }

  static async post(path: string, body: any, parseJSON?: boolean) {
    return API.request('POST', path, body, parseJSON);
  }

  static async patch(path: string, body: any) {
    return API.request('PATCH', path, body);
  }

  static async put(path: string, body: any) {
    return API.request('PUT', path, body);
  }

  static async delete(path: string) {
    return API.request('DELETE', path);
  }

  static setToken(token?: string) {
    API.token = token;
  }
}
