import React from "react";
import { Layout } from "../../components/Layout/Layout";
import Hero from "../../components/Hero/Hero";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import {
  Grid,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from "@material-ui/core";
import Box from "./../../components/Box/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ServiceTile from "../../components/ServiceTile/ServiceTile";
import TeaserTile from "../../components/TeaserTile/TeaserTile";
import CashbackManager from "../../services/manager/CashbackManager";
import Shop from "../../interfaces/shop";
import ShopTile from "../../components/ShopTile/ShopTile";
import { Loading } from "../../components/Loading";
import Backlink from "../../components/Backlink/Backlink";
import { Redirect } from "react-router-dom";
import UserManager from "../../services/manager/UserManager";
import { User } from "../../interfaces/user";
import helper from "../../services/helper";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";

interface State {
  loading: boolean;
  redirect: boolean;

  user?: User;

  salutation: string;
  firstName: string;
  lastName: string;
  dayOfBirth?: Date;

  errors: object;
}

interface Props {}

export default class ChangeSettings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      redirect: false,

      salutation: "",
      firstName: "",
      lastName: "",

      errors: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  async componentDidMount() {
    const user = await UserManager.me();

    this.setState({
      loading: false,
      user,

      salutation: user.userInfo.salutation,
      firstName: user.userInfo.firstName,
      lastName: user.userInfo.lastName,
      dayOfBirth: new Date(user.userInfo.dayOfBirth)
    });
  }

  handleChange(field: string) {
    return (e: any) => {
      this.setState({
        [field]: field === "dayOfBirth" ? e : e.target.value
      } as any);
    };
  }

  validate() {
    const errors = {} as any;
    const state = this.state as any;
    const fields = ["salutation", "firstName", "lastName"];

    fields.forEach((field: string) => {
      if (
        !state[field] ||
        (typeof state[field] === "string" && state[field].trim() === "")
      )
        errors[field] = "Dieses Feld muss ausgefüllt werden";
    });

    this.setState({
      errors
    });

    return JSON.stringify(errors) === "{}";
  }

  async handleSubmit() {
    if (!this.validate()) return;

    await UserManager.updateSettings({
      salutation: this.state.salutation,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      dayOfBirth: helper.dateToString(this.state.dayOfBirth)
    });

    this.setState({
      redirect: true
    });
  }

  render() {
    const errors = this.state.errors as any;

    if (this.state.redirect) return <Redirect to="/einstellungen" />;

    return (
      <Layout>
        <Hero
          size="lg"
          imageSrc="/assets/images/heroes/dashboard.jpg"
          imagePositionY={-280}
        >
          <h1>Persönliche Angaben</h1>
        </Hero>

        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && this.state.user && (
          <InnerLayout>
            <Backlink label="zurück zur Übersicht" to="/einstellungen" />

            <Box>
              <h2>Persönliche Angaben</h2>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl variant="filled">
                    <InputLabel id="anrede-label">Anrede</InputLabel>
                    <Select
                      labelId="anrede-label"
                      id="anrede"
                      value={this.state.salutation}
                      onChange={this.handleChange("salutation")}
                      error={errors.salutation !== undefined}
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value={"Herr"}>Herr</MenuItem>
                      <MenuItem value={"Frau"}>Frau</MenuItem>
                      <MenuItem value={"Divers"}>Divers</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Vorname"
                    variant="filled"
                    autoComplete={"given-name"}
                    value={this.state.firstName}
                    onChange={this.handleChange("firstName")}
                    error={errors.firstName !== undefined}
                    helperText={errors.firstName ? errors.firstName : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Nachname"
                    variant="filled"
                    autoComplete={"family-name"}
                    value={this.state.lastName}
                    onChange={this.handleChange("lastName")}
                    error={errors.lastName !== undefined}
                    helperText={errors.lastName ? errors.lastName : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd.MM.yyyy"
                      margin="normal"
                      label="Geburtsdatum"
                      value={this.state.dayOfBirth}
                      onChange={this.handleChange("dayOfBirth")}
                      style={{ margin: 0 }}
                      inputVariant={"filled"}
                      autoComplete={"bday"}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={12}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end"
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmit}
                  >
                    Speichern
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </InnerLayout>
        )}
      </Layout>
    );
  }
}
