import React from "react";
import "./Pagination.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleLeft,
  faAngleDoubleRight,
  faAngleRight
} from "@fortawesome/free-solid-svg-icons";
import { Hidden } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

interface Props {
  currentPage: number;
  numberOfPages: number;
  onPageChange: (page: number) => void;
}

export default function Pagination(props: Props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const maxPrevNext = matches ? 2 : 3;

  const pagesBefore = [];
  for (
    let i = props.currentPage - 1;
    i > 0 && pagesBefore.length < maxPrevNext;
    i--
  ) {
    pagesBefore.push(i);
  }

  const pagesAfter = [];
  for (
    let i = props.currentPage + 1;
    i <= props.numberOfPages && pagesAfter.length < maxPrevNext;
    i++
  ) {
    pagesAfter.push(i);
  }

  return (
    <div className="Pagination">
      {props.currentPage > 1 && (
        <span onClick={() => props.onPageChange(1)}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </span>
      )}

      <Hidden only={"xs"}>
        {props.currentPage > 1 && (
          <span onClick={() => props.onPageChange(props.currentPage - 1)}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </span>
        )}
      </Hidden>

      {pagesBefore.reverse().map((page, key) => (
        <span key={key} onClick={() => props.onPageChange(page)}>
          {page}
        </span>
      ))}
      <span className="active">{props.currentPage}</span>
      {pagesAfter.map((page, key) => (
        <span key={key} onClick={() => props.onPageChange(page)}>
          {page}
        </span>
      ))}

      <Hidden only={"xs"}>
        {props.currentPage < props.numberOfPages && (
          <span onClick={() => props.onPageChange(props.currentPage + 1)}>
            <FontAwesomeIcon icon={faAngleRight} />
          </span>
        )}
      </Hidden>

      {props.currentPage < props.numberOfPages && (
        <span onClick={() => props.onPageChange(props.numberOfPages)}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </span>
      )}
    </div>
  );
}
