import Cashback from './features/Cashback/Cashback';
import { Contact } from './features/Contact/Contact';
import ChangeCredentials from './features/Dashboard/ChangeCredentials';
import ChangeInterests from './features/Dashboard/ChangeInterests';
import ChangeSettings from './features/Dashboard/ChangeSettings';
import Complaints from './features/Dashboard/Complaints';
import CreateComplaint from './features/Dashboard/CreateComplaint';
import Dashboard from './features/Dashboard/Dashboard';
import Payouts from './features/Dashboard/Payouts';
import Settings from './features/Dashboard/Settings';
import Transactions from './features/Dashboard/Transactions';
import TransactionsOverview from './features/Dashboard/TransactionsOverview';
import Demo from './features/Demo/Demo';
import { Agb } from './features/InfoPage/Pages/Agb';
import { Faq } from './features/InfoPage/Pages/Faq';
import { Imprint } from './features/InfoPage/Pages/Imprint';
import { Privacy } from './features/InfoPage/Pages/Privacy';
import { Toolbar } from './features/InfoPage/Pages/Toolbar';
import { TravelInsurance } from './features/InfoPage/Pages/TravelInsurance';
import { Logout } from './features/Logout';
import RegionalOffers from './features/RegionalOffers/RegionalOffers';
import ResetPassword from './features/ResetPassword';
import Special from './features/Special/Special';
import Travel from './features/Travel/Travel';
import SSOCallback from './features/SSO/SSOCallback';
import NewsletterConfirmation from './features/NewsletterConfirmation';
import ClickoutHint from './features/ClickoutHint/ClickoutHint';

export interface Route {
  title: string;
  link: string;
  component?: any;
  infoPage?: { route: string; content: any };
  private?: boolean;
}

export const navigationItems: Route[] = [
  {
    title: 'Shops & Produkte',
    link: '/shops-und-produkte',
    component: Cashback,
  },
  {
    title: 'Specials',
    link: '/angebote',
    component: Special,
  },
  {
    title: 'Reisen',
    link: '/reisen',
    component: Travel,
  },
  {
    title: 'Vor Ort',
    link: '/vor-ort',
    component: RegionalOffers,
  },
  {
    title: 'Hilfe',
    link: '/info/faq',
    infoPage: {
      route: 'faq',
      content: Faq,
    },
  },
];

export const footerItems: Route[] = [
  {
    title: 'die Bayerische',
    link: 'https://www.diebayerische.de/',
  },
  {
    title: 'AGB',
    link: '/info/agb',
    infoPage: {
      route: 'agb',
      content: Agb,
    },
  },
  {
    title: 'Datenschutz',
    link: '/info/datenschutz',
    infoPage: {
      route: 'datenschutz',
      content: Privacy,
    },
  },
  {
    title: 'Impressum',
    link: '/info/impressum',
    infoPage: {
      route: 'impressum',
      content: Imprint,
    },
  },
  {
    title: 'Kontakt',
    link: '/info/kontakt',
    component: Contact,
  },
];

export const hiddenRoutes: Route[] = [
  {
    title: 'Cashback-Radar',
    link: '/info/toolbar',
    infoPage: {
      route: 'toolbar',
      content: Toolbar,
    },
  },
  {
    title: 'Einstellungen',
    link: '/einstellungen',
    component: Settings,
    private: true,
  },
  {
    title: 'Nachbuchungsanfragen',
    link: '/reklamationen',
    component: Complaints,
    private: true,
  },
  {
    title: 'Auszahlungen',
    link: '/auszahlungen',
    component: Payouts,
    private: true,
  },
  {
    title: 'Transaktionen',
    link: '/transaktionen',
    component: Transactions,
    private: true,
  },
  {
    title: 'Transaktionen Übersicht',
    link: '/transaktionen/uebersicht',
    component: TransactionsOverview,
    private: true,
  },
  {
    title: 'demo',
    link: '/demo',
    component: Demo,
  },
  {
    title: 'Profil',
    link: '/profil',
    component: Dashboard,
    private: true,
  },
  {
    title: 'nachbuchungsanfrage',
    link: '/nachbuchungsanfrage',
    component: CreateComplaint,
    private: true,
  },
  // {
  //   title: "reisebuchung",
  //   link: "/reisebuchung",
  //   component: TravelBooking,
  // },
  {
    title: 'persoenliche-angaben',
    link: '/persoenliche-angaben',
    component: ChangeSettings,
    private: true,
  },
  {
    title: 'ihre-zugangsdaten',
    link: '/ihre-zugangsdaten',
    component: ChangeCredentials,
    private: true,
  },
  {
    title: 'ihre-interessen',
    link: '/ihre-interessen',
    component: ChangeInterests,
    private: true,
  },
  {
    title: 'Abmelden',
    link: '/abmelden',
    component: Logout,
    private: true,
  },
  {
    title: 'Reiseversicherung',
    link: '/info/reiseversicherung',
    infoPage: {
      route: 'reiseversicherung',
      content: TravelInsurance,
    },
    private: true,
  },
  {
    title: 'Passwort zurücksetzen',
    link: '/passwort-vergessen',
    component: ResetPassword,
    private: false,
  },
  {
    title: 'SSO Login',
    link: '/sso-callback',
    component: SSOCallback,
    private: false,
  },
  {
    title: 'Newsletter-Anmeldung',
    link: '/newsletter/anmeldung',
    component: NewsletterConfirmation,
    private: false,
  },
  {
    title: 'Clickout-Hinweis',
    link: '/clickout',
    component: ClickoutHint,
    private: false,
  },
];
