import './Navigation.scss';

import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Hidden, Icon } from '@material-ui/core';
import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import Auth from '../../services/auth';
import CidaasSDK from 'cidaas-javascript-sdk';
import Config from '../../services/config';

interface Props {
  items: any[];
  toggleDrawer: any;

  loggedIn: boolean;
  loginError?: string;

  onChangeUsername: (username: string) => void;
  onChangePassword: (password: string) => void;
  onLogin: () => void;

  username: string;
  password: string;

  isLoginOpen: boolean;
  onToggleLogin: () => void;
}

export default function Navigation(props: Props) {
  const location = useLocation();

  const options = {
    authority: Config.getEnv('REACT_APP_CIDAAS_AUTHORITY'),
    client_id: Config.getEnv('REACT_APP_CIDAAS_CLIENT_ID'),
    redirect_uri: Config.getEnv('REACT_APP_CIDAAS_REDIRECT_URI'),
    response_type: 'token',
    scope: 'profile',
    mode: 'redirect',
  };
  const cidaas = new CidaasSDK.WebAuth(options);

  return (
    <div
      className={`${
        Auth.isLoggedIn() && location.pathname === '/profil' ? 'showHint' : ''
      } navigation`}
    >
      <div className="logo">
        <NavLink to={'/'} style={{ margin: 0 }}>
          <img src="/plusrente.svg" alt="Logo der plusrente" />
        </NavLink>
      </div>
      <div style={{ display: 'flex', position: 'relative' }}>
        <nav>
          {props.items.map((item, index) => {
            if (item.title === 'Registrierung') {
              if (Auth.isLoggedIn()) return null;
              item.link = '';
              return (
                <div key={'nav-' + index}>
                  <Link
                    to={item.link}
                    onClick={() => {
                      cidaas.registerWithBrowser();
                    }}
                  >
                    {item.title}
                  </Link>
                </div>
              );
            }

            if (props.loggedIn && item.title === 'Registrierung') return null;
            return (
              <div key={'nav-' + index}>
                <NavLink activeClassName="active" to={item.link}>
                  {item.title}
                </NavLink>
              </div>
            );
          })}
        </nav>
        {!props.loggedIn && (
          <div className="login">
            <Button
              variant="outlined"
              color="primary"
              aria-controls="login-menu"
              aria-haspopup="true"
              onClick={() => {
                cidaas.loginWithBrowser();
              }}
              id="loginButton"
            >
              <Hidden smDown>Login</Hidden>
              <Hidden mdUp>
                <Icon>person</Icon>
              </Hidden>
            </Button>
          </div>
        )}
        {props.loggedIn && (
          <div className="login">
            <Link to="/profil">
              <Button variant="contained" color="primary">
                <Hidden smDown>Meine plusrente</Hidden>
                <Hidden mdUp>
                  <Icon>person</Icon>
                </Hidden>

                <Hidden smDown>
                  <div
                    style={{
                      width: 35,
                    }}
                  />
                  <Link
                    to="/abmelden"
                    style={{
                      background: 'rgba(255,255,255,0.4)',
                      color: '#222',
                      height: 40,
                      width: 40,
                      position: 'absolute',
                      top: -2,
                      right: -2,
                      bottom: 0,
                      borderBottomRightRadius: 50,
                      borderTopRightRadius: 50,
                      margin: 0,
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      color="#747474"
                      style={{
                        marginTop: 11,
                      }}
                    />
                  </Link>
                </Hidden>
              </Button>
            </Link>
          </div>
        )}
      </div>

      <div className="burger">
        <Button onClick={() => props.toggleDrawer()}>
          <Icon>menu</Icon>
        </Button>
      </div>
    </div>
  );
}
