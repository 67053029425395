import React from "react";
import { Layout } from "../../components/Layout/Layout";
import Hero from "../../components/Hero/Hero";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Button } from "@material-ui/core";
import KpiTile from "./../../components/KpiTile/KpiTile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import TeaserTile from "../../components/TeaserTile/TeaserTile";

export default function Demo() {
  return (
    <Layout>
      <Hero size="lg" imageSrc="/assets/images/heroes/start.jpg">
        <h1>Example Headline</h1>
        <h2>Example Headline</h2>
        <h3>Example Headline</h3>
        <h4>Example Headline</h4>
        <h5>Example Headline</h5>
        <h6>Example Headline</h6>
        <p>Example paragraph</p>
        <div>
          <Button variant="contained" color="primary">
            Example Button
          </Button>
        </div>
        <div>
          <Button variant="outlined" color="primary">
            Example Button
          </Button>
        </div>
        <div>
          <Button variant="outlined" size="large" color="primary">
            Large Button
          </Button>
        </div>
        <div>
          <Button variant="outlined" size="medium" color="primary">
            Medium Button
          </Button>
        </div>
        <div>
          <Button variant="outlined" size="small" color="primary">
            Small Button
          </Button>
        </div>
      </Hero>
      <InnerLayout>
        <h1>Example Headline</h1>
        <h2>Example Headline</h2>
        <h3>Example Headline</h3>
        <h4>Example Headline</h4>
        <h5>Example Headline</h5>
        <h6>Example Headline</h6>
        <p>Example paragraph</p>
        <div>
          <Button variant="contained" color="primary">
            Example Button
          </Button>
        </div>
        <div>
          <Button variant="outlined" color="primary">
            Example Button
          </Button>
        </div>
        <div>
          <Button variant="outlined" size="large" color="primary">
            Large Button
          </Button>
        </div>
        <div>
          <Button variant="outlined" size="medium" color="primary">
            Medium Button
          </Button>
        </div>
        <div>
          <Button variant="outlined" size="small" color="primary">
            Small Button
          </Button>
        </div>
        <div>
          <h2>KpiTile:</h2>
          <KpiTile
            bgColor="#ddd"
            color="#93c01f"
            icon={<FontAwesomeIcon icon={faCloudDownloadAlt} />}
          >
            <h1>Example Headline</h1>
            <h2>Example Headline</h2>
            <h3>Example Headline</h3>
            <h4>Example Headline</h4>
            <h5>Example Headline</h5>
            <h6>Example Headline</h6>
            <p>Example paragraph</p>
          </KpiTile>
        </div>
        <div>
          <h2>TeaserTile</h2>
          <TeaserTile
            title="Egal was Sie machen,<br> sammeln Sie Cashback"
            subTitle="Im Café, Abendessen mit Freunden"
            buttonLabel="Alle Informationen"
            image="/assets/images/heroes/start.jpg"
            variant="yellow"
          >
            <h1>Example Headline</h1>
            <h2>Example Headline</h2>
            <h3>Example Headline</h3>
            <h4>Example Headline</h4>
            <h5>Example Headline</h5>
            <h6>Example Headline</h6>
            <p>Example paragraph</p>
          </TeaserTile>
        </div>
      </InnerLayout>
    </Layout>
  );
}
