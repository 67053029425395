import { API } from "../API";

export class VoucherManager {
  static async submitVoucher(
    code: string,
    fkn: string = "",
    comment: string = ""
  ): Promise<{ code: string | number; message: string }> {
    const data: any = {};
    if (fkn) data.fkn = fkn;
    if (comment) data.comment = comment;
    return await API.patch(`vouchers/vouchers/${code}`, data);
  }
}
