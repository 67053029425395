import "./Dashboard.scss";

import {
  faArrowRight,
  faCoins,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Select from "@material-ui/core/Select/Select";
import { uniq } from "lodash";
import queryString from "query-string";
import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import Backlink from "../../components/Backlink/Backlink";
import Box from "../../components/Box/Box";
import Hero from "../../components/Hero/Hero";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import KpiTile from "../../components/KpiTile/KpiTile";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ResponsiveTable from "../../components/ResponsiveTable/ResponsiveTable";
import { CashbackShoppingTransaction } from "../../interfaces/cashbackShoppingTransaction";
import { CashbackThirdPartyTransaction } from "../../interfaces/cashbackThirdPartyTransaction";
import { CashbackTravelTransaction } from "../../interfaces/cashbackTravelTransaction";
import { CashbackVoucherTransaction } from "../../interfaces/cashbackVoucherTransaction";
import { CashoutProfile } from "../../interfaces/cashoutProfile";
import Shop from "../../interfaces/shop";
import Helper from "../../services/helper";
import CashbackManager from "../../services/manager/CashbackManager";
import StateManager from "../../services/manager/StateManager";
import Auth from "../../services/auth";
import _ from "lodash";

interface Props {
  match: any;
  location: any;
}

interface State {
  cashoutProfile?: CashoutProfile;
  openComplaints?: number;
  loading: boolean;
  confirmedCashback: number;
  pendingCashback: number;
  favoriteShops: Shop[];
  transactionsFilter: [];
  type: string;
  page: number;
  status: string;
  yearFilter: string;
  years: number[];
  onlineTransactions: CashbackShoppingTransaction[];
  travelTransactions: CashbackTravelTransaction[];
  thirdPartyTransactions: CashbackThirdPartyTransaction[];
  voucherTransactions: CashbackVoucherTransaction[];
  generalTransactions: GeneralTransaction[];
}

interface GeneralTransaction {
  shopName: string;
  date: any;
  cashOutAmount: number;
  price: number;
  status: string;
}

export default class Transactions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const params = queryString.parse(this.props.location.search) as any;

    this.state = {
      openComplaints: 0,
      confirmedCashback: 0,
      pendingCashback: 0,
      favoriteShops: [],
      transactionsFilter: [],
      page: 1,
      type: params.type || "Alle Einkäufe",
      status: params.status || "Alle Status",
      years: [],
      onlineTransactions: [],
      travelTransactions: [],
      thirdPartyTransactions: [],
      voucherTransactions: [],
      generalTransactions: [],
      yearFilter: "Alle Jahre",
      loading: true,
    };

    this.handleChangeStatusFilter = this.handleChangeStatusFilter.bind(this);
    this.handleChangeTypeFilter = this.handleChangeTypeFilter.bind(this);
    this.handleChangeYearsFilter = this.handleChangeYearsFilter.bind(this);
  }

  handleChangeStatusFilter(event: any) {
    this.setState(
      {
        status: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeTypeFilter(event: any) {
    this.setState(
      {
        type: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeYearsFilter(event: any) {
    this.setState(
      {
        yearFilter: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  async load() {
    this.setState({ loading: true });

    const startDate =
      this.state.yearFilter !== "Alle Jahre"
        ? this.state.yearFilter + "-01-01"
        : "";
    const endDate =
      this.state.yearFilter !== "Alle Jahre"
        ? this.state.yearFilter + "-12-31"
        : "";

    const result = await Promise.all([
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getOnlineTransactions(startDate, endDate),
      CashbackManager.getTravelTransactions(startDate, endDate),
      CashbackManager.getThirdPartyTransactions(startDate, endDate),
      CashbackManager.getVoucherTransactions(startDate, endDate),
    ]);

    if (
      Auth.getTokenInfo().userInfo.isFriendAccount !== 1 &&
      Auth.getTokenInfo().userInfo.isFriendAccount !== "1"
    ) {
      const cashouts = await CashbackManager.getCashoutProfile();
      this.setState({
        cashoutProfile: cashouts,
      });
    }

    let normalizedOnlineTransactions: any = [];
    let normalizedTravelTransactions: any = [];
    let normalizedThirdPartyTransactions: any = [];
    let normalizedVoucherTransactions: any = [];

    if (result[1]) {
      normalizedOnlineTransactions = [
        ...result[1].items.map((item) => {
          return {
            shopName: item.shopName || "",
            date: item.date || "",
            cashOutAmount: item.cashOutAmount || 0,
            price: item.price || 0,
            status: item.status || "",
          } as GeneralTransaction;
        }),
      ];
    }

    if (result[2]) {
      normalizedTravelTransactions = [
        ...result[2].items.map((item) => {
          return {
            shopName: item.destination || "",
            date: item.departureDate || "",
            cashOutAmount: item.cashOutAmount || 0,
            price: item.price || 0,
            status: item.status || "",
          } as GeneralTransaction;
        }),
      ];
    }

    if (result[3]) {
      normalizedThirdPartyTransactions = [
        ...result[3].items.map((item) => {
          return {
            shopName: item.shopName || "",
            date: item.date || "",
            cashOutAmount: item.cashOutAmount || 0,
            price: item.price || 0,
            status: item.status || "",
          } as GeneralTransaction;
        }),
      ];
    }

    if (result[4]) {
      normalizedVoucherTransactions = [
        ...result[4].items.map((item) => {
          return {
            shopName: item.description || "",
            date: item.date || "",
            cashOutAmount: item.cashOutAmount || 0,
            price: item.price || 0,
            status: item.status || "",
          } as GeneralTransaction;
        }),
      ];
    }

    let generalTransactions = [
      ...normalizedOnlineTransactions,
      ...normalizedTravelTransactions,
      ...normalizedThirdPartyTransactions,
      ...normalizedVoucherTransactions,
    ];

    generalTransactions = _.orderBy(generalTransactions, ["date"], ["desc"]);

    if (
      normalizedOnlineTransactions &&
      normalizedOnlineTransactions.length > 0
    ) {
      normalizedOnlineTransactions = _.orderBy(
        normalizedOnlineTransactions,
        ["date"],
        ["desc"]
      );
    }

    if (
      normalizedTravelTransactions &&
      normalizedTravelTransactions.length > 0
    ) {
      normalizedTravelTransactions = _.orderBy(
        normalizedTravelTransactions,
        ["date"],
        ["desc"]
      );
    }

    if (
      normalizedThirdPartyTransactions &&
      normalizedThirdPartyTransactions.length > 0
    ) {
      normalizedThirdPartyTransactions = _.orderBy(
        normalizedThirdPartyTransactions,
        ["date"],
        ["desc"]
      );
    }

    if (
      normalizedVoucherTransactions &&
      normalizedVoucherTransactions.length > 0
    ) {
      normalizedVoucherTransactions = _.orderBy(
        normalizedVoucherTransactions,
        ["date"],
        ["desc"]
      );
    }

    let years = [
      ...generalTransactions.map((transaction) =>
        new Date(transaction.date).getFullYear()
      ),
    ];

    years = uniq(years);

    // Save filter state
    StateManager.setState("transactions", {
      type: this.state.type,
      page: this.state.page,
      status: this.state.status,
      yearFilter: this.state.yearFilter,
    });

    if (this.state.status !== "Alle Status") {
      normalizedOnlineTransactions = normalizedOnlineTransactions.filter(
        (transaction: any) =>
          this.state.status === "confirmed"
            ? transaction.status === "confirmed" ||
              transaction.status === "collected"
            : transaction.status === this.state.status
      );
      normalizedTravelTransactions = normalizedTravelTransactions.filter(
        (transaction: any) =>
          this.state.status === "confirmed"
            ? transaction.status === "confirmed" ||
              transaction.status === "collected"
            : transaction.status === this.state.status
      );
      normalizedThirdPartyTransactions = normalizedThirdPartyTransactions.filter(
        (transaction: any) =>
          this.state.status === "confirmed"
            ? transaction.status === "confirmed" ||
              transaction.status === "collected"
            : transaction.status === this.state.status
      );
      normalizedVoucherTransactions = normalizedVoucherTransactions.filter(
        (transaction: any) =>
          this.state.status === "confirmed"
            ? transaction.status === "confirmed" ||
              transaction.status === "collected"
            : transaction.status === this.state.status
      );
      generalTransactions = generalTransactions.filter((transaction: any) =>
        this.state.status === "confirmed"
          ? transaction.status === "confirmed" ||
            transaction.status === "collected"
          : transaction.status === this.state.status
      );
    }

    this.setState({
      openComplaints: result[0],
      onlineTransactions: normalizedOnlineTransactions,
      travelTransactions: normalizedTravelTransactions,
      thirdPartyTransactions: normalizedThirdPartyTransactions,
      voucherTransactions: normalizedVoucherTransactions,
      years,
      generalTransactions,
      loading: false,
    });
  }

  setStatusLabelAndContent = (value: any) => {
    let color;
    Helper.getLabelForCashbackStatus(value) === "Bestätigt"
      ? (color = "color-good")
      : (color = "color-bad");

    return (
      <div className={color}>{Helper.getLabelForCashbackStatus(value)}</div>
    );
  };

  async componentDidMount() {
    this.load();
  }

  render() {
    return (
      <Layout>
        <div className="Dashboard">
          <Hero
            size="md"
            imageSrc="/assets/images/heroes/dashboard.jpg"
            imagePositionY={-280}
          >
            <h1>Meine Cashbackübersicht</h1>
          </Hero>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Backlink
                label="zurück zur Übersicht"
                to="/transaktionen/uebersicht"
              />

              <Grid container spacing={4}>
                <Grid item xs={12} md={9}>
                  <Box>
                    {this.state.type === "Alle Einkäufe" && (
                      <h2>Meine Transaktionen</h2>
                    )}
                    {this.state.type === "cashback" && (
                      <h2>Meine Online Einkäufe</h2>
                    )}
                    {this.state.type === "travel" && (
                      <h2>Meine Reisebuchungen</h2>
                    )}
                    {this.state.type === "thirdparty" && (
                      <h2>Meine Pluskarte</h2>
                    )}
                    {this.state.type === "voucher" && <h2>Meine Gutscheine</h2>}

                    <p>
                      Hier finden Sie alle erfassten Einkäufe samt weiterer
                      Informationen wie Einkaufsdatum, Höhe des Cashbacks oder
                      den aktuellen Status Ihrer Bonuszahlungen. Haben Sie eine
                      Rückfrage zu einer Transaktion, so können Sie uns über{" "}
                      <a href="/info/kontakt">Kontakt</a> eine Nachricht
                      schreiben.
                    </p>

                    <div className="tableFilter">
                      <div>
                        <h4 style={{ marginBottom: 3, paddingLeft: 10 }}>
                          Kategorie
                        </h4>

                        <Select
                          className="rounded-select"
                          name="type"
                          value={this.state.type}
                          onChange={this.handleChangeTypeFilter}
                        >
                          <MenuItem value="Alle Einkäufe">
                            Alle Einkäufe
                          </MenuItem>
                          <MenuItem value="cashback">Online Cashback</MenuItem>
                          <MenuItem value="travel">Reiserückvergütung</MenuItem>
                          <MenuItem value="thirdparty">Pluskarte</MenuItem>
                          <MenuItem value="coupon">Gutscheine</MenuItem>
                        </Select>
                      </div>

                      <div>
                        <h4 style={{ marginBottom: 3, paddingLeft: 10 }}>
                          Status
                        </h4>

                        <Select
                          className="rounded-select"
                          name="status"
                          value={this.state.status}
                          onChange={this.handleChangeStatusFilter}
                        >
                          <MenuItem value="Alle Status">Alle Status</MenuItem>
                          <MenuItem value="open">Offen</MenuItem>
                          <MenuItem value="confirmed">Bestätigt</MenuItem>
                          <MenuItem value="canceled">Storniert</MenuItem>
                        </Select>
                      </div>

                      <div>
                        <h4 style={{ marginBottom: 3, paddingLeft: 10 }}>
                          Zeitraum
                        </h4>

                        <Select
                          className="rounded-select"
                          name="years"
                          value={this.state.yearFilter}
                          onChange={this.handleChangeYearsFilter}
                        >
                          <MenuItem value="Alle Jahre">Alle Jahre</MenuItem>

                          {this.state.years.map((year, key) => (
                            <MenuItem key={key} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>

                    {this.state.type === "Alle Einkäufe" && (
                      <ResponsiveTable
                        options={{
                          pagination: true,
                        }}
                        columns={[
                          {
                            name: "shopName",
                            label: "Name",
                            textAlign: "left",
                            sortable: true,
                            notCollapseOnMobile: true,
                          },
                          {
                            name: "date",
                            label: "Einkaufsdatum",
                            notCollapseOnMobile: true,
                            customBodyRender: (value: any) =>
                              Helper.formatDate(value),
                            sortable: true,
                            textAlign: "left",
                          },
                          {
                            name: "cashOutAmount",
                            label: "Cashback",
                            sortable: true,
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            textAlign: "right",
                          },
                          {
                            name: "price",
                            label: "Warenkorbwert",
                            sortable: true,
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            textAlign: "right",
                          },
                          {
                            name: "status",
                            label: "Status",
                            sortable: true,
                            notCollapseOnMobile: true,
                            customBodyRender: (value) => (
                              <div>{this.setStatusLabelAndContent(value)}</div>
                            ),
                            textAlign: "right",
                          },
                        ]}
                        data={
                          this.state.generalTransactions &&
                          this.state.generalTransactions
                            .map((transaction: GeneralTransaction) => {
                              return {
                                shopName: transaction.shopName,
                                date: transaction.date,
                                cashOutAmount: transaction.cashOutAmount,
                                price: transaction.price,
                                status: transaction.status,
                                action: "",
                              };
                            })
                            .filter((a) => !!a.shopName)
                        }
                      />
                    )}

                    {this.state.type === "cashback" && (
                      <ResponsiveTable
                        options={{
                          pagination: true,
                        }}
                        columns={[
                          {
                            name: "shopName",
                            label: "Shop",
                            sortable: true,
                            notCollapseOnMobile: true,
                            textAlign: "left",
                          },
                          {
                            name: "date",
                            label: "Einkaufsdatum",
                            sortable: true,
                            customBodyRender: (value: any) =>
                              Helper.formatDate(value),
                            notCollapseOnMobile: true,
                            textAlign: "left",
                          },
                          {
                            name: "cashOutAmount",
                            label: "Cashback",
                            sortable: true,
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            textAlign: "right",
                          },
                          {
                            name: "price",
                            label: "Warenkorbwert",
                            sortable: true,
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            textAlign: "right",
                          },
                          {
                            name: "status",
                            label: "Status",
                            sortable: true,
                            notCollapseOnMobile: true,
                            customBodyRender: (value) => (
                              <div>{this.setStatusLabelAndContent(value)}</div>
                            ),
                            textAlign: "right",
                          },
                        ]}
                        data={this.state.onlineTransactions
                          .map((transaction: GeneralTransaction) => {
                            return {
                              shopName: transaction.shopName,
                              date: transaction.date,
                              cashOutAmount: transaction.cashOutAmount,
                              price: transaction.price,
                              status: transaction.status,
                              action: "",
                            };
                          })
                          .filter((a) => !!a.shopName)}
                      />
                    )}

                    {this.state.type === "travel" && (
                      <ResponsiveTable
                        options={{
                          pagination: true,
                        }}
                        columns={[
                          {
                            name: "shopName",
                            label: "Reiseziel",
                            sortable: true,
                            notCollapseOnMobile: true,
                            textAlign: "left",
                          },
                          {
                            name: "date",
                            label: "Abreisedatum",
                            customBodyRender: (value: any) =>
                              Helper.formatDate(value),
                            sortable: true,
                            notCollapseOnMobile: true,
                            textAlign: "left",
                          },
                          {
                            name: "cashOutAmount",
                            label: "Cashback",
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            sortable: true,
                            textAlign: "right",
                          },
                          {
                            name: "price",
                            label: "Preis",
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            sortable: true,
                            textAlign: "right",
                          },
                          {
                            name: "status",
                            label: "Status",
                            notCollapseOnMobile: true,
                            customBodyRender: (value) => (
                              <div>{this.setStatusLabelAndContent(value)}</div>
                            ),
                            sortable: true,
                            textAlign: "right",
                          },
                        ]}
                        data={this.state.travelTransactions.map(
                          (transaction: GeneralTransaction) => {
                            return {
                              shopName: transaction.shopName,
                              date: transaction.date,
                              cashOutAmount: transaction.cashOutAmount,
                              price: transaction.price,
                              status: transaction.status,
                            };
                          }
                        )}
                      />
                    )}

                    {this.state.type === "thirdparty" && (
                      <ResponsiveTable
                        options={{
                          pagination: true,
                        }}
                        columns={[
                          {
                            name: "shopName",
                            label: "Shop",
                            sortable: true,
                            notCollapseOnMobile: true,
                            textAlign: "left",
                          },
                          {
                            name: "date",
                            label: "Einkaufsdatum",
                            customBodyRender: (value: any) =>
                              Helper.formatDate(value),
                            sortable: true,
                            notCollapseOnMobile: true,
                            textAlign: "left",
                          },
                          {
                            name: "cashOutAmount",
                            label: "Cashback",
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            sortable: true,
                            textAlign: "right",
                          },
                          {
                            name: "price",
                            label: "Preis",
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            sortable: true,
                            textAlign: "right",
                          },
                          {
                            name: "status",
                            label: "Status",
                            notCollapseOnMobile: true,
                            customBodyRender: (value) => (
                              <div>{this.setStatusLabelAndContent(value)}</div>
                            ),
                            sortable: true,
                            textAlign: "right",
                          },
                        ]}
                        data={this.state.thirdPartyTransactions.map(
                          (transaction: GeneralTransaction) => {
                            return {
                              shopName: transaction.shopName,
                              date: transaction.date,
                              cashOutAmount: transaction.cashOutAmount,
                              price: transaction.price,
                              status: transaction.status,
                            };
                          }
                        )}
                      />
                    )}

                    {this.state.type === "coupon" && (
                      <ResponsiveTable
                        options={{
                          pagination: true,
                        }}
                        columns={[
                          {
                            name: "shopName",
                            label: "Name",
                            textAlign: "left",
                            sortable: true,
                            notCollapseOnMobile: true,
                          },
                          {
                            name: "date",
                            label: "Einlösedatum",
                            customBodyRender: (value: any) =>
                              Helper.formatDate(value),
                            sortable: true,
                            notCollapseOnMobile: true,
                            textAlign: "left",
                          },
                          {
                            name: "cashOutAmount",
                            label: "Cashback",
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            sortable: true,
                            textAlign: "right",
                          },
                          {
                            name: "price",
                            label: "Preis",
                            customBodyRender: (value: number) =>
                              Helper.formatPrice(value),
                            sortable: true,
                            textAlign: "right",
                          },
                          {
                            name: "status",
                            label: "Status",
                            notCollapseOnMobile: true,
                            customBodyRender: (value) => (
                              <div>{this.setStatusLabelAndContent(value)}</div>
                            ),
                            sortable: true,
                            textAlign: "right",
                          },
                        ]}
                        data={this.state.voucherTransactions.map(
                          (transaction: CashbackVoucherTransaction) => {
                            return {
                              shopName: transaction.shopName,
                              date: transaction.date,
                              cashOutAmount: transaction.cashOutAmount,
                              price: transaction.price,
                              status: transaction.status,
                            };
                          }
                        )}
                      />
                    )}

                    <br />
                    <br />

                    {this.state.type === "Alle Einkäufe" ||
                      (this.state.type === "cashback" && (
                        <Fragment>
                          <h3>Sie finden eine Transaktion nicht?</h3>

                          <p>
                            Manchmal dauert es bis zu 24 Stunden bis Ihr Einkauf
                            in der Übersicht erscheint. Sollten Sie auch nach
                            dieser Zeitspanne immer noch einen Einkauf in der
                            Übersicht vermissen, nutzen Sie bitte das
                            Nachbuchungsformular, damit wir bei dem
                            entsprechenden Partnershop für Sie anfragen können.
                          </p>

                          <Link to="/nachbuchungsanfrage">
                            <Button
                              color="primary"
                              variant="contained"
                              size="medium"
                            >
                              Neue Nachbuchungsanfrage
                              <FontAwesomeIcon
                                icon={faArrowRight}
                                style={{
                                  marginLeft: 10,
                                }}
                              />
                            </Button>
                          </Link>
                        </Fragment>
                      ))}
                  </Box>

                  <Box>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <p style={{ fontSize: "1rem", lineHeight: "160%" }}>
                          <b>Hinweis:</b> Wir haben den Status "Ausgezahlt"
                          geändert. Zukünftig wird dieser nicht mehr im Portal
                          geführt. Die Transaktionen bleiben, auch wenn Sie
                          ausgezahlt wurden, auf dem Status "Bestätigt" stehen.
                        </p>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <h4 style={{ color: "#93c01f" }}>Offen:</h4>
                        <p style={{ fontSize: "1rem", lineHeight: "160%" }}>
                          Ihr Einkauf wurde erfasst und muss noch durch den
                          Partner bestätigt werden.
                        </p>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <h4 style={{ color: "#93c01f" }}>Bestätigt:</h4>
                        <p style={{ fontSize: "1rem", lineHeight: "160%" }}>
                          Ihr Einkauf wurde vom Partner bestätigt. Nach der
                          Zahlung des Partners an uns wird Ihnen das Cashback
                          schnellstmöglich ausgezahlt.
                        </p>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <h4 style={{ color: "#93c01f" }}>Storniert:</h4>
                        <p style={{ fontSize: "1rem", lineHeight: "160%" }}>
                          Das Cashback wurde leider vom Partner abgelehnt und
                          Sie erhalten keine Zahlung.
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                  {Auth.getTokenInfo().userInfo.isFriendAccount !== 1 &&
                    Auth.getTokenInfo().userInfo.isFriendAccount !== "1" && (
                      <KpiTile
                        color="#fff"
                        bgColor="#009FD6"
                        title="Meine Auszahlungen"
                        icon={<FontAwesomeIcon icon={faCoins} />}
                        link="/auszahlungen"
                      >
                        <h3>
                          {this.state.cashoutProfile &&
                            Helper.formatPrice(
                              this.state.cashoutProfile.paidOutCash +
                                this.state.cashoutProfile.externalPaidOutCash ||
                                0
                            )}
                        </h3>
                      </KpiTile>
                    )}
                  <KpiTile
                    color="#fff"
                    bgColor="#FCC200"
                    title="Meine Anfragen"
                    icon={<FontAwesomeIcon icon={faExclamation} />}
                    link="/reklamationen"
                  >
                    <h3>
                      {this.state.openComplaints || 0} offene
                      Nachbuchungsanfragen
                    </h3>
                  </KpiTile>
                </Grid>
              </Grid>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
