import SweetAlert from 'sweetalert2';

export class Alert {
  static error(msg: string, text?: string) {
    SweetAlert.fire({
      title: msg,
      text: text || '',
    });
  }

  static success(msg: string, text?: string) {
    SweetAlert.fire({
      title: msg,
      text: text || '',
    });
  }

  static warning(msg: string, text?: string) {
    SweetAlert.fire({
      title: msg,
      text: text || '',
    });
  }

  static once(
    msg: string,
    text: string,
    sessionStoragekey: string,
    sessionStorageValue: string
  ) {
    SweetAlert.fire({
      title: msg,
      text: text,
      showCloseButton: true,
    }).then(() =>
      sessionStorage.setItem(sessionStoragekey, sessionStorageValue)
    );
  }

  static async confirm(
    msg: string,
    text: string,
    confirmButtonText: string,
    cancelButtonText: string
  ) {
    const result = await SweetAlert.fire({
      title: msg,
      text: text,
      showCloseButton: true,
      showCancelButton: true,
    });
    return result.value;
  }
}
