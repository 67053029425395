import './Footer.scss';

import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Footer(props: { items: any[] }) {
  return (
    <div className="footer">
      <div className="logo">
        <NavLink to={'/'}>
          <img src="/plusrente.svg" alt="Logo der plusrente" />
        </NavLink>
      </div>
      <nav>
        {props.items.map((item, index) => {
          if (item.link.indexOf('http') === -1) {
            return (
              <NavLink
                key={'footer-nav-' + index}
                activeClassName="active"
                to={item.link}
              >
                {item.title}
              </NavLink>
            );
          }

          if (item.link.indexOf('http') === 0) {
            return (
              <a
                target="_blank"
                rel="noopener noreferrer"
                key={'footer-nav-' + index}
                href={item.link}
              >
                {item.title}
              </a>
            );
          }

          return null;
        })}
      </nav>
    </div>
  );
}
