import React from 'react';
import { Layout } from '../../components/Layout/Layout';
import Hero from '../../components/Hero/Hero';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Grid } from '@material-ui/core';
import './Special.scss';
import SpecialTile from '../../components/SpecialTile/SpecialTile';
import CashbackManager from '../../services/manager/CashbackManager';
import { default as SpecialInterface } from '../../interfaces/special';
import helper from '../../services/helper';
import { Loading } from '../../components/Loading';

interface Props {
  history: any;
}

interface State {
  specials: SpecialInterface[];
  loading: boolean;
}

export default class Special extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      specials: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const specials = await CashbackManager.findSpecials();
    this.setState({
      specials,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <div className="Special">
          <Hero
            size="lg"
            imageSrc="/assets/images/heroes/specials.jpg"
            imagePositionY={-330}
          >
            <h1>Aktuelle Specials & Highlights</h1>
          </Hero>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Grid container spacing={2}>
                {this.state.specials.map((special: SpecialInterface, key) => (
                  <Grid item xs={12} md={6} key={key}>
                    <SpecialTile
                      image={special.image}
                      title={special.title}
                      subTitle={special.subTitle}
                      info={
                        special.end
                          ? `Nur bis zum ${helper.formatDate(special.end)}`
                          : undefined
                      }
                      buttonLabel="Zum Shop"
                      buttonLink={special.link}
                      priceActual={special.priceActual}
                      priceBefore={special.priceBefore}
                      specialText={special.text}
                      shopId={String(special.id)}
                      history={this.props.history}
                    ></SpecialTile>
                  </Grid>
                ))}
              </Grid>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
