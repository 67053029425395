import './Dashboard.scss';

import { faCoins, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import React from 'react';

import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import Hero from '../../components/Hero/Hero';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import KpiTile from '../../components/KpiTile/KpiTile';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import Shop from '../../interfaces/shop';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import Auth from '../../services/auth';

interface Props {
  match: any;
  location: any;
}

interface State {
  cashoutProfile?: CashoutProfile;
  openComplaints: number;
  onlineCashbackOpen: number;
  onlineCashbackConfirmed: number;
  onlineCashbackCancelled: number;
  travelCashbackOpen: number;
  travelCashbackConfirmed: number;
  travelCashbackCancelled: number;
  thirdPartyCashbackOpen: number;
  thirdPartyCashbackConfirmed: number;
  thirdPartyCashbackCancelled: number;
  voucherCashbackOpen: number;
  voucherCashbackConfirmed: number;
  voucherCashbackCancelled: number;
  loading: boolean;
  favoriteShops: Shop[];
  numberOfBasketItems: number;
}

interface GeneralTransaction {
  shopName: string;
  date: any;
  cashOutAmount: number;
  price: number;
  status: string;
}

export default class TransactionsOverview extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openComplaints: 0,
      onlineCashbackOpen: 0,
      onlineCashbackConfirmed: 0,
      onlineCashbackCancelled: 0,
      travelCashbackOpen: 0,
      travelCashbackConfirmed: 0,
      travelCashbackCancelled: 0,
      thirdPartyCashbackOpen: 0,
      thirdPartyCashbackConfirmed: 0,
      thirdPartyCashbackCancelled: 0,
      voucherCashbackOpen: 0,
      voucherCashbackConfirmed: 0,
      voucherCashbackCancelled: 0,
      numberOfBasketItems: 0,
      favoriteShops: [],
      loading: true,
    };
  }

  async componentDidMount() {
    const result = await Promise.all([
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getOnlineTransactions(),
      CashbackManager.getTravelTransactions(),
      CashbackManager.getThirdPartyTransactions(),
      CashbackManager.getVoucherTransactions(),
    ]);

    const [
      sumsOnline,
      sumsTravel,
      sumsThirdParty,
      sumsVoucher,
    ] = await Promise.all([
      CashbackManager.getCashbackAmount(result[1].items),
      CashbackManager.getCashbackAmount(result[2].items),
      CashbackManager.getCashbackAmount(result[3].items),
      CashbackManager.getCashbackAmount(result[4].items),
    ]);

    if (
      Auth.getTokenInfo().userInfo.isFriendAccount !== 1 &&
      Auth.getTokenInfo().userInfo.isFriendAccount !== '1'
    ) {
      const cashouts = await CashbackManager.getCashoutProfile();
      this.setState({
        cashoutProfile: cashouts,
      });
    }

    this.setState({
      openComplaints: result[0],
      onlineCashbackOpen: sumsOnline.sumOpen,
      onlineCashbackConfirmed: sumsOnline.sumConfirmed,
      onlineCashbackCancelled: sumsOnline.sumCancelled,
      travelCashbackOpen: sumsTravel.sumOpen,
      travelCashbackConfirmed: sumsTravel.sumConfirmed,
      travelCashbackCancelled: sumsTravel.sumCancelled,
      thirdPartyCashbackOpen: sumsThirdParty.sumOpen,
      thirdPartyCashbackConfirmed: sumsThirdParty.sumConfirmed,
      thirdPartyCashbackCancelled: sumsThirdParty.sumCancelled,
      voucherCashbackOpen: sumsVoucher.sumOpen,
      voucherCashbackConfirmed: sumsVoucher.sumConfirmed,
      voucherCashbackCancelled: sumsVoucher.sumCancelled,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <div className="Dashboard">
          <Hero
            size="md"
            imageSrc="/assets/images/heroes/dashboard.jpg"
            imagePositionY={-280}
          >
            <h1>Meine Cashbackübersicht</h1>
          </Hero>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Backlink label="zurück zur Übersicht" to="/profil" />

              <Grid container spacing={4}>
                <Grid item xs={12} md={9}>
                  <Box>
                    <h2>Meine Transaktionen</h2>

                    <p>
                      Hier finden Sie alle erfassten Einkäufe samt weiterer
                      Informationen wie Einkaufsdatum, Höhe des Cashbacks oder
                      den aktuellen Status Ihrer Bonuszahlungen. Haben Sie eine
                      Rückfrage zu einer Transaktion, so können Sie uns über{' '}
                      <a href="/info/kontakt">Kontakt</a> eine Nachricht
                      schreiben.
                    </p>

                    <ResponsiveTable
                      lastRowIsSumRow
                      columns={[
                        {
                          name: 'category',
                          label: '',
                          notCollapseOnMobile: true,
                          textAlign: 'left',
                        },
                        { name: 'open', label: 'Offen', textAlign: 'right' },
                        {
                          name: 'confirmed',
                          label: 'Bestätigt',
                          textAlign: 'right',
                        },
                        {
                          name: 'canceled',
                          label: 'Storniert',
                          textAlign: 'right',
                        },
                        {
                          name: 'action',
                          label: 'Aktion',
                          customBodyRender: (value, entity) =>
                            value && (
                              <a href={'/transaktionen?type=' + value}>
                                mehr Infos
                              </a>
                            ),
                          textAlign: 'left',
                        },
                      ]}
                      data={[
                        {
                          category: 'Online Cashback',
                          open: Helper.formatPrice(
                            this.state.onlineCashbackOpen
                          ),
                          confirmed: Helper.formatPrice(
                            this.state.onlineCashbackConfirmed
                          ),
                          canceled: Helper.formatPrice(
                            this.state.onlineCashbackCancelled
                          ),
                          action: 'cashback',
                        },
                        {
                          category: 'Reiserückvergütung',
                          open: Helper.formatPrice(
                            this.state.travelCashbackOpen
                          ),
                          confirmed: Helper.formatPrice(
                            this.state.travelCashbackConfirmed
                          ),
                          canceled: Helper.formatPrice(
                            this.state.travelCashbackCancelled
                          ),
                          action: 'travel',
                        },
                        {
                          category: 'Pluskarte',
                          open: Helper.formatPrice(
                            this.state.thirdPartyCashbackOpen
                          ),
                          confirmed: Helper.formatPrice(
                            this.state.thirdPartyCashbackConfirmed
                          ),
                          canceled: Helper.formatPrice(
                            this.state.thirdPartyCashbackCancelled
                          ),
                          action: 'thirdparty',
                        },
                        {
                          category: 'Gutscheine',
                          open: Helper.formatPrice(
                            this.state.voucherCashbackOpen
                          ),
                          confirmed: Helper.formatPrice(
                            this.state.voucherCashbackConfirmed
                          ),
                          canceled: Helper.formatPrice(
                            this.state.voucherCashbackCancelled
                          ),
                          action: 'coupon',
                        },
                        {
                          category: '',
                          open: Helper.formatPrice(
                            this.state.onlineCashbackOpen +
                              this.state.travelCashbackOpen +
                              this.state.thirdPartyCashbackOpen +
                              this.state.voucherCashbackOpen
                          ),
                          confirmed: Helper.formatPrice(
                            this.state.onlineCashbackConfirmed +
                              this.state.travelCashbackConfirmed +
                              this.state.thirdPartyCashbackConfirmed +
                              this.state.voucherCashbackConfirmed
                          ),
                          canceled: Helper.formatPrice(
                            this.state.onlineCashbackCancelled +
                              this.state.travelCashbackCancelled +
                              this.state.thirdPartyCashbackCancelled +
                              this.state.voucherCashbackCancelled
                          ),
                          action: '',
                        },
                      ]}
                    />
                  </Box>

                  <Box>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <p
                          style={{
                            fontSize: '1rem',
                            lineHeight: '160%',
                          }}
                        >
                          <b>Hinweis:</b> Wir haben den Status "Ausgezahlt"
                          geändert. Zukünftig wird dieser nicht mehr im Portal
                          geführt. Die Transaktionen bleiben, auch wenn Sie
                          ausgezahlt wurden, auf dem Status "Bestätigt" stehen.
                        </p>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <h4 style={{ color: '#93c01f' }}>Offen:</h4>
                        <p
                          style={{
                            fontSize: '1rem',
                            lineHeight: '160%',
                          }}
                        >
                          Ihr Einkauf wurde erfasst und muss noch durch den
                          Partner bestätigt werden.
                        </p>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <h4 style={{ color: '#93c01f' }}>Bestätigt:</h4>
                        <p
                          style={{
                            fontSize: '1rem',
                            lineHeight: '160%',
                          }}
                        >
                          Ihr Einkauf wurde vom Partner bestätigt. Nach der
                          Zahlung des Partners an uns wird Ihnen das Cashback
                          schnellstmöglich ausgezahlt.
                        </p>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <h4 style={{ color: '#93c01f' }}>Storniert:</h4>
                        <p
                          style={{
                            fontSize: '1rem',
                            lineHeight: '160%',
                          }}
                        >
                          Das Cashback wurde leider vom Partner abgelehnt und
                          Sie erhalten keine Zahlung.
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                <Grid item xs={12} md={3}>
                  {Auth.getTokenInfo().userInfo.isFriendAccount !== 1 &&
                    Auth.getTokenInfo().userInfo.isFriendAccount !== '1' && (
                      <KpiTile
                        color="#fff"
                        bgColor="#009FD6"
                        title="Meine Auszahlungen"
                        icon={<FontAwesomeIcon icon={faCoins} />}
                        link="/auszahlungen"
                      >
                        <h3>
                          {this.state.cashoutProfile &&
                            Helper.formatPrice(
                              this.state.cashoutProfile.paidOutCash +
                                this.state.cashoutProfile.externalPaidOutCash ||
                                0
                            )}
                        </h3>
                      </KpiTile>
                    )}
                  <KpiTile
                    color="#fff"
                    bgColor="#FCC200"
                    title="Meine Anfragen"
                    icon={<FontAwesomeIcon icon={faExclamation} />}
                    link="/reklamationen"
                  >
                    <h3>
                      {this.state.openComplaints || 0} offene
                      Nachbuchungsanfragen
                    </h3>
                  </KpiTile>
                </Grid>
              </Grid>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
