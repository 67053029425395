import './Notification.scss';

import {
  faCheck,
  faExclamationCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface Props {
  type: 'success' | 'warning' | 'error';
  title?: string;
  text?: string;
}

export default function Notification(props: Props) {
  const ICONS_FOR_TYPE = {
    success: faCheck,
    warning: faExclamationTriangle,
    error: faExclamationCircle,
  };

  return (
    <div className={`Notification ${props.type}`}>
      <span className="icon">
        <FontAwesomeIcon icon={ICONS_FOR_TYPE[props.type]} />
      </span>

      <span>
        {props.title && <span className="title">{props.title}</span>}

        {props.text && <span className="text">{props.text}</span>}
      </span>
    </div>
  );
}
