export default class Config {
  static getEnv(name: string) {
    const win = window as any;
    if (win.env && win.env[name] !== undefined) {
      return win.env[name];
    } else {
      return process.env[name];
    }
  }

  static getAPIURL(): string {
    return Config.getEnv('REACT_APP_API_URL');
  }

  static getTravelCategoryId(): any {
    return this.getEnv('REACT_APP_TRAVEL_CATEGORY_ID');
  }

  static getPublicTransportKey(): string {
    return Config.getEnv('REACT_APP_PUBLIC_TRANSPORT_KEY');
  }

  static isTestEnv(): boolean {
    console.log('window?.env :>> ', (window as any)?.env);
    console.log('process.env :>> ', process.env);
    return (
      // process.env?.REACT_APP_API_URL?.includes('backend') ||
      (window as any)?.env?.REACT_APP_API_URL?.includes('backend')
    );
  }
}
