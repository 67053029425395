import { ClickoutHintData } from '../interfaces/ClickoutHintData';
import { CashbackStatus } from '../interfaces/cashbackStatus';
export default class Helper {
  static formatNumber(value: number, decimals = 0) {
    value = parseFloat(value as any);
    return value
      .toFixed(decimals)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  static formatDate(date: any) {
    if (typeof date !== 'object') {
      if (date.indexOf('T') === -1) {
        date = date.replace(/-/g, '/');
      }
      date = new Date(date);
    }
    const prefix = (c: number) => (c < 10 ? `0${c}` : c);
    return `${prefix(date.getDate())}.${prefix(
      date.getMonth() + 1
    )}.${date.getFullYear()}`;
  }

  static dateToString(date?: Date | null) {
    if (!date) return undefined;
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  static formatPrice(value: number, currency?: string) {
    const price = Helper.formatNumber(value, 2);

    if (!currency || ['EUR', 'eur', 'euro', 'Euro'].indexOf(currency) > -1)
      currency = '€';

    return `${price} ${currency}`;
  }

  static getLabelForCashbackStatus = (status: CashbackStatus): string => {
    const CASHBACK_STATUS_MAP: { [key: string]: string } = {
      open: 'Offen',
      confirmed: 'Offen',
      canceled: 'Storniert',
      cancelled: 'Storniert',
      collected: 'Bestätigt',
    };

    return CASHBACK_STATUS_MAP[status] || 'Status unbekannt';
  };

  static handleClickoutHint(data: ClickoutHintData, e: any, history: any) {
    e.preventDefault();

    history.push({
      pathname: '/clickout',
      state: data,
    });
  }
}
