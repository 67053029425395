import { Button, Checkbox, Grid, TextField } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import Backlink from '../../components/Backlink/Backlink';
import Hero from '../../components/Hero/Hero';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ServiceTile from '../../components/ServiceTile/ServiceTile';
import { User } from '../../interfaces/user';
import helper from '../../services/helper';
import UserManager from '../../services/manager/UserManager';
import Box from './../../components/Box/Box';
import { Alert } from '../../services/alert';
import CashbackManager from '../../services/manager/CashbackManager';

interface State {
  loading: boolean;
  user?: User;
  contractId: string;
  contractIdError?: boolean;
}

interface Props {}

export default class Settings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      contractId: '',
    };

    this.handleChangeContractId = this.handleChangeContractId.bind(this);
    this.handleSubmitContractId = this.handleSubmitContractId.bind(this);
  }

  async componentDidMount() {
    const user = await UserManager.me();

    this.setState({
      loading: false,
      user,
    });
  }

  handleChangeContractId(e: any) {
    this.setState({
      contractId: e.target.value,
    });
  }

  async handleSubmitContractId() {
    if (this.state.contractId.trim() === '') return;

    await this.setState({
      loading: true,
    });

    try {
      await UserManager.updateContractId(this.state.contractId.trim());
    } catch (e) {
      Alert.error(
        'Fehler',
        'Leider konnte die von Ihnen angegebene Versicherungsnr. / Antragsnr. nicht gefunden werden. Bitte überprüfen Sie Ihre Angaben oder wenden Sie sich an den Support.'
      );
      // error updating contract ID
      return this.setState({
        contractIdError: true,
        loading: false,
      });
    }

    const user = await UserManager.me();

    if (!user.userInfo.prospectiveCustomer) {
      await CashbackManager.setAutoCashoutSubscribe({ status: true });
    }

    Alert.success(
      'Daten geändert',
      'Sie haben Ihre Versicherungsnr. / Antragsnr. erfolgreich hinterlegt.'
    );

    this.setState({
      loading: false,
      user,
    });
  }

  render() {
    const interests = JSON.parse(
      this.state.user && this.state.user.userInfo.interests
        ? this.state.user.userInfo.interests
        : '{}'
    );

    return (
      <Layout>
        <Hero
          size="md"
          imageSrc="/assets/images/heroes/dashboard.jpg"
          imagePositionY={-280}
        >
          <h1>Meine Einstellungen</h1>
        </Hero>

        {this.state.loading && (
          <InnerLayout>
            <Loading />
          </InnerLayout>
        )}

        {!this.state.loading && this.state.user && (
          <InnerLayout>
            <Backlink label="zurück zur Übersicht" to="/profil" />

            <Grid container spacing={2}>
              <Grid item xs={12} md={9}>
                <Box>
                  <h2>Persönliche Angaben</h2>

                  <Grid container spacing={2}>
                    {[
                      {
                        label: 'Vorname',
                        value: this.state.user.userInfo.firstName,
                      },
                      {
                        label: 'Nachname',
                        value: this.state.user.userInfo.lastName,
                      },
                      {
                        label: 'Geburtsdatum',
                        value:
                          typeof this.state.user.userInfo.dayOfBirth !==
                          'undefined'
                            ? helper.formatDate(
                                this.state.user.userInfo.dayOfBirth
                              )
                            : '',
                      },
                    ]
                      .concat(
                        this.state.user.userInfo.contractId &&
                          this.state.user.userInfo.contractId.trim() !== ''
                          ? [
                              {
                                label: this.state.user?.userInfo.isFriendAccount
                                  ? 'Freundschaftsvertrag'
                                  : 'Versicherungsnr. / Antragsnr.',
                                value: this.state.user.userInfo
                                  ._aditoCustomerId,
                              },
                            ]
                          : []
                      )

                      .map(({ label, value }, key) => (
                        <Grid item md={6} xs={12} key={key}>
                          <Grid container>
                            <Grid item xs={5}>
                              <span
                                style={{
                                  color: '#333333',
                                }}
                              >
                                {label}:
                              </span>
                            </Grid>
                            <Grid item xs={7}>
                              {value}
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </Box>

                <Box
                  style={{
                    marginTop: '2em',
                  }}
                >
                  <a
                    style={{
                      float: 'right',
                    }}
                    href="https://www.diebayerische.de/sso/#/mein-account"
                  >
                    Änderungen vornehmen
                  </a>

                  <h2>Ihre Zugangsdaten</h2>

                  <Grid container spacing={2}>
                    {[
                      {
                        label: 'E-Mail-Adresse',
                        value: this.state.user.email,
                      },
                      {
                        label: 'Passwort',
                        value: '***********',
                      },
                    ].map(({ label, value }, key) => (
                      <Grid item md={6} xs={12} key={key}>
                        <Grid container>
                          <Grid item xs={5}>
                            <span
                              style={{
                                color: '#333333',
                              }}
                            >
                              {label}:
                            </span>
                          </Grid>
                          <Grid item xs={7}>
                            {value}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <Box
                  style={{
                    marginTop: '2em',
                  }}
                >
                  <Link
                    style={{
                      float: 'right',
                    }}
                    to="/ihre-interessen"
                  >
                    Änderungen vornehmen
                  </Link>

                  <h2
                    // onClick={() => console.log('process.env :>> ', process.env)}
                    style={{ display: 'inline' }}
                  >
                    Ihre Interessen
                  </h2>

                  <Grid container spacing={0}>
                    {[
                      { label: 'Immobilien', value: interests.realEstate },
                      { label: 'Familie', value: interests.family },
                      { label: 'Sport', value: interests.sports },
                      { label: 'Kunst', value: interests.art },
                      { label: 'Kultur', value: interests.culture },
                      { label: 'Autos/Motorräder', value: interests.cars },
                      { label: 'Musik', value: interests.music },
                      { label: 'Mobilität', value: interests.mobility },
                      { label: 'Energie sparen', value: interests.energy },
                      { label: 'Reisen', value: interests.travel },
                      {
                        label: 'Versicherungsprodukte',
                        value: interests.insuranceProducts,
                      },
                    ].map(({ label, value }, key) => (
                      <Grid item md={6} xs={12} key={key}>
                        <span
                          style={{
                            color: '#333333',
                          }}
                        >
                          <Checkbox
                            disabled
                            checked={value === true}
                            color="default"
                            value="default"
                          />
                          {label}
                        </span>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={3}>
                <ServiceTile />
              </Grid>
            </Grid>
          </InnerLayout>
        )}
      </Layout>
    );
  }
}
