import './Layout.scss';

import {
  createMuiTheme,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  SnackbarContent,
  ThemeProvider,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { footerItems, navigationItems } from '../../routes';
import { Alert } from '../../services/alert';
import Auth from '../../services/auth';
import UserManager from '../../services/manager/UserManager';
import Navigation from '../Navigation/Navigation';
import Footer from './../Footer/Footer';
import Head from './Head';

interface Props {
  title?: string;
}

interface State {
  isDrawerOpen: boolean;
  loading: boolean;
  loginError?: any;

  username: string;
  password: string;
  isLoginOpen: boolean;
  location?: any;
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#93C01F',
      main: '#93C01F',
      dark: '#93C01F',
      contrastText: '#fff',
    },
    secondary: {
      main: '#009FD6',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1140,
      lg: 1280,
      xl: 1920,
    },
  },
});

export class Layout extends React.Component<Props, State> {
  public navigation = navigationItems;
  public footer = footerItems;

  constructor(props: any) {
    super(props);

    this.state = {
      isDrawerOpen: false,
      loading: false,

      username: '',
      password: '',

      isLoginOpen: false,
      // location: useLocation(),
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleToggleLogin = this.handleToggleLogin.bind(this);
    this.handleResendRegisterMail = this.handleResendRegisterMail.bind(this);
  }

  async componentDidMount() {
    // TODO: set title
  }

  componentDidCatch(error: Error | null, info: object) {
    Alert.error(
      'Ein Fehler ist aufgetreten',
      'Bitte versuchen Sie es später noch einmal. Wir arbeiten mit Hochdruck an der Behebung des Fehlers.'
    );
  }

  handleToggleLogin() {
    this.setState({
      isLoginOpen: !this.state.isLoginOpen,
    });
  }

  toggleDrawer() {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  }

  closeDrawer() {
    this.setState({ isDrawerOpen: false });
  }

  handleChangeUsername(value: string) {
    this.setState({
      username: value,
    });
  }

  handleChangePassword(value: string) {
    this.setState({
      password: value,
    });
  }

  async handleResendRegisterMail() {
    await UserManager.resendVerification(this.state.username);

    this.setState({
      loginError:
        'Wir haben Ihnen soeben eine neue E-Mail zur Bestätigung Ihrer E-Mail Adresse zugesendet. Bitte prüfen Sie auch den SPAM-Ordner Ihres E-Mail Programms.',
    });
  }

  async onLogin() {
    await this.setState({
      loading: true,
      loginError: undefined,
    });

    const loginResult = await Auth.login(
      this.state.username,
      this.state.password
    );

    if (loginResult === true) {
      this.setState({
        loginError: undefined,
        loading: false,
      });
    } else {
      let errorMsg: any =
        'Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.';
      if (loginResult === 'notVerified')
        errorMsg = (
          <p>
            Bitte bestätigen Sie Ihre E-Mail Adresse über den per E-Mail an Sie
            versendeten Link, um sich anzumelden.
            <br />
            Sie haben auch nach einigen Minuten keine E-Mail erhalten?{' '}
            <a onClick={this.handleResendRegisterMail}>
              Dann können Sie hier eine weitere E-Mail zur Bestätigung anfordern
            </a>
            .
          </p>
        );

      this.setState({
        loginError: errorMsg,
        loading: false,
      });
    }
  }

  render() {
    if (!this.state) return;

    const footer = this.footer;

    return (
      <ThemeProvider theme={theme}>
        <Head>
          {Auth.isLoggedIn() && <meta name="fkn" content={Auth.getFKN()} />}
        </Head>

        <div className="layout">
          <Drawer
            onBackdropClick={this.closeDrawer}
            anchor="right"
            open={this.state.isDrawerOpen}
          >
            <List>
              {this.navigation.map((item, index) => (
                <ListItem key={'nav-item-' + index} button>
                  <NavLink
                    style={{ width: '100%', margin: '6px 0' }}
                    key={'nav-' + index}
                    activeClassName="active"
                    to={item.link}
                  >
                    {item.title}
                  </NavLink>
                </ListItem>
              ))}
              <Divider />
              {footer.map((item, index) => (
                <ListItem key={'footer-item-' + index} button>
                  <ListItemText
                    primary={
                      <div>
                        {item.link.indexOf('http') === -1 && (
                          <NavLink
                            key={'nav-' + index}
                            activeClassName="active"
                            to={item.link}
                          >
                            {item.title}
                          </NavLink>
                        )}
                        {item.link.indexOf('http') === 0 && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            key={'nav-' + index}
                            href={item.link}
                          >
                            {item.title}
                          </a>
                        )}
                      </div>
                    }
                  />
                </ListItem>
              ))}
              <Divider />
              {Auth.isLoggedIn() && (
                <ListItem button>
                  <NavLink
                    style={{ width: '100%', margin: '6px 0' }}
                    activeClassName="active"
                    to={'/abmelden'}
                  >
                    Abmelden
                  </NavLink>
                </ListItem>
              )}
            </List>
          </Drawer>
          <Navigation
            items={this.navigation}
            toggleDrawer={this.toggleDrawer}
            onLogin={this.onLogin}
            onChangeUsername={this.handleChangeUsername}
            onChangePassword={this.handleChangePassword}
            loggedIn={Auth.isLoggedIn()}
            loginError={this.state.loginError}
            username={this.state.username}
            password={this.state.password}
            isLoginOpen={this.state.isLoginOpen}
            onToggleLogin={this.handleToggleLogin}
          />
          <div className="content-wrapper">{this.props.children}</div>
          <Footer items={this.footer} />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.loginError !== undefined}
            autoHideDuration={6000}
            onClose={() => this.setState({ loginError: undefined })}
            style={{
              backgroundColor: '#D32F2F',
            }}
          >
            <SnackbarContent
              aria-describedby="login-snackbar"
              message={<span id="login-snackbar">{this.state.loginError}</span>}
              action={[
                <IconButton
                  key="close"
                  aria-label="close"
                  color="inherit"
                  onClick={() => this.setState({ loginError: undefined })}
                >
                  <CloseIcon />
                </IconButton>,
              ]}
              style={{
                backgroundColor: '#D32F2F',
              }}
            />
          </Snackbar>
        </div>
      </ThemeProvider>
    );
  }
}
