import './Location.scss';

import { Button, Paper, Slider } from '@material-ui/core';
import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import GoogleMap from '../GoogleMap/GoogleMap';

interface Props {
  location?: { lat: number; lng: number };
  distance?: number;
  onChange: (location: any, distance: number) => void;
}

interface State {
  query: string;
  location?: { lat: number; lng: number };
  distance: number;
}

export default class Location extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      query: "",
      distance: this.props.distance ? this.props.distance : 20,
      location: this.props.location,
    };

    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendChange = this.sendChange.bind(this);
  }

  sendChange() {
    this.props.onChange(this.state.location, this.state.distance);
  }

  handleSubmit(query: any) {
    this.setState(
      {
        query,
      },
      () => {
        geocodeByAddress(this.state.query)
          .then((results: any) => getLatLng(results[0]))
          .then((latLng: any) =>
            this.setState({
              location: latLng,
            })
          )
          .catch((error: any) => console.error("Error", error));
      }
    );
  }

  handleChangeQuery(query: any) {
    this.setState({
      query,
    });
  }

  render() {
    return (
      <div className="Location">
        <div
          style={{
            paddingBottom: 10,
          }}
        >
          <h2
            style={{
              textAlign: "left",
            }}
          >
            Ihre Adresse
          </h2>

          <PlacesAutocomplete
            value={this.state.query}
            onChange={this.handleChangeQuery}
            onSelect={this.handleSubmit}
            searchOptions={{ componentRestrictions: { country: 'de' } }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div
                style={{
                  width: "100%",
                }}
              >
                <Paper
                  elevation={0}
                  className="InputWithButton"
                  style={
                    suggestions.length > 0
                      ? {
                          borderRadius: 21,
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                        }
                      : {}
                  }
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                    }}
                  >
                    <div className="MuiInputBase-root">
                      <input
                        {...getInputProps({
                          placeholder: "Suche nach Ort oder Adresse...",
                          className: "location-search-input MuiInputBase-input",
                        })}
                      />
                    </div>
                  </div>
                </Paper>
                {suggestions.length > 0 && (
                  <div
                    style={{
                      textAlign: "left",
                      padding: 15,
                      backgroundColor: "#f6f6f6",
                      borderBottomLeftRadius: 35,
                      borderBottomRightRadius: 35,
                      border: "1px solid #93c01f",
                    }}
                  >
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { marginBottom: 10, cursor: "pointer" }
                          : { marginBottom: 10, cursor: "pointer" };

                        const injectedProps: any = getSuggestionItemProps(
                          suggestion,
                          {
                            className,
                            style,
                          }
                        );

                        return (
                          <div {...injectedProps}>
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}
          </PlacesAutocomplete>

          <div
            style={{
              padding: 15,
            }}
          >
            <Slider
              style={{
                marginTop: 40,
                width: "100%",
              }}
              value={this.state.distance}
              min={0}
              max={100}
              getAriaValueText={(value: number) => `${value} km`}
              onChange={(e: any, distance: any) => {
                this.setState({ distance });
              }}
              valueLabelDisplay="on"
              marks={[
                {
                  value: 0,
                  label: "0 km",
                },
                {
                  value: 100,
                  label: "100 km",
                },
              ]}
            />
          </div>
        </div>
        <div
          style={{
            position: "relative",
            width: "100%",
            height: 320,
          }}
        >
          <GoogleMap
            location={this.state.location}
            distance={this.state.distance}
          />
        </div>
        {this.state.location && (
          <div
            style={{
              marginTop: 15,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={this.sendChange}
            >
              Suchen
            </Button>
          </div>
        )}
      </div>
    );
  }
}
