import React from 'react';

import Box from '../../../components/Box/Box';

export const Agb = {
  title: 'Allgemeine Geschäfts&shy;bedingungen',
  content: (
    <Box>
      <h2>Allgemeine Geschäftsbedingungen</h2>
      <h3>1. Allgemeine Informationen</h3>
      <p>
        <b>1.1.</b>
        <br />
        Kunden der BY die Bayerische Vorsorge Lebensversicherung AG (nachfolgend
        „die Bayerische“) mit einem Versicherungsprodukt der Bayerischen mit
        „Modul plusrente“ haben die Möglichkeit, an einem Vorteilsprogramm über
        ein Cashback-Portal auf der Website www.plusrente.de oder anderer
        Domains teilzunehmen. Verantwortlich für dieses Angebot ist die die
        Bayerische Online-Versicherungsagentur und Marketing GmbH (kurz BOAM)
        (nachfolgend „Anbieter“ genannt). Dienstleister des Cashback-Portals ist
        die MEHRWERK GmbH (nachfolgend „System-Dienstleister“ genannt).
      </p>
      <p>
        <b>1.2.</b>
        <br />
        Das Cashback-Portal bietet dem Portal-Nutzer die Möglichkeit, eine
        prozentuale Rückvergütung oder Rabatte auf Einkäufe bei einer breiten
        Auswahl von Onlineshops oder bei der Buchung von Reisen zu erlangen.
      </p>
      <p>
        <b>1.3.</b>
        <br />
        Der System-Dienstleister unterhält zur Abwicklung der prozentualen
        Rückvergütung oder Rabatte auf Einkäufe in Onlineshops Verträge mit
        verschiedenen Betreibern von Online-Shops (nachfolgend „Partnershops“
        genannt). Kunden der Bayerischen mit einem Versicherungsprodukt mit
        „Modul plusrente“ können bei einem Einkauf in Partnershops über das
        Cashback-Portal gemäß diesen AGB in Abhängigkeit vom Nettoeinkaufswert
        der im Partnershop bestellten Ware oder Dienstleistung einen bestimmten
        Betrag oder prozentualen Anteil des Nettoeinkaufswertes in Form von
        Bonuszahlungen gutgeschrieben bekommen. Bedingung für die Teilnahme am
        Cashback-Portal ist das Bestehen einer wirksamen Kundenbeziehung zur
        Bayerischen oder einer Ihrer Kooperationspartner in Form eines aktiven
        Versicherungsproduktes (plusrente) der Bayerischen, welcher sich in der
        Ansparphase befindet, oder die Zugangsmöglichkeit zu einem Portal eines
        Kooperationspartners und ein Wohnsitz in Deutschland.
      </p>
      <h3>2. Geltungsbereich</h3>
      <p>
        <b>2.1</b>
        <br />
        Für Leistungen zwischen dem Anbieter und den Kunden gelten
        ausschließlich diese AGB. Gegenbestätigungen des Kunden unter Hinweis
        auf seine eigenen Geschäftsbedingungen wird hiermit ausdrücklich
        widersprochen.
      </p>
      <p>
        <b>2.2</b>
        <br />
        Tätigt der Kunde eine Bestellung in einem Partnershop über das
        Cashback-Portal, kommt über diese Bestellung ein eigener Vertrag
        zwischen Partnershop und dem Kunden zustande, der Anbieter oder die
        Dienstleister werden insoweit nicht Vertragspartner des Kunden. Der
        Anbieter oder die Dienstleister übernehmen diesbezüglich auch keine
        Gewähr für Angaben der Partnershops oder für das Zustandekommen von
        Verträgen mit Partnershops.
      </p>
      <h3>3. Vertragsschluss</h3>
      <p>
        <b>3.1</b>
        <br />
        Zur Teilnahme am Cashback-Portal muss der Kunde sich einloggen und
        registrieren. Mit dem Ausfüllen des Registrierungsformulars und dem
        Akzeptieren dieser AGB erklärt der Kunde, das Cashback-Portal auf Basis
        dieser Bedingungen nutzen zu wollen. Es kommt ein Vertrag zwischen dem
        Anbieter und dem Kunden über die Nutzung des Cashback-Portals zustande.
      </p>
      <p>
        <b>3.2</b>
        <br />
        Für den Kunden ist dann zugleich ein virtuelles Guthabenkonto
        eingerichtet. Der Kunde hat im Guthabenkonto jederzeit die Möglichkeit,
        Einsicht in seine bisherigen Transaktionen und seinen derzeitigen
        Guthabenstand zu nehmen.
      </p>
      <p>
        <b>3.3</b>
        <br />
        Mit dem Akzeptieren dieser AGB stimmt der Portal-Nutzer der Ausschüttung
        seiner erwirtschafteten Rückvergütung auf sein aktives Konto bei der
        Bayerischen zu. Eine anderweitige Ausschüttung der Rückvergütung aus dem
        Cashback-Portal ist nicht möglich.
      </p>
      <h3>4. Nutzungsrecht</h3>
      <p>
        <b>4.1</b>
        <br />
        Dem Kunden wird das nicht ausschließliche und nicht übertragbare Recht
        eingeräumt, selbst oder vertreten durch von ihm befugte Nutzer in seinem
        Namen die in der vorstehenden Ziffer beschriebenen Leistungen zu nutzen.
        Der Anbieter ist berechtigt, Inhalte und Schnittstellen des
        Cashback-Portals sowie die Domain jederzeit ganz oder teilweise zu
        ändern, umzugestalten oder deren Bereitstellung einzustellen.
      </p>
      <p>
        <b>4.2</b>
        <br />
        Der Zugang zum internen Bereich ist nur registrierten Kunden möglich und
        gestattet. Aufgrund der nicht kontrollierbaren Bedingungen des Internets
        bzw. der miteinander verbundenen Computersysteme übernehmen der Anbieter
        und die Dienstleister keine Gewähr oder Garantie für die ununterbrochene
        Verfügbarkeit des vorstehend beschriebenen Zugangs.
      </p>
      <p>
        <b>4.3</b>
        <br />
        Ebenso übernehmen der Anbieter und die Dienstleister trotz Einsatzes von
        IT-Sicherheitsanwendungen keine Gewähr oder Garantie für eine
        Virenfreiheit. Ein Anspruch des Kunden auf Einrichtung von
        Sicherheitsvorkehrungen, insbesondere durch sogenannte Virenscanner,
        besteht nicht.
      </p>
      <p>
        <b>4.4</b>
        <br />
        Die Nutzung des Cashback-Portals ist kostenfrei.
      </p>
      <h3>5. Eigentum, Software</h3>
      <p>
        <b>5.1</b>
        <br />
        Der Anbieter und die Dienstleister behalten sich alle Eigentumsrechte
        sowie gewerblichen Schutzrechte wie Urheber-, Patent-, Warenzeichen-,
        Betriebsgeheimnis- und sonstige Rechte am geistigen Eigentum der über
        den persönlichen Zugang herunterladbaren Dokumente und sonstigen Daten
        vor, soweit nicht ausdrücklich etwas anderes bestimmt ist.
      </p>
      <p>
        <b>5.2</b>
        <br />
        Informationen, Markennamen und sonstige Inhalte des Zugangs dürfen,
        soweit nichts anderes bestimmt ist, weder kopiert, verändert,
        vervielfältigt, verkauft, vermietet, ergänzt oder in sonstiger Weise
        genutzt werden.
      </p>
      <h3>6. Vertragsgegenstand</h3>
      <p>
        <b>6.1</b>
        <br />
        Der System-Dienstleister unterhält Verträge mit verschiedenen
        Partnershops. Kunden können über das Cashback-Portal bei einem Einkauf
        in Partnershops Rückvergütungen erhalten; d.h. Kunden erhalten in
        Abhängigkeit vom Nettowarenwert der im Partnershop bestellten Ware oder
        Dienstleistung einen bestimmten Betrag oder prozentualen Anteil in Form
        von Bonuszahlungen gutgeschrieben. Eine Gutschrift findet nur dann
        statt, wenn die Bonuszahlungen im virtuellen Guthabenkonto, wie unter
        7.5 beschrieben, als bestätigt markiert sind.
      </p>
      <p>
        <b>6.2</b>
        <br />
        Die Dienstleister können das Angebot des Cashback-Portals und die
        Plattform / Website laufend weiterentwickeln und an die technischen
        Entwicklungen anpassen. Die Dienstleister sind weiterhin berechtigt,
        Partnershops auszutauschen, auszuschließen oder neue Partnershops zu
        integrieren. Kunden haben keinen Anspruch auf Beibehaltung der
        Vertragsbeziehung zu bestimmten Partnershops. Die Dienstleister stellen
        jedoch sicher, dass bei Ausschluss und / oder Wegfall eines Partnershops
        die hierüber angestoßenen und noch nicht abgeschlossenen Transaktionen,
        insbesondere die Gutschrift von Bonuszahlungen, vereinbarungsgemäß
        abgewickelt werden.
      </p>
      <h3>
        7. Einkaufen und Voraussetzungen für die Gutschriften von Bonuszahlungen
      </h3>
      <p>
        <b>7.1</b>
        <br />
        Kunden müssen sich für das Cashback-Portal anmelden. Sodann besteht
        Zugriff auf die Partnershops des Cashback-Portals.
      </p>
      <p>
        <b>7.2</b>
        <br />
        Eine Gutschrift von Bonuszahlungen wird nur bei Vorliegen folgender
        Voraussetzungen („Leistungsvoraussetzungen“) gewährt:
      </p>
      <p>
        <b>7.2.1</b>
        <br />
        Rechtsverbindliche Bestellung von Waren und / oder Leistungen bei einem
        Partnershop des Cashback-Portals nach Login des Kunden auf der Website,
      </p>
      <p>
        <b>7.2.2</b>
        <br />
        Annahme der Bestellung durch den Partnershop,
      </p>
      <p>
        <b>7.2.3</b>
        <br />
        Vollständige Bezahlung des Rechnungsbetrages durch den Kunden an den
        Partnershop
      </p>
      <p>
        <b>7.2.4</b>
        <br />
        Ablauf einer etwaigen gesetzlichen oder vertraglich vereinbarten
        Rückgabe-/ Widerrufsfrist, ohne dass ein Widerruf erfolgte sowie
      </p>
      <p>
        <b>7.2.5</b>
        <br />
        Bestätigung des erfolgreichen Geschäftsabschlusses sowie Auszahlung der
        Bonuszahlung durch den Partner-Shop an den System-Dienstleister.
      </p>
      <p></p>
      <p>
        <b>7.3</b>
        <br />
        Die Höhe der jeweiligen Bonuszahlung richtet sich nach der bestellten
        Ware und / oder Dienstleistung und bezieht sich auf den
        Netto-Einkaufswert ohne Berücksichtigung der anfallenden Umsatzsteuer
        sowie der Versand- und Versicherungskosten. Die Höhe der jeweiligen
        Bonuszahlung variiert je nach Partnershop und Produkt und kann von
        diesem jederzeit angepasst werden. Der jeweils gültige Bonus für
        Produkte und / oder Dienstleistungen wird im jeweiligen Partnershop im
        Cashback-Portal angezeigt.
      </p>
      <p>
        <b>7.4</b>
        <br />
        Sollten Bonuszahlungen (Fixbetrag oder prozentualer Anteil des
        Bestellwerts) von Partner-Shops nicht korrekt aufgeführt werden oder
        Fehler in der Übertragung von Bonuszahlungen erfolgen, übernehmen
        Anbieter und Dienstleister keinerlei Garantie dafür, dass die zur Zeit
        der Bestellung angegebenen Boni vom Partner-Shop gezahlt werden. Der
        System-Dienstleister zahlt den Betrag an den Anbieter aus, der vom
        Partnershop ausgezahlt wird.
      </p>
      <p>
        <b>7.5</b>
        <br />
        Kunden können in ihrem eigenen Gutschriftenkonto einsehen, welche
        Bonuszahlungen bisher für sie registriert wurden. Hierin als
        „vorgemerkt“ ausgewiesene Bonuszahlungen beziehen sich auf
        Transaktionen, die vom Partner-Shop noch nicht bestätigt wurden; bei den
        als „bestätigt“ ausgewiesenen Bonuszahlungen hat der Partnershop die
        entsprechende Bonuszahlung freigegeben. Als „abgelehnt“ ausgewiesene
        Bonuszahlungen werden mangels Vorliegens der Leistungsvoraussetzungen
        nicht geleistet. Bonuszahlungen, die zum Ende eines Monats den Status
        „bestätigt“ erreicht haben, stehen Kunden abschließend zu. Als
        „ausgezahlt“ gekennzeichnet sind Bonuszahlungen, welche bereits an den
        Anbieter angewiesen bzw. überwiesen wurden.
      </p>
      <p>
        <b>7.6</b>
        <br />
        Der aktuelle Stand zu den Bonuszahlungen wird dem Kunden laufend im
        Gutschriftenkonto aktualisiert und angezeigt. Erhebt der Kunde nicht
        binnen 30 Tagen Widerspruch in Textform (z.B. Papierform, E-Mail) gegen
        den Gutschriftstand, so gilt der vom Anbieter mitgeteilte Stand als
        richtig.
      </p>
      <p>
        <b>7.7</b>
        <br />
        Kunden, die über das Cashback-Portal einkaufen und dadurch Ansprüche auf
        Bonuszahlungen erwerben, die zum Ende eines Monats als „bestätigt“
        gekennzeichnet sind, haben einen Anspruch auf Gutschrift des
        ausgewiesenen Betrages auf ihren Versicherungsvertrag bei der
        Bayerischen. Die Überweisung von Bonuszahlungen erfolgt ab einem
        Mindestguthaben von EUR 50,00 automatisch.
      </p>
      <h3>8. Pflichten des Kunden</h3>
      <p>
        <b>8.1</b>
        <br />
        Der Kunde hat durch geeignete Maßnahmen stets sicherzustellen, dass
        unbefugte Dritte von seinen Zugangsdaten keine Kenntnis nehmen können.
        Soweit das Gesetz nicht zwingend etwas anderes bestimmt, haftet der
        Anbieter nicht für den Missbrauch durch unbefugte Dritte. Der Kunde muss
        durch entsprechende technische Vorkehrungen (insbesondere Spamfilter)
        sicherstellen, dass er alle vom Cashback-Portal an ihn versendeten
        E-Mails vollständig und zeitnah empfangen kann. Verwendet der Kunde eine
        Anti-Spam-Software, ist diese entsprechend zu konfigurieren.
      </p>
      <h3>9. Laufzeit</h3>
      <p>
        <b>9.1</b>
        <br />
        Das im Cashback-Portal erstellte Gutschriftenkonto eines Kunden wird auf
        den Zeitraum der Laufzeit des zu Grunde liegenden Versicherungsvertrages
        angelegt. Der Kunde kann den Nutzungsvertrag mit dem Anbieter über die
        Leistung des Cashback-Portals jederzeit ohne Angabe von Gründen
        kündigen. Die Kündigung hat in Textform (z. B. Brief, Fax, E-Mail) zu
        erfolgen. Die Kontaktdaten sind im Impressum auf der Website angegeben.
        Erfolgt eine ordentliche Kündigung durch den Kunden, so wird der
        Anbieter ihm die bis zum Vertragsende erwirtschafteten Bonuszahlungen
        gutschreiben, sobald die Leistungsvoraussetzungen gemäß diesen AGB
        vorliegen.
      </p>
      <p>
        <b>9.2</b>
        <br />
        Die Kündigung hat keine Auswirkungen auf den Bestand des
        Versicherungsvertrages der Bayerischen. Hat ein Kunde seine
        Registrierung gelöscht, kann er sich im Cashback-Portal jederzeit erneut
        registrieren, solange er den bisherigen oder einen anderen
        Versicherungsvertrag mit der Bayerischen unterhält.
      </p>
      <p>
        <b>9.3.</b>
        <br />
        Außerordentliche und fristlose Kündigungen können gegenüber dem Kunden
        durch den System-Dienstleister bei Vorliegen eines der nachfolgend
        genannten Gründe ausgesprochen werden:
      </p>
      <p>
        <b>9.3.1</b>
        <br />
        Betrug bzw. Fraud gegenüber Partnershops durch manuelles oder
        automatisiertes Vortäuschen von Transaktionen, Erzeugen von unechten
        Interessentenanfragen (Leads) sowie jedwede sonstige unter Verstoß gegen
        die AGB erfolgten Transaktionen.
      </p>
      <p>
        <b>9.3.2</b>
        <br />
        Verwendung von Partnerlinks zur Einbindung auf anderen Webseiten.
      </p>
      <p>
        <b>9.4.</b>
        <br />
        Im Fall einer fristlosen Kündigung aufgrund eines der vorstehend
        genannten Gründe fällt ein ggf. vorhandenes Bonusguthaben an den
        Anbieter, so dass keine Pflicht zur Gutschrift besteht.
      </p>
      <p>
        <b>9.5.</b>
        <br />
        Eine außerordentliche Kündigung durch den System-Dienstleister ist ohne
        Wirkung auf den Bestand des Vertrages mit dem Anbieter
      </p>
      <p>
        <b>9.6.</b>
        <br />
        Endet die Vertragsbeziehung zum Anbieter oder wird der Vertrag
        beitragsfrei gestellt, so endet auch die Möglichkeit, das
        Cashback-Portal weiter zu nutzen. Zu diesem Zeitpunkt noch nicht
        abgeschlossene Transaktionen werden vom System-Dienstleister
        ordnungsgemäß abgewickelt, insbesondere sämtliche noch offenen
        Bonuszahlungen bei Vorliegen der Leistungsvoraussetzungen dem Konto
        gutgeschrieben.
      </p>
      <h3>10. Datenschutz</h3>
      <p>
        <b>10.1.</b>
        <br />
        Anbieter und Dienstleister verpflichten sich zur Einhaltung der
        datenschutzrechtlichen Vorgaben, insbesondere der Regelungen des
        Bundesdatenschutz- und des Telemediengesetzes (BDSG und TMG).
        Personenbezogene Daten werden im Rahmen des Cashback-Portals nur
        insoweit und in dem Umfang erhoben und verarbeitet, als dies im
        technischen Sinne und zur die Erbringung der Services des
        Cashback-Portals notwendig ist. Grundsätzlich werden keine Nutzerdaten
        an Dritte weitergegeben.
      </p>
      <h3>11. Links</h3>
      <p>
        <b>11.1.</b>
        <br />
        Die Website des Cashback-Portals enthält Links zu externen Webseiten
        Dritter, insbesondere Partnershops. Auf deren Inhalte hat weder der
        Anbieter noch der Dienstleister Einfluss. Deshalb kann für diese fremden
        Inhalte auch keine Gewähr übernehmen werden. Für die Inhalte der
        verlinkten Seiten ist stets der jeweilige Betreiber der Seiten selbst
        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
        inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
        von Rechtsverletzungen werden derartige Links umgehend entfernt.
      </p>
      <h3>12. Haftung</h3>
      <p>
        <b>12.1</b>
        <br />
        Anbieter und Dienstleister haften bei Vorsatz und grober Fahrlässigkeit.
        Bei einfacher Fahrlässigkeit besteht Haftung nur a) für Schäden aus der
        Verletzung des Lebens, des Körpers oder der Gesundheit, b) für Schäden
        aus der Verletzung einer wesentlichen Vertragspflicht (Verpflichtung,
        deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt
        erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertraut
        und vertrauen darf); in diesem Fall ist die Haftung jedoch auf den
        Ersatz des vorhersehbaren, typischerweise eintretenden Schadens
        begrenzt. Die vorstehenden Haftungsbeschränkungen gelten nicht, soweit
        Anbieter oder Dienstleister einen Mangel arglistig verschwiegen oder
        seine Abwesenheit zugesichert haben. Das Gleiche gilt für Ansprüche nach
        dem Produkthaftungsgesetz. 12.2. Der Anbieter übernimmt keine
        Gewährleistung dafür, dass Umsätze zwischen dem Kunden und den
        Partnershops zu Stande kommen. Der Anbieter ist auch nicht verpflichtet,
        den Kunden aufgrund von Versäumnissen der Partnershops nicht
        gutgeschriebene Bonuszahlungen zu erstatten.
      </p>
      <h3>13. Änderungen</h3>
      <p>
        <b>13.1.</b>
        <br />
        Der Anbieter ist berechtigt, diese AGB unter folgenden Umständen für die
        Zukunft zu ändern: Eine Veränderung der Gesetzeslage oder der
        höchstrichterlichen Rechtsprechung, Wechsel des Dienstleisters sowie
        Änderungen der Marktgegebenheiten. Der Anbieter wird seine Kunden in
        einem solchen Fall rechtzeitig vorher über die Änderung der AGB per
        E-Mail unterrichten. Eine Änderung dieser AGB gilt als vom Kunden
        genehmigt, wenn dieser nicht innerhalb von zwei Wochen nach Erhalt der
        Änderungsmitteilung widerspricht oder den Vertrag ordentlich kündigt. Im
        Falle eines Widerspruches behält sich der Anbieter vor, seinerseits den
        Vertrag ordentlich zu kündigen. Der Anbieter wird in der E-Mail, mit der
        der Kunde über die Änderungen unterrichtet wird, noch einmal auf das
        Widerspruchs- bzw. Kündigungsrecht und seine Folgen hinweisen.
      </p>
      <p>
        <b>13.2</b>
        <br />
        Der Widerspruch oder die Kündigung des Nutzungsvertrages hat keine
        Auswirkungen auf den Versicherungsvertrag der Bayerischen.
      </p>
      <h3>14. Sonstiges</h3>
      <p>
        <b>14.1.</b>
        <br />
        Sollte eine Bestimmung dieser AGB unwirksam sein oder werden, so wird
        die Gültigkeit der übrigen Bestimmungen der AGB davon nicht berührt.
      </p>
      <p>
        <b>14.2.</b>
        <br />
        Es gilt ausschließlich deutsches Recht.
      </p>
    </Box>
  ),
};
