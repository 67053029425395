import * as React from 'react';
import { Layout } from '../../components/Layout/Layout';
import Hero from '../../components/Hero/Hero';
import { Grid, Button, Select, MenuItem, Slider } from '@material-ui/core';
import ShopTile from '../../components/ShopTile/ShopTile';
import Pagination from '../../components/Pagination/Pagination';
import Box from '../../components/Box/Box';
import HeroSearch from '../../components/HeroSearch/HeroSearch';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { CSSProperties } from 'react';
import Shop from '../../interfaces/shop';
import { Loading } from '../../components/Loading';
import CashbackManager from '../../services/manager/CashbackManager';
import Product from '../../interfaces/product';
import helper from '../../services/helper';
import SearchShop from '../../interfaces/searchShop';
import queryString from 'query-string';
import './Cashback.scss';
import { CashbackCategory } from '../../interfaces/cashbackCategory';
import StateManager from '../../services/manager/StateManager';

interface Props {
  match: any;
  location: any;
  history: any;
}

interface State {
  mode: string;
  query: string;
  shops: Shop[];
  products: Product[];
  productShops: SearchShop[];
  loading: boolean;
  page: number;
  numberOfPages: number;
  filters: string[];
  order: string;
  orderOnSearch: boolean;
  productOrder: string;
  shopsFilter: string[];
  priceRange: [number, number];
  priceRangeFilter: [number, number];
  categories: CashbackCategory[];
  categoryFilter: number;
}

export default class Cashback extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mode: 'combined',
      query: '',
      shops: [],
      products: [],
      productShops: [],
      loading: true,
      page: 1,
      numberOfPages: 0,
      filters: [],
      order: 'popularity_desc',
      orderOnSearch: false,
      shopsFilter: [],
      priceRange: [0, 0],
      priceRangeFilter: [0, 0],
      productOrder: 'priority',
      categories: [],
      categoryFilter: 0,
    };

    this.handleChangeMode = this.handleChangeMode.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
    this.handleToggleFilter = this.handleToggleFilter.bind(this);
    this.handleChangeShopFilter = this.handleChangeShopFilter.bind(this);
    this.handleChangePriceRangeFilter = this.handleChangePriceRangeFilter.bind(
      this
    );
    this.handleChangeProductOrder = this.handleChangeProductOrder.bind(this);
    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeCategoryFilter = this.handleChangeCategoryFilter.bind(
      this
    );
  }

  handleChangePriceRangeFilter(event: any, value: any) {
    this.setState({
      priceRangeFilter: value,
    });
  }

  handleChangeShopFilter(event: any) {
    this.setState(
      {
        shopsFilter: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeCategoryFilter(event: any) {
    this.setState(
      {
        categoryFilter: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeOrder(event: any) {
    this.setState(
      {
        order: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleChangeProductOrder(event: any) {
    this.setState(
      {
        productOrder: event.target.value,
        page: 1,
      },
      () => {
        this.load();
      }
    );
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.shops;
      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });
      this.setState({
        shops,
      });
    };
  }

  handleToggleFilter(filter: string) {
    return () => {
      let filters = this.state.filters;
      const index = filters.indexOf(filter);

      if (filter === 'cashbackAmount') {
        filters = filters.filter((item) => item !== 'cashbackPercent');
      }

      if (filter === 'cashbackPercent') {
        filters = filters.filter((item) => item !== 'cashbackAmount');
      }

      if (index !== -1) {
        filters.splice(index, 1);
      } else {
        filters.push(filter);
      }

      this.setState(
        {
          filters,
        },
        () => {
          this.load();
        }
      );
    };
  }

  handleRemoveFilter() {
    if (this.state.mode === 'shops') {
      return () => {
        this.setState(
          {
            filters: [],
            query: '',
            order: 'popularity_desc',
            categoryFilter: 0,
          },
          () => {
            this.load();
          }
        );
      };
    } else if (this.state.mode === 'products') {
      return () => {
        this.setState(
          {
            query: '',
            shopsFilter: [],
            priceRange: [0, 0],
            priceRangeFilter: [0, 0],
            productOrder: 'priority',
          },
          () => {
            this.load();
          }
        );
      };
    }
  }

  handleChangeMode(mode: string) {
    return () => {
      this.setState(
        {
          mode,
          page: 1,
          orderOnSearch: false,
        },
        () => {
          this.load();
        }
      );
    };
  }

  handleChangePage(page: number) {
    this.setState(
      {
        page,
        loading: true,
      },
      () => {
        this.load();
      }
    );
    window.scrollTo({
      top: 0,
    });
  }

  async componentDidMount() {
    const categories = await CashbackManager.findCategories();
    await this.setState({
      categories: categories.items,
    });

    const params = queryString.parse(this.props.location.search) as any;

    const newState: any = {};

    if (params.q) {
      newState.query = params.q;
    }

    // Get old filter state
    const oldState = StateManager.getState('pap');
    if (oldState) {
      if (oldState.query) newState.query = oldState.query;
      if (oldState.page) newState.page = oldState.page;
      if (oldState.order) newState.order = oldState.order;
      if (oldState.filters) newState.filters = oldState.filters;
      if (oldState.categoryFilter)
        newState.categoryFilter = oldState.categoryFilter;
      if (oldState.mode) newState.mode = oldState.mode;
      if (oldState.shopsFilter) newState.shopsFilter = oldState.shopsFilter;
      if (oldState.productOrder) newState.productOrder = oldState.productOrder;
      if (oldState.priceRangeFilter)
        newState.priceRangeFilter = oldState.priceRangeFilter;
    }

    await this.setState(newState);

    this.load();
  }

  async load() {
    this.setState({ loading: true });

    // Save filter state
    StateManager.setState('pap', {
      query: this.state.query,
      page: this.state.page,
      order: this.state.order,
      filters: this.state.filters,
      categoryFilter: this.state.categoryFilter,
      mode: this.state.mode,
      shopsFilter: this.state.shopsFilter,
      productOrder: this.state.productOrder,
      priceRangeFilter: this.state.priceRangeFilter,
    });

    if (this.state.mode === 'shops') this.loadShops();
    if (this.state.mode === 'products') this.loadProducts();
    if (this.state.mode === 'combined') this.loadShopsAndProducts();
  }

  async loadShopsAndProducts() {
    const params = {
      page: this.state.page,
    } as any;

    let products;
    if (this.state.query.trim().length >= 3) {
      params.query = this.state.query;
      products = await CashbackManager.findProducts(params);
    } else {
      products = { products: [], numberOfResults: 0 };
    }
    const shops = await CashbackManager.findShops(params);

    if (typeof shops.items === 'object')
      shops.items = Object.values(shops.items);

    this.setState({
      products: products.products,
      shops: shops.items,
      loading: false,
      numberOfPages: Math.max(
        shops.numberOfPages,
        Math.ceil(products.numberOfResults / 50)
      ),
    });
  }

  async loadProducts() {
    if (this.state.query.trim().length < 3)
      return this.setState({ loading: false });

    const params = {
      page: this.state.page,
      query: this.state.query,
      filters: {},
    } as any;

    if (this.state.shopsFilter) params.filters.shops = this.state.shopsFilter;

    if (this.state.productOrder !== '')
      params.filters.order = this.state.productOrder;

    let priceRangeFilterIsSet = false;
    if (
      this.state.priceRange[0] !== this.state.priceRangeFilter[0] ||
      this.state.priceRange[1] !== this.state.priceRangeFilter[1]
    ) {
      params.filters.priceRange = {
        min: this.state.priceRangeFilter[0],
        max: this.state.priceRangeFilter[1],
      };
      priceRangeFilterIsSet = true;
    }

    const products = await CashbackManager.findProducts(params);

    this.setState({
      products: products.products,
      productShops: products.shops,
      shops: [],
      loading: false,
      page: products.currentPage,
      numberOfPages: Math.ceil(products.numberOfResults / 50),
      priceRange: [products.lowestPrice, products.highestPrice],
      priceRangeFilter: priceRangeFilterIsSet
        ? this.state.priceRangeFilter
        : [products.lowestPrice, products.highestPrice],
    });
  }

  async loadShops() {
    const params = {
      page: this.state.page,
    } as any;
    if (this.state.query.trim().length >= 3) params.query = this.state.query;

    if (!this.state.orderOnSearch) {
      if (this.state.order.trim() !== '') {
        params.orderBy = this.state.order.split('_')[0];
        params.direction = this.state.order.split('_')[1];
      }
    }

    if (this.state.filters.indexOf('vouchers') > -1) params.vouchers = true;
    if (this.state.filters.indexOf('favorites') > -1) params.favorites = true;
    if (this.state.filters.indexOf('cashbackPercent') > -1)
      params.cashbackType = 'perc';
    if (this.state.filters.indexOf('cashbackAmount') > -1)
      params.cashbackType = 'eur';

    if (this.state.categoryFilter !== 0) {
      params.category = this.state.categoryFilter;
    }

    const shops = await CashbackManager.findShops(params);

    if (typeof shops.items === 'object')
      shops.items = Object.values(shops.items);

    this.setState({
      products: [],
      shops: shops.items,
      loading: false,
      page: shops.currentPage,
      numberOfPages: shops.numberOfPages,
    });
  }

  handleChangeQuery(query: string) {
    this.setState({
      query,
    });
  }

  handleSearch() {
    this.setState(
      {
        page: 1,
        orderOnSearch: true,
      },
      () => {
        this.load();
      }
    );
  }

  renderSortFilter(style: CSSProperties) {
    const sortings = [
      { label: 'Alphabetisch', value: 'name_asc' },
      { label: 'Aktuelle Einträge zu Beginn', value: 'createdAt_desc' },
      { label: 'Cashback aufsteigen', value: 'maximumCashback_asc' },
      { label: 'Cashback absteigend', value: 'maximumCashback_desc' },
      { label: 'Beliebte zu Beginn', value: 'popularity_desc' },
    ];

    return (
      <div style={style}>
        <Select
          className="rounded-select"
          value={this.state.order}
          onChange={this.handleChangeOrder}
        >
          {sortings.map((order, key) => (
            <MenuItem value={order.value} key={key}>
              {order.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderProductSortFilter(style: CSSProperties) {
    const sortings = [
      { label: 'Relevanz', value: 'priority' },
      { label: 'Preis aufsteigend', value: 'asc' },
      { label: 'Preis absteigend', value: 'desc' },
    ];

    return (
      <div style={style}>
        <span
          className="font-small"
          style={{
            marginRight: 10,
            marginBottom: 5,
          }}
        >
          Sortieren
        </span>
        <Select
          className="rounded-select"
          value={this.state.productOrder}
          onChange={this.handleChangeProductOrder}
          style={{
            width: '100%',
          }}
        >
          {sortings.map((order, key) => (
            <MenuItem value={order.value}>{order.label}</MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderQuickFilter(style: CSSProperties) {
    const filters = [
      { id: 'favorites', label: 'nur Favoriten' },
      { id: 'vouchers', label: 'mit Gutschein' },
      { id: 'cashbackPercent', label: 'nur prozentualer Cashback' },
      { id: 'cashbackAmount', label: 'fester Cashbackwert' },
    ];

    return (
      <div style={style}>
        {filters.map((filter, key) => (
          <Button
            key={key}
            color={
              this.state.filters.indexOf(filter.id) > -1
                ? 'secondary'
                : 'primary'
            }
            variant={
              this.state.filters.indexOf(filter.id) > -1
                ? 'contained'
                : 'outlined'
            }
            style={{ marginRight: 10, marginBottom: 10 }}
            onClick={this.handleToggleFilter(filter.id)}
          >
            {filter.label}
          </Button>
        ))}
      </div>
    );
  }

  renderPriceRangeFilter(style: CSSProperties) {
    return (
      <div style={style}>
        <span
          className="font-small"
          style={{
            marginRight: 10,
            marginBottom: 5,
          }}
        >
          Preisspanne
        </span>
        <Slider
          style={{
            marginTop: 30,
            marginLeft: '5%',
            width: '90%',
          }}
          value={
            this.state.priceRangeFilter
              ? this.state.priceRangeFilter
              : this.state.priceRange
          }
          min={this.state.priceRange[0]}
          max={this.state.priceRange[1]}
          getAriaValueText={(value: number) => `${value} €`}
          onChange={this.handleChangePriceRangeFilter}
          onChangeCommitted={() => this.load()}
          valueLabelDisplay="on"
        />
      </div>
    );
  }

  renderShopFilter(style: CSSProperties) {
    return (
      <div style={style}>
        <span
          className="font-small"
          style={{
            marginRight: 10,
            marginBottom: 5,
          }}
        >
          Nach Shop filtern
        </span>
        <Select
          className="rounded-select"
          value={this.state.shopsFilter}
          multiple={true}
          onChange={this.handleChangeShopFilter}
        >
          {this.state.productShops.map((shop: SearchShop, key) => (
            <MenuItem key={key} value={shop.id}>
              {shop.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderCategoryFilter(style: CSSProperties) {
    return (
      <div style={style}>
        <Select
          className="rounded-select"
          value={this.state.categoryFilter}
          onChange={this.handleChangeCategoryFilter}
        >
          <MenuItem value={0}>Kategorie filtern</MenuItem>
          {this.state.categories.map((category: CashbackCategory, key) => (
            <MenuItem key={key} value={category.id}>
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderFilter() {
    if (this.state.mode === 'shops') {
      return (
        <Box
          style={{
            marginBottom: '1em',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            paddingBottom: '70px',
            flexWrap: 'wrap',
            gap: 10,
          }}
        >
          {this.renderQuickFilter({})}
          {this.renderCategoryFilter({})}
          {this.renderSortFilter({})}
          <div
            style={{
              position: 'absolute',
              bottom: '30px',
              left: '50%',
              marginLeft: '-59px',
            }}
          >
            <a
              href="#"
              onClick={this.handleRemoveFilter()}
              style={{
                borderBottom: '1px solid #93c01f',
                color: '#333',
              }}
            >
              Filter zurücksetzen
            </a>
          </div>
        </Box>
      );
    }

    if (this.state.mode === 'products') {
      return (
        <Box
          style={{
            marginBottom: '1em',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
            paddingBottom: '30px',
          }}
        >
          <Grid container spacing={4}>
            <Grid item md={4} xs={12}>
              {this.renderPriceRangeFilter({})}
            </Grid>
            <Grid item md={4} xs={12} style={{}}>
              {this.renderShopFilter({
                display: 'flex',
                flexDirection: 'column',
              })}
            </Grid>
            <Grid item md={4} xs={12}>
              {this.renderProductSortFilter({
                display: 'flex',
                flexDirection: 'column',
              })}
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  position: 'absolute',
                  bottom: '30px',
                  left: '50%',
                  marginLeft: '-59px',
                }}
              >
                <a
                  href="#"
                  onClick={this.handleRemoveFilter()}
                  style={{
                    borderBottom: '1px solid #93c01f',
                    color: '#333',
                  }}
                >
                  Filter zurücksetzen
                </a>
              </div>
            </Grid>
          </Grid>
        </Box>
      );
    }
  }

  render() {
    return (
      <Layout>
        <Hero
          size="lg"
          imageSrc="/assets/images/heroes/cashback.jpg"
          imagePositionY={-100}
        >
          <HeroSearch
            title="Shops & Produkte"
            subTitle="zum besten Preis finden!"
            placeholder="Shop oder Produkt suchen und finden..."
            buttonLabel="suchen"
            onSubmit={this.handleSearch}
            onChange={this.handleChangeQuery}
            query={this.state.query}
          >
            <button
              onClick={this.handleChangeMode('combined')}
              className={this.state.mode === 'combined' ? 'active' : ''}
            >
              Shops & Produkte
            </button>
            <button
              onClick={this.handleChangeMode('shops')}
              className={this.state.mode === 'shops' ? 'active' : ''}
            >
              nur Shops
            </button>
            <button
              onClick={this.handleChangeMode('products')}
              className={this.state.mode === 'products' ? 'active' : ''}
            >
              nur Produkte
            </button>
          </HeroSearch>
        </Hero>
        <InnerLayout>
          <div className="filter">{this.renderFilter()}</div>
          {this.state.loading && <Loading />}
          {!this.state.loading && (
            <div>
              {this.state.shops.length === 0 &&
                this.state.products.length === 0 && (
                  <p
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Leider konnten für diesen Suchbegriff keine Ergebnisse
                    gefunden werden.
                  </p>
                )}
              <Grid container spacing={2}>
                {this.state.shops.map((shop: Shop, key) => (
                  <Grid item xs={6} md={3} key={key}>
                    <ShopTile
                      image={shop.logo}
                      buttonLabel="zum Shop"
                      morePath={`/shop/${shop.id}`}
                      buttonLink={shop.link}
                      isFavorite={shop.isFavorite}
                      onFavoriteChange={this.handleFavoriteChange(shop)}
                      shopId={shop.id}
                      history={this.props.history}
                    >
                      Bis zu{' '}
                      <strong>
                        {shop.maximumCashback} {shop.maximumCashbackType}
                      </strong>{' '}
                      bei {shop.name}
                    </ShopTile>
                  </Grid>
                ))}
                {this.state.products.map((product: Product, key) => (
                  <Grid item xs={6} md={3} key={key}>
                    <ShopTile
                      image={product.imageUrl}
                      buttonLabel="zum Shop"
                      morePath={`/produkt/${product.id}`}
                      buttonLink={product.link}
                      shopId={product.shop.id}
                      history={this.props.history}
                    >
                      <strong>{product.name}</strong>
                      <div
                        style={{
                          marginTop: 10,
                          fontWeight: 'bold',
                        }}
                      >
                        ab {helper.formatNumber(product.price / 100, 2)}{' '}
                        {product.currency.trim() === ''
                          ? '€'
                          : product.currency}{' '}
                        {product.duplicatesCount > 0 &&
                          `in ${product.duplicatesCount} Shops`}
                      </div>
                    </ShopTile>
                  </Grid>
                ))}
              </Grid>

              <Pagination
                currentPage={this.state.page}
                numberOfPages={this.state.numberOfPages}
                onPageChange={this.handleChangePage}
              />
            </div>
          )}
        </InnerLayout>
      </Layout>
    );
  }
}
