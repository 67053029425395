import * as React from "react";
import { Layout } from "../components/Layout/Layout";
import Hero from "../components/Hero/Hero";
import InnerLayout from "../components/InnerLayout/InnerLayout";

export class Error404 extends React.Component<{}, {}> {
  render() {
    return (
      <Layout title="Seite nicht gefunden">
        <div className="Registration">
          <Hero size="md" imageSrc="/assets/images/heroes/travel.jpg">
            <h1>404</h1>
          </Hero>
          <InnerLayout>
            <h2>Die Seite wurde nicht gefunden</h2>
            <p>Unter dieser Adresse wurde leider keine Seite gefunden.</p>
          </InnerLayout>
        </div>
      </Layout>
    );
  }
}
