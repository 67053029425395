
export default class StateManager {

    static states : any = {};

    static setState(name: string, state : any) {
        StateManager.states[name] = state;
    }

    static getState(name: string, defaultValue?: any) {
        return StateManager.states[name] !== undefined ? StateManager.states[name] : (defaultValue !== undefined ? defaultValue : undefined);
    }

}
