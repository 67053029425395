import * as React from 'react';
import { Button, PropTypes } from '@material-ui/core';
import Auth from '../services/auth';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';

interface Props {
  color?: PropTypes.Color;
  disableFocusRipple?: boolean;
  endIcon?: React.ReactNode;
  fullWidth?: boolean;
  href?: string;
  target?: string;
  size?: 'small' | 'medium' | 'large';
  startIcon?: React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
  children: any;
  style?: CSSProperties;
  onClick?: (e: any) => void;
}

export const AuthButton = (props: Props) => {
  if (Auth.isLoggedIn()) {
    return <Button {...props}>{props.children}</Button>;
  } else {
    const openLogin = (e: any) => {
      e.preventDefault();

      const doc = document as any;

      window.scrollTo({
        top: 0,
      });

      try {
        const evt = new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window,
        });

        doc.getElementById('loginButton').dispatchEvent(evt);
      } catch (e) {
        doc.getElementById('login-menu').style.display = 'block';
      }
    };

    return (
      <Button
        {...props}
        variant={Auth.isLoggedIn() ? 'contained' : 'outlined'}
        onClick={openLogin}
        href="#"
      >
        zum Login
        <FontAwesomeIcon
          icon={faArrowRight}
          style={{
            marginLeft: 10,
          }}
        />
      </Button>
    );
  }
};
