import React from 'react';

import Box from '../../../components/Box/Box';

export const Imprint = {
  title: 'Impressum',
  content: (
    <Box>
      <h2>Impressum</h2>
      <h3>Herausgeber / Diensteanbieter gem. § 5 TMG:</h3>
      <p>
        BY die Bayerische Vorsorge Lebensversicherung a.G.
        <br />
        Aufsichtsratsvorsitzender: Prof. Dr. Alexander Hemmelrath
        <br />
        Vorstand: Dr. Herbert Schneidemann (Vorsitzender), Martin Gräfer, Thomas
        Heigl
        <br />
        Registergericht: München HRB 81283
        <br />
        Umsatzsteueridentifikationsnummer (Ust-ID-Nr.): DE 129 274 448
      </p>
      <h3>Anschrift</h3>
      <address>
        <p>
          Thomas-Dehler-Str. 25
          <br />
          81737 München
        </p>
        <p>
          Tel. <a href="tel:08967870">089/6787-0</a>
          <br />
          Fax <a href="tel:08967879150">089/6787-9150</a>
          <br />
          E-Mail{' '}
          <a href="mailto:info@diebayerische.de">info@diebayerische.de</a>
        </p>
      </address>
      <h3>Aufsichtsbehörde</h3>
      <address>
        <p>
          Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)
          <br />
          Graurheindorfer Str. 108
          <br />
          53117 Bonn
        </p>
      </address>
      <h3>Verantwortlich i.S.v. § 18 Abs. 2 MStV:</h3>
      <address>
        <p>
            <ul>
                <li>
                    Felicitas Eckert - Pressesprecherin Schaden- und Unfallversicherung
                </li>
                <li>
                    Moritz Rebhan - Pressesprecher Nachhaltigkeit und Pangaea Life
                </li>
                <li>
                    Simon Kuklinski- Pressesprecher Konzern und Lebensversicherung
                </li>
            </ul>
        </p>
          <p>
              BY die Bayerische Vorsorge Lebensversicherung a.G.
              <br/>
              Thomas-Dehler-Str. 25
          <br />
          81737 München
        </p>
        <p>
          E-Mail{' '}
          <a href="mailto:presse@diebayerische.de">presse@diebayerische.de</a>
        </p>
      </address>
      <p>
        Auf den Seiten von www.plusrente.de finden Sie Produkte, Angebote und
        Informationen folgender Gesellschaften:
      </p>
      <h3>BL die Bayerische Lebensversicherung AG</h3>
      <p>
        Aufsichtsratsvorsitzender: Prof. Dr. Alexander Hemmelrath
        <br />
        Vorstand: Dr. Herbert Schneidemann (Vorsitzender), Martin Gräfer, Thomas
        Heigl
        <br />
        Registergericht: München HR B 81 283
        <br />
        Umsatzsteueridentifikationsnummer (Ust-ID-Nr.): DE 811 813 902
      </p>
      <address>
        <p>
          Thomas-Dehler-Str. 25
          <br />
          81737 München
        </p>
        <p>
          Tel. <a href="tel:08967870">089/6787-0</a>
          <br />
          Fax <a href="tel:08967879150">089/6787-9150</a>
          <br />
          E-Mail{' '}
          <a href="mailto:info@diebayerische.de">info@diebayerische.de</a>
        </p>
      </address>
      <h3>Aufsichtsbehörde</h3>
      <address>
        <p>
          Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)
          <br />
          Graurheindorfer Str. 108
          <br />
          53117 Bonn
        </p>
      </address>
    </Box>
  ),
};
