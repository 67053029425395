import {API} from "../API";
import {List} from "../../interfaces/list";
import {Tour} from "../../interfaces/Tour";
import {Event} from "../../interfaces/Event";
import {Category} from "../../interfaces/Category";
import {BasketItem} from "../../interfaces/basketItem";
import {Type} from "../../interfaces/Type";

export default class TicketManager {

    static async findTours(params : any = {}): Promise<List<Tour>> {
        return await API.get(`tickets/tours?${API.encodeURL(params)}`);
    }

    static async findTour(id: number): Promise<Tour> {
        return await API.get(`tickets/tours/${id}`);
    }

    static async findEvent(id: number): Promise<Event> {
        return await API.get(`tickets/events/${id}`);
    }

    static async findCities(): Promise<string[]> {
        return await API.get(`tickets/cities`);
    }

    static async findTypes(): Promise<Type[]> {
        return await API.get(`tickets/types`);
    }


    static getBasket(): BasketItem[] {
        const data = localStorage.getItem('basket');
        if (!data) return [];
        const basket = JSON.parse(data);
        if (!basket) return [];
        return basket;
    }

    static saveBasket(basket: BasketItem[]): BasketItem[] {
        localStorage.setItem('basket', JSON.stringify(basket));
        return basket;
    }

    static addToBasket(event: Event, category: Category, count: number): BasketItem[] {
        const basket = TicketManager.getBasket();
        let found = false;

        basket.forEach((item) => {
            if (item.category.id === category.id) {
                item.count += count;
                found = true;
            }
        });

        if (!found) {
            basket.push({
                count,
                category,
                event
            });
        }

        return TicketManager.saveBasket(basket);
    }

    static removeFromBasket(item: BasketItem): BasketItem[] {
        const basket = TicketManager.getBasket();
        basket.forEach((c, key) => {
            if (c.category.id === item.category.id) {
                basket.splice(key, 1);
            }
        });
        return TicketManager.saveBasket(basket);
    }

    static async addFavorite(tour: Tour) {
        return await API.post(`tickets/favorites`,{
            id: tour.id
        });
    }

    static async removeFavorite(tour: Tour) {
        return await API.delete(`tickets/favorites/${tour.id}`);
    }

    static async findFavorites(): Promise<Tour[]> {
        return await API.get(`tickets/favorites`);
    }

}
