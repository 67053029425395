import './App.scss';

import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { AppLoading } from './components/AppLoading';
import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import ProductDetail from './features/Cashback/ProductDetail';
import ShopDetail from './features/Cashback/ShopDetail';
import { Error404 } from './features/Error404';
import { Home } from './features/Home/Home';
import { InfoPage } from './features/InfoPage/InfoPage';
import RegionalOfferDetail from './features/RegionalOffers/RegionalOfferDetail';
import SetNewPassword from './features/SetNewPassword';
import UnsubscribeFromNewsletter from './features/UnsubscribeFromNewsletter';
import VerifyAccount from './features/VerifyAccount';
import {
  footerItems,
  hiddenRoutes,
  navigationItems,
  Route as RouteInterface,
} from './routes';
import { Alert } from './services/alert';
import Auth from './services/auth';
import Config from './services/config';
import CidaasSDK from 'cidaas-javascript-sdk';

interface State {
  loggedIn?: boolean;
  loading: boolean;
}

const options = {
  authority: Config.getEnv('REACT_APP_CIDAAS_AUTHORITY'),
  client_id: Config.getEnv('REACT_APP_CIDAAS_CLIENT_ID'),
  redirect_uri: Config.getEnv('REACT_APP_CIDAAS_REDIRECT_URI'),
  response_type: 'token',
  scope: 'profile',
  mode: 'redirect',
};
const cidaas = new CidaasSDK.WebAuth(options);

class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidCatch(error: Error | null, info: object) {
    Alert.error(
      'Ein Fehler ist aufgetreten',
      'Bitte versuchen Sie es später noch einmal. Wir arbeiten mit Hochdruck an der Behebung des Fehlers.'
    );
  }

  async componentDidMount() {
    const queryParams = new URLSearchParams(window.location.hash);
    const accessToken = queryParams.get('access_token');

    if (accessToken === null) {
      this.setState({
        loggedIn: await Auth.checkLogin(),
        loading: false,
      });
    } else {
      this.setState({
        loggedIn: false,
        loading: false,
      });
    }
  }

  render() {
    if (this.state.loading) return <AppLoading />;

    return (
      <div>
        <BrowserRouter basename="/">
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Home} />

            {[...navigationItems, ...footerItems, ...hiddenRoutes].map(
              (item: RouteInterface, index) => {
                if (item.component) {
                  const attr = {
                    exact: true,
                    key: 'route-' + index,
                    path: item.link,
                    component: item.component,
                  };

                  if (item.private) {
                    return <PrivateRoute {...attr} />;
                  } else {
                    return <Route {...attr} />;
                  }
                }
              }
            )}
            <Route exact path="/produkt/:id" component={ProductDetail} />
            <Route exact path="/vor-ort/:id" component={RegionalOfferDetail} />
            <Route
              path="/reisebuchung"
              render={() => <Redirect to="/reisen" />}
            />
            <Route exact path="/shop/:id" component={ShopDetail} />
            <Route exact path="/info/:id" component={InfoPage} />
            <Route
              exact
              path="/registrierung/:type"
              component={() => {
                cidaas.registerWithBrowser();
                return null;
              }}
            />
            <Route
              exact
              path="/passwort-zuruecksetzen"
              component={SetNewPassword}
            />
            <Route exact path="/konto-aktivieren" component={VerifyAccount} />
            <Route
              exact
              path="/newsletter/abmeldung"
              component={UnsubscribeFromNewsletter}
            />

            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
