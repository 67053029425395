import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import * as React from 'react';

import Box from '../../components/Box/Box';
import Hero from '../../components/Hero/Hero';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Alert } from '../../services/alert';
import Auth from '../../services/auth';
import ContactManager from '../../services/manager/ContactManager';
import InfoPageModal from '../InfoPage/InfoPageModal';

interface Props {}

interface State {
  subject: string;
  message: string;
  acceptedPrivacy: boolean;
  submitted: boolean;
  email: string;
}

export class Contact extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      subject: "Allgemeine Anfrage",
      message: "",
      acceptedPrivacy: false,
      submitted: false,
      email: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const tokenInfo = Auth.getTokenInfo();
    const email = tokenInfo?.email;
    if(email) {
      this.setState({email});
    }
  }

  handleChange(field: string) {
    return (e: any) => {
      this.setState({
        [field]: e.target.value
      } as any);
    };
  }

  async handleSubmit() {
    await this.setState({submitted: true});
    if(!this.state.acceptedPrivacy || (this.state.email.length < 3 || !this.state.email.includes("@"))) {
      return;
    }
    if (this.state.message.trim() === "") return;

    const response = await ContactManager.contact({
      email: this.state.email,
      title: this.state.subject,
      message: this.state.message
    });

    if((response.statusCode && response.statusCode >= 200 && response.statusCode < 300) || response.messageId) {
      Alert.success(
        "Vielen Dank für Ihre Anfrage!",
        "Wir kümmern uns um Ihr Anliegen schnellstmöglich."
      );
    }else {
      Alert.warning(
        "Es ist ein Fehler aufgetreten",
        "Bitte probieren Sie es zu einem späteren Zeitpunkt erneut oder wenden Sie sich an unser Service-Center."
      );
    }

    this.setState({
      subject: "Allgemeine Anfrage",
      message: "",
      acceptedPrivacy: false,
      submitted: false,
      email: ""
    });
  }

  render() {
    return (
      <Layout>
        <Hero
          size="lg"
          imageSrc="/assets/images/heroes/tickets.jpg"
          imagePositionY={-200}
        />
        <InnerLayout>
          <Box>
            <h2>Kontakt</h2>

            <Grid container spacing={2}>
              <Grid item xs={12}>
              <FormControl error={(this.state.email.length < 3 || !this.state.email.includes("@"))&& this.state.submitted}>
              <TextField
                label="Ihre E-Mail Adresse"
                variant="filled"
                value={this.state.email}
                onChange={this.handleChange("email")}
              />
                {(this.state.email.length < 3 || !this.state.email.includes("@")) && this.state.submitted && (
                  <FormHelperText>Bitte geben Sie eine gültige E-Mail Adresse ein</FormHelperText>
                )}
              </FormControl>
              </Grid>
              <Grid item xs={12}>
                 <Select
                    className="rounded-select"
                    value={this.state.subject}
                    onChange={this.handleChange("subject")}
                    label="Betreff"
                    style={{width: "100%"}}
                  >
                    <MenuItem value={"Allgemeine Anfrage"}>
                      Allgemeine Anfrage
                    </MenuItem>
                    <MenuItem value={"Verbesserungsvorschlag"}>
                      Verbesserungsvorschlag
                    </MenuItem>
                    <MenuItem value={"Shopvorschlag"}>
                      Shopvorschlag
                    </MenuItem>
                  </Select>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  label="Ihre Nachricht"
                  variant="filled"
                  multiline={true}
                  value={this.state.message}
                  onChange={this.handleChange("message")}
                  rows={10}
                />
              </Grid>
            </Grid>

            <br />

            <Grid item xs={12}>
              <FormControl error={!this.state.acceptedPrivacy && this.state.submitted}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.acceptedPrivacy}
                      onChange={(e) =>
                        this.setState({ acceptedPrivacy: !!e.target.value })
                      }
                      value="true"
                      color="primary"
                    />
                  }
                  label={
                    <p>
                      Ich habe die{" "}
                      <InfoPageModal route="datenschutz">
                        Datenschutz&shy;bestimmungen
                      </InfoPageModal>{" "}
                      gelesen und akzeptiere diese.
                    </p>
                  }
                />
                {!this.state.acceptedPrivacy && this.state.submitted && (
                  <FormHelperText>Pflichtfeld</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end"
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
              >
                Absenden
              </Button>
            </Grid>
          </Box>
        </InnerLayout>
      </Layout>
    );
  }
}
