import "./Dashboard.scss";

import { faCoins, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import React from "react";

import Backlink from "../../components/Backlink/Backlink";
import Box from "../../components/Box/Box";
import Hero from "../../components/Hero/Hero";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import KpiTile from "../../components/KpiTile/KpiTile";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ResponsiveTable from "../../components/ResponsiveTable/ResponsiveTable";
import { Cashout } from "../../interfaces/cashout";
import { CashoutProfile } from "../../interfaces/cashoutProfile";
import Helper from "../../services/helper";
import CashbackManager from "../../services/manager/CashbackManager";

interface Props {}

interface State {
  cashouts: Cashout[];
  cashoutProfile?: CashoutProfile;
  confirmedCashback?: number;
  pendingCashback?: number;
  cashoutSum: number;
  loading: boolean;
  openComplaints: number;
}

export default class Payouts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openComplaints: 0,
      cashouts: [],
      cashoutSum: 0,
      loading: true,
    };
  }

  async componentDidMount() {
    const [
      cashoutProfile,
      openComplaints,
      { confirmed: confirmedCashback, open: pendingCashback },
    ] = await Promise.all([
      CashbackManager.findCashouts(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getCashbackAmountSums(["confirmed", "open"]),
    ]);

    this.setState({
      cashouts: cashoutProfile.items,
      cashoutProfile,
      openComplaints,
      confirmedCashback,
      pendingCashback,
      loading: false,
    });
  }

  getCashoutSum() {
    let cashoutSum = 0;

    if (this.state.cashouts) {
      this.state.cashouts.forEach((cashout) => {
        cashoutSum += cashout.sum;
      });
    }

    return cashoutSum;
  }

  render() {
    return (
      <Layout>
        <div className="Dashboard">
          <Hero
            size="md"
            imageSrc="/assets/images/heroes/dashboard.jpg"
            imagePositionY={-280}
          >
            <h1>Meine Auszahlungen</h1>
          </Hero>

          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Backlink label="zurück zur Übersicht" to="/profil" />

              <Grid container spacing={4}>
                <Grid item xs={12} md={9}>
                  <Box>
                    <h2>Meine Auszahlungen</h2>
                    <p>
                      Ihre bestätigten Einkäufe werden bis zu einer
                      Auszahlungsgrenze von 50 € gesammelt und danach komplett
                      in Ihre Plusrente eingezahlt.
                    </p>
                    <ResponsiveTable
                      lastRowIsSumRow
                      columns={[
                        {
                          name: "title",
                          label: "Titel",
                          notCollapseOnMobile: true,
                          textAlign: "left",
                        },
                        {
                          name: "date",
                          label: "Einkaufsdatum",
                          textAlign: "left",
                        },
                        {
                          name: "sum",
                          label: "Cashback",
                          notCollapseOnMobile: true,
                          textAlign: "right",
                        },
                        {
                          name: "status",
                          label: "Status",
                          notCollapseOnMobile: true,
                          textAlign: "right",
                          customBodyRender: (value) => (
                            <div>
                              <span className="color-bad">
                                {value === "requested" && "Angefragt"}
                                {value === "open" && "Offen"}
                                {value === "cancelled" && "Storniert"}
                                {value === "canceled" && "Storniert"}
                                {value === "rejected" && "Abgelehnt"}
                              </span>
                              <span className="color-good">
                                {value === "confirmed" && "Ausgezahlt"}
                                {value === "done" && "Erledigt"}
                                {value === "waiting" && "Wartet auf Kunde"}
                              </span>
                            </div>
                          ),
                        },
                      ]}
                      data={
                        this.state.cashouts && [
                          ...this.state.cashouts.map(
                            (cashout: Cashout, index) => ({
                              title: cashout.recipient_name,
                              date: Helper.formatDate(cashout.date),
                              sum: Helper.formatPrice(cashout.sum, "EUR"),
                              status: cashout.status,
                            })
                          ),
                          {
                            title: "",
                            date: "",
                            sum: Helper.formatPrice(
                              this.getCashoutSum(),
                              "EUR"
                            ),
                            status: "",
                          },
                        ]
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <KpiTile
                    color="#fff"
                    bgColor="#93c01f"
                    title="plusrente Guthaben"
                    icon={<FontAwesomeIcon icon={faCoins} />}
                    link="/transaktionen"
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          textAlign: "right",
                          marginRight: 20,
                        }}
                      >
                        <h3>bestätigt:</h3>
                        <h3>offen: </h3>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <h3>
                          {Helper.formatPrice(
                            this.state.confirmedCashback || 0,
                            "EUR"
                          )}
                        </h3>
                        <h3>
                          {Helper.formatPrice(
                            this.state.pendingCashback || 0,
                            "EUR"
                          )}
                        </h3>
                      </div>
                    </div>
                  </KpiTile>
                  <KpiTile
                    color="#fff"
                    bgColor="#FCC200"
                    title="Meine Anfragen"
                    icon={<FontAwesomeIcon icon={faExclamation} />}
                    link="/reklamationen"
                  >
                    <h3>
                      {this.state.openComplaints || 0} offene
                      Nachbuchungsanfragen
                    </h3>
                  </KpiTile>
                </Grid>
              </Grid>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
