import React from "react";
import { Layout } from "../components/Layout/Layout";
import Hero from "../components/Hero/Hero";
import InnerLayout from "../components/InnerLayout/InnerLayout";
import Box from "../components/Box/Box";

interface Props {}

interface State {}

export default class UnsubscribeFromNewsletter extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <Layout>
        <Hero
          size="lg"
          imageSrc="/assets/images/heroes/faq.jpg"
          imagePositionY={-300}
        >
          <h1>Newsletterabmeldung</h1>
        </Hero>

        <InnerLayout>
          <Box>
            <h2>Newsletterabmeldung</h2>
            <p>Sie wurden erfolgreich von unserem Newsletter abgemeldet.</p>
          </Box>
        </InnerLayout>
      </Layout>
    );
  }
}
