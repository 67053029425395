import {API} from "../API";

export default class NewsletterManager {

    static async subscribe(email: string) {
        return await API.post(`newsletter/subscribe`, {
            email
        });
    }

}
