import React from 'react';
import { RouteComponentProps } from 'react-router';

import Hero from '../../components/Hero/Hero';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { footerItems, hiddenRoutes, navigationItems, Route } from '../../routes';

interface State {
  allRoutes: Route[];
  matchingRoute: string;
}

export class InfoPage extends React.Component<RouteComponentProps, State> {
  private params: any;
  private result: any = null;

  constructor(props: RouteComponentProps) {
    super(props);

    this.state = {
      allRoutes: [...footerItems, ...navigationItems, ...hiddenRoutes],
      matchingRoute: "",
    };
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.getParams();
  }

  getParams = () => {
    this.params = this.props;

    this.setState({
      matchingRoute: this.params.match.params.id,
    });
  };

  componentDidMount = () => {
    this.getParams();

    setTimeout(() => {
      if (window.location.hash) {
        const goTo: HTMLElement | null = document.getElementById(
          window.location.hash.replace("#", "")
        );

        if (goTo !== null) {
          goTo.scrollIntoView();
        }
      }
    }, 1000);
  };

  componentWillUnmount = () => {
    this.setState({
      matchingRoute: "",
    });
  };

  render() {
    {
      this.state.allRoutes.map((route: Route) => {
        if (
          route.infoPage &&
          route.infoPage.route &&
          route.infoPage.content &&
          route.infoPage.route === this.state.matchingRoute
        ) {
          this.result = (
            <Layout>
              <div className="InfoPage">
                <Hero
                  size="md"
                  imageSrc="/assets/images/heroes/faq.jpg"
                  imagePositionY={-300}
                >
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: route.infoPage.content.title,
                    }}
                  ></h1>
                </Hero>

                <InnerLayout>{route.infoPage.content.content}</InnerLayout>
              </div>
            </Layout>
          );
        }
      });
    }

    return this.result !== null ? this.result : <div></div>;
    // return this.result !== null ? this.result : <Redirect to="/404" />;
  }
}
