import * as React from "react";
import Auth from "../services/auth";
import { AppLoading } from "../components/AppLoading";

export class Logout extends React.Component<{}, {}> {
  componentDidMount() {
    window.setTimeout(() => {
      Auth.logout();
    }, 500);
  }

  render() {
    return <AppLoading />;
  }
}
