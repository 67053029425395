import React from 'react';
import { Layout } from '../components/Layout/Layout';
import Hero from '../components/Hero/Hero';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import Box from '../components/Box/Box';

const NewsletterConfirmation = () => {
  return (
    <Layout>
      <Hero
        size="lg"
        imageSrc="/assets/images/heroes/specials.jpg"
        imagePositionY={-300}
      />

      <InnerLayout>
        <Box>
          <h2>Vielen Dank für die Bestätigung Ihrer E-Mail-Adresse!</h2>

          <p>
            <b>Sie wurden erfolgreich für den Newsletter angemeldet.</b>
            <br /> Ab sofort erhalten Sie jeden Monat ausgewählte Angebote zum
            Shoppen und extra Cashback sichern!
          </p>

          <p>
            Falls Sie es sich anders überlegen:
            <br /> In jedem Newsletter können Sie mit einem Klick das Abo auch
            wieder abbestellen.
          </p>

          <p>
            Herzliche Grüße
            <br />
            Ihr Plusrente Team
          </p>
        </Box>
      </InnerLayout>
    </Layout>
  );
};

export default NewsletterConfirmation;
