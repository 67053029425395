import React, { CSSProperties } from 'react';
import './CashbackCounter.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@material-ui/core';

interface Props {
  style?: CSSProperties;

  value: number;
}

export default function CashbackCounter(props: Props) {
  const formatNumber = (value: number, decimals = 0) => {
    value = parseFloat(value as any);
    return value
      .toFixed(decimals)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  };

  const InfoIcon = React.forwardRef(function MyComponent(p, ref: any) {
    return (
      <i {...p} ref={ref}>
        <FontAwesomeIcon
          icon={faQuestionCircle}
          style={{
            opacity: 0.7,
          }}
        />
      </i>
    );
  });

  return (
    <div className="CashbackCounter" style={props.style}>
      <div>
        plusrente Cashback-Uhr{' '}
        <Tooltip title="Gesammeltes Cashback aller plusrente Kunden">
          <InfoIcon />
        </Tooltip>
      </div>
      <span>{formatNumber(props.value, 2)} EUR</span>
    </div>
  );
}
