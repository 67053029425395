import { Grid, Link } from '@material-ui/core';
import React from 'react';

import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import GoogleMap from '../../components/GoogleMap/GoogleMap';
import Hero from '../../components/Hero/Hero';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable';
import RegionalOfferShop from '../../interfaces/regionalOfferShop';
import Auth from '../../services/auth';
import RegionalOffersManager from '../../services/manager/RegionalOffersManager';

interface Props {
  match: any;
  location: any;
}

interface State {
  loading: boolean;
  shop?: RegionalOfferShop;
  isFavorite: boolean;
}

export default class RegionalOfferDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      isFavorite: false,
    };

    this.handleToggleFavorite = this.handleToggleFavorite.bind(this);
  }

  async componentDidMount() {
    const shop = await RegionalOffersManager.findShopById(
      this.props.match.params.id
    );
    const isFavorite = await RegionalOffersManager.isFavorite(shop.id);
    this.setState({
      shop,
      loading: false,
      isFavorite,
    });
  }

  async handleToggleFavorite() {
    if (!this.state.shop) return;

    if (this.state.isFavorite) {
      await RegionalOffersManager.removeShopFavorite(this.state.shop.id);
    } else {
      await RegionalOffersManager.setShopFavorite(this.state.shop.id);
    }

    this.setState({
      isFavorite: !this.state.isFavorite,
    });
  }

  render() {
    const events: any[] = [];

    if (this.state.shop) {
      this.state.shop.offers.forEach((offer) => {
        events.push({
          description: (
            <div
              dangerouslySetInnerHTML={{
                __html: offer.description,
              }}
            />
          ),
        });
      });
    }

    return (
      <Layout>
        <div className="RegionalOfferDetail">
          <Hero
            size="lg"
            imageSrc="/assets/images/heroes/tickets.jpg"
            imagePositionY={-200}
          />

          {this.state.loading && (
            <InnerLayout>
              <Backlink label="Zurück zur Übersicht" to="/vor-ort" />
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && this.state.shop && (
            <InnerLayout>
              <Backlink label="Zurück zur Übersicht" to="/vor-ort" />

              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <Box>
                    <h2>
                      {this.state.shop?.displayName
                        ? this.state.shop.displayName
                        : this.state.shop.name}
                    </h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.shop?.description,
                      }}
                    />
                  </Box>
                  <Box style={{ marginTop: 32 }}>
                    <h2>Angebotsübersicht</h2>
                    <ResponsiveTable
                      columns={[{ name: 'description', label: 'Beschreibung' }]}
                      data={events}
                    ></ResponsiveTable>
                  </Box>
                  <Box style={{ marginTop: 32 }}>
                    <h3>Standorte</h3>

                    {this.state.shop?.branches.map((branch, key) => (
                      <Grid container spacing={2} key={key}>
                        <Grid item xs={12} md={6}>
                          {branch.street} {branch.streetNumber}
                          <br />
                          {branch.zipCode} {branch.city}
                          <br />
                          {branch.country}
                          <br />
                          <br />
                          {branch.email ? (
                            <div>
                              E-Mail:{' '}
                              <a href={`mailto:${branch.email}`}>
                                {branch.email}
                              </a>
                            </div>
                          ) : (
                            ''
                          )}
                          {branch.website ? (
                            <div>
                              Webseite:{' '}
                              <a
                                href={`https://${branch.website}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {branch.website}
                              </a>
                            </div>
                          ) : (
                            ''
                          )}
                          {branch.phone ? (
                            <div>
                              Telefon:{' '}
                              <a href={`tel:${branch.phone}`}>{branch.phone}</a>
                            </div>
                          ) : (
                            ''
                          )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <div
                            style={{
                              width: '100%',
                              position: 'relative',
                              height: 150,
                            }}
                          >
                            <GoogleMap
                              location={{
                                lat: branch.lat,
                                lng: branch.lng,
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </Grid>
                <Grid item md={3}>
                  {this.state.shop?.images.length > 0 && (
                    <img
                      src={this.state.shop?.images[0].url}
                      style={{ width: '100%' }}
                      alt=""
                    />
                  )}
                  <div
                    style={{
                      lineHeight: '200%',
                      textAlign: 'center',
                      marginTop: '1em',
                    }}
                  >
                    {Auth.isLoggedIn() && (
                      <div>
                        {!this.state.isFavorite && (
                          <Link onClick={this.handleToggleFavorite}>
                            Als Favorit markieren
                          </Link>
                        )}
                        {this.state.isFavorite && (
                          <Link onClick={this.handleToggleFavorite}>
                            Favorit löschen
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
