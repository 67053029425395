import React from 'react';
import Box from '../../../components/Box/Box';

export const Privacy = {
    title: 'Datenschutz',
    content: (
        <Box>
            <h2>Datenschutzhinweise</h2>
            <p>
                Mit diesen Hinweisen informieren wir Sie über die Verarbeitung Ihrer
                personenbezogenen Daten durch BY die Bayerische Vorsorge Lebensversicherung
                a.G. im Zusammenhang mit der Nutzung dieser Website und dem
                Cashback-Portal und die Ihnen nach dem Datenschutzrecht zustehenden
                Rechte.
            </p>
            <h2>Verantwortliche Stelle und Datenschutzbeauftragter</h2>
            <h3>
                Verantwortliche Stelle für die Datenverarbeitung auf www.plusrente.de
                ist die
            </h3>
            <p>
                BY die Bayerische Vorsorge Lebensversicherung a.G. <br/>
                Aufsichtsratsvorsitzender: Prof. Dr. Alexander Hemmelrath
                <br/>
                Vorstand: Dr. Herbert Schneidemann (Vorsitzender), Martin Gräfer, Thomas
                Heigl
                <br/>
                Registergericht: München HR B 262
                <br/>
                Umsatzsteueridentifikationsnummer (Ust-ID-Nr.): DE 129 274 448
            </p>
            <p>
                Anschrift
                <br/>
                Thomas-Dehler-Str. 25
                <br/>
                81737 München
                <br/>
                T 089 / 6787-0
                <br/>
                F 089 / 6787-9150
                <br/>
                <a href="mailto:info@diebayerische.de">info@diebayerische.de</a>
            </p>
            <h3>Die Datenschutzbeauftragte ist zu erreichen unter</h3>
            <p>
                BY die Bayerische Vorsorge Lebensversicherung a.G.
                <br/>
                Datenschutzbeauftragte
                <br/>
                81732 München
                <br/>
                <a href="mailto:datenschutz@diebayerische.de">
                    datenschutz@diebayerische.de
                </a>
                <br/>T 089 / 6787-0
            </p>
            <h3>Zwecke und Rechtsgrundlagen der Datenverarbeitung</h3>
            <p>
                Beim Aufrufen unserer Website werden durch den auf ihrem Endgerät zum
                Einsatz kommenden Browser automatisch Informationen an den Server
                unserer Website gesendet. Diese Informationen werden temporär in einem
                sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr
                Zutun erfasst und bis zur automatisierten Löschung gespeichert:
            </p>
            <ul className="point">
                <li>IP-Adresse des anfragenden Rechners,</li>
                <li>Datum und Uhrzeit des Zugriffs,</li>
                <li>Name und URL der abgerufenen Datei,</li>
                <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
                <li>
                    Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie
                    der Name Ihres Access-Providers.
                </li>
            </ul>
            <p>
                Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
            </p>
            <ul className="point">
                <li>
                    Gewährleistung eines reibungslosen Verbindungsaufbaus der Website
                </li>
                <li>Gewährleistung einer komfortablen Nutzung unserer Website</li>
                <li>Auswertung der Systemsicherheit und –stabilität sowie</li>
                <li>zu weiteren administrativen Zwecken.</li>
            </ul>
            <p>
                Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1
                lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten
                Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen
                Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
            </p>
            <p>
                Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie
                Analysedienste ein. Nähere Erläuterungen dazu erhalten Sie unter Ziff. 4
                und 6 dieser Datenschutzerklärung.
            </p>
            <p>
                Bei der Registrierung werden Name, Geburtsdatum, Versicherungsnummer,
                E-Mail-Adresse und Passwort gespeichert, um die Leistungen des
                Cashback-Portals durchführen zu können, Art. 6 Abs. 1 S.1 lit. b DSGVO.
            </p>
            <p>
                Ihre Daten verarbeiten wir auch, um berechtigte Interessen von uns oder
                von Dritten zu wahren (Art. 6 Abs. 1 f) DSGVO).
                <br/>
                Dies kann insbesondere erforderlich sein:
            </p>
            <ul>
                <li>zur Gewährleistung der IT-Sicherheit und des IT-Betriebs,</li>
                <li>
                    zur Werbung für unsere eigenen Versicherungsprodukte und für andere
                    Produkte der Unternehmen der Gruppe die Bayerische und deren
                    Kooperationspartner sowie für Markt- und Meinungsumfragen,
                </li>
                <li>
                    zur Verhinderung und Aufklärung von Straftaten, insbesondere nutzen
                    wir Datenanalysen zur Erkennung von Hinweisen, die auf
                    Versicherungsmissbrauch hindeuten können.
                </li>
            </ul>
            <p>
                Darüber hinaus verarbeiten wir Ihre personenbezogenen Daten zur
                Erfüllung gesetzlicher Verpflichtungen wie z. B. aufsichtsrechtlicher
                Vorgaben, handels- und steuerrechtlicher Aufbewahrungspflichten oder
                unserer Beratungspflicht. Als Rechtsgrundlage für die Verarbeitung
                dienen in diesem Fall die jeweiligen gesetzlichen Regelungen i. V. m.
                Art. 6 Abs. 1 c) DSGVO. Sollten wir Ihre personenbezogenen Daten für
                einen oben nicht genannten Zweck verarbeiten wollen, werden wir Sie im
                Rahmen der gesetzlichen Bestimmungen darüber zuvor informieren.
            </p>
            <h3>Kategorien von Empfängern der personenbezogenen Daten</h3>
            <p>
                Die Durchführung der Cashback-Leistungen ist in unseren AGB beschrieben.
                Zur Durchführung der Cashback-Leistungen sind Datenübermittlungen von
                den Partnershops und dem Kreditkartenanbieter an den
                System-Dienstleister des Cashback-Portals und zur Ausschüttung der
                erwirtschafteten Rückvergütung auf den Versicherungsvertrag bei der Neue
                Bayerischen Beamten Lebensversicherung AG an diese erforderlich.
            </p>
            <p>Weitere Übermittlungen finden nur statt wenn:</p>
            <ul className="point">
                <li>
                    Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche
                    Einwilligung dazu erteilt haben.{' '}
                </li>
                <li>
                    Die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
                    Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
                    erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein
                    überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer
                    Daten haben.{' '}
                </li>
                <li>
                    Für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c.
                    DSGVO eine gesetzliche Verpflichtung besteht, sowie{' '}
                </li>
                <li>
                    dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für
                    die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
                </li>
            </ul>
            <h3>Cookies / Analyse- und Tracking-Tools</h3>
            <p>
                Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um
                kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem
                Endgerät gespeichert werden, wenn Sie unsere Seite besuchen. Cookies
                richte auf Ihrem Endgerät keinen Schaden an. In dem Cookie werden
                Informationen abgelegt, die sich jeweils im Zusammenhang mit dem
                spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht,
                dass wir dadurch unmittelbar Kenntnis von Ihrer Identität erhalten. Der
                Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots
                für Sie angenehmer zu gestalten. So setzen wir Session-Cookies ein, um
                zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht
                haben. Diese werden nach Verlassen unserer Seite automatisch gelöscht.
                Darüber hinaus setzen wir ebenfalls zur Optimierung der
                Benutzerfreundlichkeit temporäre Cookies ein, die für einen bestimmten
                festgelegten Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen
                Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird
                automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben
                und Einstellungen sie getätigt haben, um diese nicht noch einmal
                eingeben zu müssen.
            </p>
            <p>
                Zum anderen setzen wir Cookies ein, um die Nutzung unserer Website
                statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes
                für Sie auszuwerten. Diese Cookies ermöglichen es uns, bei einem
                erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits
                bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit
                automatisch gelöscht.
            </p>
            <p>
                Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur
                Wahrung unseres berechtigten Interesses sowie der Dritter nach Art. 6
                Abs. 1 S. 1 lit. f DSGVO erforderlich. Unser berechtigtes Interesse im
                Sinne der DSGVO ist die Verbesserung unseres Angebotes und unseres
                Webauftritts.
                <br/>
                Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren
                Browser jedoch so konfigurieren, dass keine Cookies auf ihrem Computer
                gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer
                Cookie angelegt wird. Die vollständige Deaktivierung von Cookies kann
                jedoch dazu führen, dass Sie nicht alle Funktionen unserer Website
                nutzen können.
                <br/>
                Mehr Informationen über Cookies und darüber, wie man überprüfen kann,
                welche Cookies gespeichert wurden, wie man sie verwaltet und löscht,
                finden Sie unter{' '}
                <a href="www.allaboutcookies.org" target="_blank">
                    www.allaboutcookies.org
                </a>
                .
            </p>
            <h3>Google Analytics</h3>
            <p>
                Diese Website nutzt den Dienst „Google Analytics“, welcher von der Google Inc. (1600 Amphitheatre
                Parkway Mountain View, CA 94043, USA) angeboten wird, zur Analyse der Websitebenutzung durch Nutzer. Der
                Dienst verwendet „Cookies“ – Textdateien, welche auf Ihrem Endgerät gespeichert werden. Die durch die
                Cookies gesammelten Informationen werden im Regelfall an einen Google-Server in den USA gesandt und dort
                gespeichert. Während Ihres Website-Besuchs werden u.a. folgende Daten aufgezeichnet:
                <br/>
                <ul>
                    <li>Aufgerufene Seiten</li>
                    <li>Bestellungen inkl. des Umsatzes und der bestellten Produkte</li>
                    <li>Ihr Verhalten auf den Seiten (beispielsweise Klicks, Scroll-Verhalten und Verweildauer)</li>
                    <li>Ihr ungefährer Standort (Land und Stadt)</li>
                    <li>Ihre IP-Adresse (in gekürzter Form, sodass keine eindeutige Zuordnung möglich ist)</li>
                    <li>Technische Informationen wie Browser, Internetanbieter, Endgerät und Bildschirmauflösung</li>
                    <li>Herkunftsquelle Ihres Besuchs (d.h. über welche Website bzw. über welches Werbemittel Sie zu uns
                        gekommen sind)
                    </li>
                </ul>
            </p>
            <p>
                Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird innerhalb der
                Mitgliedsstaaten der EU und des Europäischen Wirtschaftsraum gekürzt. Durch diese Kürzung entfällt der
                Personenbezug Ihrer IP-Adresse. Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung, welche die
                Websitebetreiber mit der Google Inc. geschlossen haben, erstellt diese mithilfe der gesammelten
                Informationen eine Auswertung der Websitenutzung und der Websiteaktivität und erbringt mit der
                Internetnutzung verbundene Dienstleistungen.
            </p>
            <p>
                Sie haben die Möglichkeit, die Speicherung des Cookies auf Ihrem Gerät zu verhindern, indem Sie in Ihrem
                Browser entsprechende Einstellungen vornehmen. Es ist nicht gewährleistet, dass Sie auf alle Funktionen
                dieser Website ohne Einschränkungen zugreifen können, wenn Ihr Browser keine Cookies zulässt.
            </p>
            <p>
                Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies gesammelten
                Informationen (inklusive Ihrer IP-Adresse) an die Google Inc. gesendet und von der Google Inc. genutzt
                werden. Folgender Link führt Sie zu dem entsprechenden Plugin: <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">
                https://tools.google.com/dlpage/gaoptout?hl=de</a>
            </p>
            <p>
                Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.: <a
                href="https://support.google.com/analytics/answer/6004245?hl=de"
                target="_blank">https://support.google.com/analytics/answer/6004245?hl=de</a> Google Analytics speichert
                Cookies in Ihrem
                Webbrowser für die Dauer von zwei Jahren seit Ihrem letzten Besuch. Diese Cookies enthaltene eine
                zufallsgenerierte User-ID, mit der Sie bei zukünftigen Website-Besuchen wiedererkannt werden können.
            </p>
            <p>
                Die aufgezeichneten Daten werden zusammen mit der zufallsgenerierten User-ID gespeichert, was die
                Auswertung pseudonymer Nutzerprofile ermöglicht. Diese nutzerbezogenen Daten werden automatisch nach 26
                Monaten gelöscht. Sonstige Daten bleiben in aggregierter Form unbefristet gespeichert.
            </p>
            <h3>Datensicherheit</h3>
            <p>
                Alle Daten werden für eine Übertragung nach den modernsten
                Sicherheitsstandards mit dem SSL-Standard verschlüsselt. Durch diese
                Verschlüsselungstechnik können die Daten während der Übertragung nicht
                von unbefugten Personen eingesehen werden. Ihr plusrente Benutzerkonto
                ist passwortgeschützt und somit vor Zugriffen von unberechtigten
                Personen gesichert. Zusätzlich haben Sie die Möglichkeit Ihr Passwort so
                jederzeit zu ändern, damit Ihre Daten sicherer vor einer Verwaltung
                durch eine unbefugte Person sind.
            </p>
            <h3>Dauer der Datenspeicherung</h3>
            <p>
                Wir löschen Ihre personenbezogenen Daten, sobald sie für die oben
                genannten Zwecke nicht mehr erforderlich sind. Dabei kann es vorkommen,
                dass personenbezogene Daten für die Zeit aufbewahrt werden, in der
                Ansprüche gegen unser Unternehmen geltend gemacht werden können
                (gesetzliche Verjährungsfrist von drei oder bis zu dreißig Jahren).
                Zudem speichern wir Ihre personenbezogenen Daten, soweit wir dazu
                gesetzlich verpflichtet sind. Entsprechende Nachweis- und
                Aufbewahrungspflichten ergeben sich unter anderem aus dem
                Handelsgesetzbuch, der Abgabenordnung und dem Geldwäschegesetz. Die
                Speicherfristen betragen danach bis zu zehn Jahren.
            </p>
            <h3>Betroffenenrechte</h3>
            <p>
                Sie können unter der o. g. Adresse Auskunft über die zu Ihrer Person
                gespeicherten Daten verlangen. Darüber hinaus können Sie unter
                bestimmten Voraussetzungen die Berichtigung oder die Löschung Ihrer
                Daten verlangen. Ihnen kann weiterhin ein Recht auf Einschränkung der
                Verarbeitung Ihrer Daten sowie ein Recht auf Herausgabe der von Ihnen
                bereitgestellten Daten in einem strukturierten, gängigen und
                maschinenlesbaren Format zustehen.
            </p>
            <h3>Widerspruchsrecht</h3>
            <p>
                Sie haben das Recht, einer Verarbeitung Ihrer personenbezogenen Daten zu
                Zwecken der Direktwerbung zu widersprechen.
                <br/>
                Verarbeiten wir Ihre Daten zur Wahrung berechtigter Interessen, können
                Sie dieser Verarbeitung widersprechen, wenn sich aus Ihrer besonderen
                Situation Gründe ergeben, die gegen die Datenverarbeitung sprechen.
                Verarbeiten wir Ihre Daten aufgrund Ihrer Einwilligung, so können Sie
                die Einwilligung jederzeit widerrufen. Durch den Widerruf der
                Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis
                zum Widerruf erfolgten Verarbeitung nicht berührt.
            </p>
            <h3>Beschwerderecht</h3>
            <p>
                Sie haben die Möglichkeit, sich mit einer Beschwerde an den oben
                genannten Datenschutzbeauftragten oder an eine
                Datenschutzaufsichtsbehörde zu wenden. Die für uns zuständige
                Datenschutzaufsichtsbehörde ist: Bayerisches Landesamt für
                Datenschutzaufsicht
            </p>
            <p>Postfach 606 | 91511 Ansbach | Deutschland</p>
        </Box>
    ),
};
