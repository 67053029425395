import './HeroSearch.scss';

import React from 'react';

import InputWithButton from '../InputWithButton/InputWithButton';

interface Props {
  children?: any;
  title: string;
  subTitle?: string;
  placeholder: string;
  buttonLabel: string;
  onSubmit: () => void;
  onChange: (query: string) => void;
  query?: string;
}

export default function HeroSearch(props: Props) {
  return (
    <div className="HeroSearch">
      {props.title && (
        <h1>
          {props.title}
          {props.subTitle && <span>{props.subTitle}</span>}
        </h1>
      )}
      <div className="HeroSearch-main">
        <InputWithButton
          value={props.query}
          placeholder={props.placeholder}
          buttonLabel="suchen"
          onClick={props.onSubmit}
          onChange={(e: any) => props.onChange(e.target.value)}
          onKeyDown={(event: any) =>
            event.keyCode === 13 ? props.onSubmit() : null
          }
        />
      </div>
      <div className="HeroSearch-children">{props.children}</div>
    </div>
  );
}
