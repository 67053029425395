import { Button } from '@material-ui/core';
import React, { Fragment } from 'react';

import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import Notification from '../../components/Notification/Notification';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import Shop from '../../interfaces/shop';
import CashbackManager from '../../services/manager/CashbackManager';

import { ClickoutHintData } from '../../interfaces/ClickoutHintData';
import Hero from '../../components/Hero/Hero';

interface Props {
  history: any;
  navigation: any;
}

interface State {
  loading: boolean;
  shop?: Shop;
  data: ClickoutHintData;
}

const styledHeader = {
  fontSize: 20,
  fontWeight: 100,
};

export default class ClickoutHint extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      data: {},
    };
  }

  async componentDidMount() {
    const data = this.props.history.location.state;

    const shop = data.shopId
      ? await CashbackManager.findShop(data.shopId)
      : (() => undefined)();

    this.setState({
      data,
      shop,
      loading: false,
    });
  }

  render() {
    const { type } = this.state.data;
    const { shop } = this.state;
    const { history } = this.props;

    return (
      <Layout>
        <Hero
          size="md"
          imageSrc="/assets/images/heroes/cashback.jpg"
          imagePositionY={-100}
        />

        <InnerLayout className="ClickoutHint">
          {this.state.loading && <Loading />}

          {!this.state.loading && (
            <Fragment>
              <Backlink label="zurück zur Übersicht" to="/shops-und-produkte" />

              <Box
                style={{
                  paddingTop: 30,
                  paddingBottom: 0,
                }}
              >
                <h2 style={{ marginBottom: 0 }}>
                  Sie verlassen nun das plusrente Portal
                </h2>
              </Box>

              {type === 'shop' && (
                <Box style={{ marginTop: 0 }}>
                  <Notification
                    type="warning"
                    title="Wichtig: Alle Cookies akzeptieren!"
                    text="Ihr Cashback kann nur erfasst werden, wenn Sie beim Partner der Verwendung aller Cookies zustimmen."
                  />
                </Box>
              )}

              <Box>
                {type === 'shop' && (
                  <Fragment>
                    {shop?.important && (
                      <Fragment>
                        <h2 style={styledHeader}>
                          Bitte beachten Sie bei{' '}
                          {shop.name || 'diesem Cashback Shop'}
                        </h2>

                        <div style={{ width: '80%', marginBottom: 30 }}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: shop.important,
                            }}
                          ></p>
                        </div>
                      </Fragment>
                    )}
                  </Fragment>
                )}

                {type === 'shop' && (
                  <Fragment>
                    <h2 style={styledHeader}>
                      Bitte beachten Sie im Allgemeinen
                    </h2>

                    <div style={{ width: '80%' }}>
                      <p>
                        Bitte achten Sie darauf, dass Gutscheine meist nicht mit
                        dem angegebenen Cashback kombiniert werden können. Dies
                        gilt auch für käuflich erworbene Gutscheine im Shop
                        selbst oder von Groupon o.ä. Die Verwendung von
                        Gutscheinen führt in den meisten Fällen zur Ablehnung
                        des Bonus. Alle prozentualen Rabatte beziehen sich auf
                        den Bruttoeinkaufswert. Versandkosten werden nicht mit
                        in die Bonusberechnung einbezogen, der
                        Bruttoeinkaufswert ist damit im Normalfall etwas
                        geringer als der Rechnungsbetrag.
                      </p>
                    </div>
                  </Fragment>
                )}

                {type === 'travelInsurance' && (
                  <Fragment>
                    <h2 style={styledHeader}>
                      Mehr Infos zur Reiseversicherung?
                    </h2>

                    <div style={{ width: '80%' }}>
                      <p>
                        Sie sind dabei die OLBplus-Seite zu verlassen, um sich
                        auf der Webseite der OLB über weitere Details zur
                        Reiseversicherung zu informieren. Bitte klicken Sie auf
                        "Weiter", um fortzufahren.{' '}
                      </p>
                    </div>
                  </Fragment>
                )}

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <a
                    href={this.state?.data && this.state?.data?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ marginLeft: 10 }}
                    >
                      Weiter
                      {type === 'shop' ? ' zum Shop' : ''}
                    </Button>
                  </a>
                </div>
              </Box>
            </Fragment>
          )}
        </InnerLayout>
      </Layout>
    );
  }
}
