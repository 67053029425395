import * as React from 'react';
import { Layout } from '../../components/Layout/Layout';
import Hero from '../../components/Hero/Hero';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import Backlink from '../../components/Backlink/Backlink';
import {
  Button,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Box from '../../components/Box/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import ShopTile from '../../components/ShopTile/ShopTile';
import './ShopDetail.scss';
import CashbackManager from '../../services/manager/CashbackManager';
import Shop from '../../interfaces/shop';
import { Loading } from '../../components/Loading';
import helper from '../../services/helper';
import copyToClipboard from 'copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import { AuthButton } from '../../components/AuthButton';
import Auth from '../../services/auth';
import Helper from '../../services/helper';

interface Props {
  match: any;
  history: any;
}

interface State {
  loading: boolean;
  shop?: Shop;
  similarShops: Shop[];
  visibleVoucherId: number;
  showCopyFeedback: boolean;
  isFavorite: boolean;
}

export default class ShopDetail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      similarShops: [],
      visibleVoucherId: 0,
      showCopyFeedback: false,
      isFavorite: false,
    };

    this.setVisibleVoucherId = this.setVisibleVoucherId.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.handleToggleFavorite = this.handleToggleFavorite.bind(this);
  }

  async componentDidMount() {
    const shop = await CashbackManager.findShop(this.props.match.params.id);

    const similarShops: Shop[] = [];
    if (shop.similarShops) {
      shop.similarShops.forEach(async (shopId) => {
        const similarShop = await CashbackManager.findShop(shopId);
        similarShops.push(similarShop);
      });
    }

    this.setState({
      shop,
      loading: false,
      similarShops,
      isFavorite: shop.isFavorite,
    });
  }

  setVisibleVoucherId(id: number) {
    this.setState({
      visibleVoucherId: id,
    });
  }

  copyToClipboard(code?: any) {
    if (!code) return;
    copyToClipboard(code);
    this.setState({
      showCopyFeedback: true,
    });
  }

  async handleToggleFavorite() {
    if (!this.state.shop) return;

    if (this.state.isFavorite) {
      await CashbackManager.removeShopFavorite(this.state.shop.id);
    } else {
      await CashbackManager.setShopFavorite(this.state.shop.id);
    }

    this.setState({
      isFavorite: !this.state.isFavorite,
    });
  }

  handleClickout(e: any, shop: any) {
    if (this.state?.shop && this.props?.history) {
      return Helper.handleClickoutHint(
        {
          type: 'shop',
          url: shop?.link,
          shopId: shop?.id,
        },
        e,
        this.props?.history
      );
    }
    return null;
  }

  render() {
    return (
      <Layout>
        <Hero
          size="md"
          imageSrc="/assets/images/heroes/cashback.jpg"
          imagePositionY={-100}
        />
        <InnerLayout className="ShopDetail">
          {this.state.loading && <Loading />}
          {!this.state.loading && this.state.shop && (
            <div>
              <Backlink label="zurück zur Übersicht" to="/shops-und-produkte" />

              <Grid container spacing={2}>
                <Grid item md={9}>
                  <Box
                    style={{
                      height: '100%',
                    }}
                  >
                    <h2>{this.state.shop.name}</h2>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.state.shop.description || '',
                      }}
                    />
                    <AuthButton
                      color="primary"
                      variant="contained"
                      size="medium"
                      href={this.state.shop.link}
                      target="_blank"
                      onClick={(e) => this.handleClickout(e, this.state.shop)}
                    >
                      zum Shop
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{
                          marginLeft: 10,
                        }}
                      />
                    </AuthButton>
                  </Box>
                </Grid>
                <Grid item md={3}>
                  <Box
                    style={{
                      height: '100%',
                    }}
                  >
                    <img src={this.state.shop.logo} width="100%" />

                    <div
                      style={{
                        lineHeight: '200%',
                        textAlign: 'center',
                        marginTop: '1em',
                      }}
                    >
                      {Auth.isLoggedIn() && (
                        <div>
                          {!this.state.isFavorite && (
                            <Link onClick={this.handleToggleFavorite}>
                              Als Favorit markieren
                            </Link>
                          )}
                          {this.state.isFavorite && (
                            <Link onClick={this.handleToggleFavorite}>
                              Favorit löschen
                            </Link>
                          )}
                        </div>
                      )}
                      <br />
                      <Link href="#cashback">Cashbackübersicht</Link>
                      <br />
                      <Link href="#vouchers">Gutscheine</Link>
                    </div>
                  </Box>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item md={9}>
                  <Box
                    style={{
                      marginTop: '1em',
                    }}
                  >
                    <h2 id="cashback">Cashbackübersicht</h2>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Cashback</TableCell>
                          <TableCell>Beschreibung</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.shop.cashbackRates &&
                          this.state.shop.cashbackRates.map(
                            (cashbackRate, key) => (
                              <TableRow key={key}>
                                <TableCell>
                                  {cashbackRate.amount} {cashbackRate.type}
                                </TableCell>
                                <TableCell>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: cashbackRate.description,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            )
                          )}
                      </TableBody>
                    </Table>
                    <br />
                    <AuthButton
                      color="primary"
                      variant="contained"
                      size="medium"
                      href={this.state.shop.link}
                      target="_blank"
                      onClick={(e) => this.handleClickout(e, this.state.shop)}
                    >
                      zum Shop
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        style={{
                          marginLeft: 10,
                        }}
                      />
                    </AuthButton>
                  </Box>
                  <Box
                    style={{
                      marginTop: '1em',
                    }}
                  >
                    <h2 id="vouchers">Gutscheine</h2>

                    <div className="ShopDetail-vouchers">
                      {this.state.shop.vouchers &&
                        this.state.shop.vouchers.length === 0 && (
                          <p>Aktuell liegen keine Gutscheine vor.</p>
                        )}
                      {this.state.shop.vouchers &&
                        this.state.shop.vouchers.map((voucher, key) => (
                          <div className="ShopDetail-voucher" key={key}>
                            {this.state.visibleVoucherId !== voucher.id &&
                              voucher.code && (
                                <Button
                                  variant="outlined"
                                  size="medium"
                                  onClick={() => {
                                    this.setVisibleVoucherId(voucher.id);
                                  }}
                                >
                                  Gutscheincode anzeigen
                                </Button>
                              )}
                            {this.state.visibleVoucherId === voucher.id &&
                              voucher.code && (
                                <AuthButton
                                  variant="outlined"
                                  size="medium"
                                  color="primary"
                                  onClick={() =>
                                    this.copyToClipboard(voucher.code)
                                  }
                                >
                                  Gutscheincode: {voucher.code}
                                </AuthButton>
                              )}
                            <h3>{voucher.title}</h3>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: voucher.description || '',
                              }}
                            ></p>
                            <div className="ShopDetail-voucher-info">
                              {parseInt(`${voucher.mimimumOrderValue}`, 10) >
                                0 && (
                                <span>
                                  Mindestbestellwert:{' '}
                                  {voucher.mimimumOrderValue}
                                </span>
                              )}

                              <span>
                                Gültig bis: {helper.formatDate(voucher.validTo)}
                              </span>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Box>

                  <Box
                    style={{
                      marginTop: '1em',
                    }}
                  >
                    <h2>Allgemeine Informationen</h2>
                    <p>
                      Bitte achten Sie darauf, dass Gutscheine meist nicht mit
                      dem angegebenen Cashback kombiniert werden können. Dies
                      gilt auch für käuflich erworbene Gutscheine im Shop selbst
                      oder von Groupon o.ä. Die Verwendung von Gutscheinen führt
                      in den meisten Fällen zur Ablehnung des Bonus.
                    </p>
                    <p>
                      Alle prozentualen Rabatte beziehen sich auf den
                      Bruttoeinkaufswert. Versandkosten werden nicht mit in die
                      Bonusberechnung einbezogen, der Bruttoeinkaufswert ist
                      damit im Normalfall etwas geringer als der
                      Rechnungsbetrag.
                    </p>
                    <p>
                      Nachbuchungsanfragen müssen spätestens 3 Monate nach
                      Buchung versendet werden. Anfragen, die später eingehen
                      werden nicht berücksichtigt.
                    </p>
                  </Box>

                  <br />

                  {this.state.similarShops.length > 0 && (
                    <div>
                      <h2>Ähnliche Shops</h2>
                      <Grid container spacing={2}>
                        {this.state.similarShops.map((shop, key) => (
                          <Grid item xs={6} md={4} key={key}>
                            <ShopTile
                              image={shop.logo}
                              buttonLabel="zum Shop"
                              morePath={`/shop/${shop.id}`}
                              buttonLink={shop.link}
                              isFavorite={shop.isFavorite}
                              shopId={shop.id}
                              history={this.props.history}
                            >
                              Bis zu{' '}
                              <strong>
                                {shop.maximumCashback}{' '}
                                {shop.maximumCashbackType}
                              </strong>{' '}
                              bei {shop.name}
                            </ShopTile>
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  )}
                </Grid>
                <Grid item md={3}>
                  {this.state.shop.important && (
                    <Box
                      style={{
                        marginTop: '1em',
                      }}
                    >
                      <h3>Bitte beachten Sie</h3>

                      <p
                        dangerouslySetInnerHTML={{
                          __html: this.state.shop.important || '',
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </div>
          )}

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.showCopyFeedback}
            autoHideDuration={5000}
            onClose={() => this.setState({ showCopyFeedback: false })}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">Gutscheincode wurde kopiert</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={() => this.setState({ showCopyFeedback: false })}
              >
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>,
            ]}
          />
        </InnerLayout>
      </Layout>
    );
  }
}
